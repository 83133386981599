import React from 'react'
import { Carousel } from '../../components/Carousel'
import { InnovationCard } from '../../components/InnovationCard'

const FeaturedCarousel = ({ featured }) => {
	return (
		<Carousel showOverflow={true} hideNavIndicators={true} autoSwipe={true}>
			{({ slideProps, isSwiping, isActive, hideCarouselNav, goToSlide }) => {
				return featured.map((e, i) => (
					<div key={i} {...slideProps(i, featured.length, true)}>
						<InnovationCard
							swiping={isSwiping()}
							disabled={!isActive(i)}
							goToSlide={() => goToSlide(i)}
							thumbnailSrc={
								e.assets[0] != null
									? e.assets[0].vimeoData
										? e.assets[0].vimeoData.thumbnail_url
										: e.assets[0].src
									: ''
							}
							data={e}
							showTags={true}
						/>
					</div>
				))
			}}
		</Carousel>
	)
}

export default FeaturedCarousel
