import styled from 'styled-components'

export const BtnWrapper = styled.div`
	width: 100%;
	text-align: center;
	margin-top: 50px;
	position: fixed;
	bottom: 0;
	left: 0;
`

export const ExploreBtn = styled.button`
	text-decoration: none;
	border: 3px solid #ffcc01;
	background-color: #000;
	margin-bottom: 20px;
	padding: 5px 20px;
	cursor: pointer;
	transition: color 0.2s, background-color 0.2s;
	font-size: 16px;
	color: #fff;
	font-weight: 400;
	font-family: 'Averta for TBWA';
	&:hover {
		background-color: #ffcc01;
		color: #000;
	}
`

export const Details = styled.div`
	width: 100%;
	padding: 12px;
	color: #fff;
	box-sizing: border-box;
	margin: 0 auto;
`
export const Link = styled.a`
	color: #fff;
	&:hover {
		color: #ffcc01;
	}
`

export const Para = styled.p`
	margin-block-start: 0;
`

export const Hero = styled.div`
	position: relative;
	width: 100%;
	height: 100vh;
	color: #fff;
	background-color: #000;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
`

export const Overlay = styled.div`
	position: absolute;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: #000;
	opacity: 0.4;
`

export const Logo = styled.div`
	background: url(${({ src }) => src});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	height: 100px;
	width: 100px;
	top: 50px;
	position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
	z-index: 4;
`

export const InnovationsContainer = styled.div`
	margin: 0 auto;
	width: 100%;
	max-width: 2100px;
	> div {
		&.carousel {
			width: 60%;
			margin: 0 auto;
			padding-top: 60px;
			@media (max-width: 700px) {
				width: 100%;
			}
			> div:first-child {
				text-align: center;
			}
		}
		&.search {
			width: 100%;
			padding: 40px 0px 20px 0px;
			height: auto;
			display: flex;
			flex-direection: row;
			@media (max-width: 837px) {
				flex-direction: column;
				margin-bottom: 15px;
				> div {
					width: calc(100% - 40px) !important;
					> svg {
						top: 15px !important;
					}
					&:first-child {
						> input {
							border-bottom: none !important;
						}
					}
				}
			}
			> div {
				width: 50%;
				box-sizing: border-box;
				margin: 0 20px;
				position: relative;
				> svg {
					position: absolute;
					left: 12px;
					top: 13px;
				}
				&:first-child {
					> input {
						width: 100%;
						background: #ffffff;
						border: 1px solid #c7c8c9;
						font-family: Averta for TBWA;
						font-weight: 200;
						font-size: 15px;
						color: #231f20;
						letter-spacing: 0;
						height: 45px;
						padding-left: 47px;
					}
				}
				&:last-child {
					background: #ffffff;
					border: 1px solid #c7c8c9;
					font-family: Averta for TBWA;
					font-weight: 200;
					font-size: 15px;
					color: #231f20;
					letter-spacing: 0;
					height: 45px;
					padding-left: 47px;
					display: flex;
					align-items: center;
					${p => p.filtering && `justify-content: space-between;`}
					padding-right: 10px;
					box-sizing: border-box;
					> .svg2 {
						top: 15px;
						cursor: pointer;
					}
					> .svg {
						position: absolute;
						width: 45px;
						height: 45px;
						display: flex;
						align-items: center;
						justify-content: center;
						left: 0px;
						cursor: pointer;
					}
					> input {
						width: 100%;
						background: #ffffff;
						border: none;
						font-family: Averta for TBWA;
						font-weight: 200;
						font-size: 15px;
						color: #231f20;
						height: 43px;
						padding: 0px;
						&:focus {
							outline: none;
						}
					}
				}
			}
		}
		&.innovations {
			width: 100%;
			min-height: 600px;
			display: flex;
			flex-wrap: wrap;
		}
	}
`

export const SelectedFilters = styled.div`
	display: flex;
	> div {
		display: flex;
	}
`

export const Ellipsis = styled.div`
	display: flex;
	align-items: flex-end;
	box-sizing: border-box;
	font-size: 25px;
`

export const ClearFilters = styled.div`
	background-color: #ffd014;
	color: #fff;
	width: auto;
	border-radius: 15px;
	right: 10px;
	cursor: pointer;
	padding: 0px 0px 0px 7px;
	display: flex;
	align-items: center;
	flex-shrink: 0;
	> svg {
		right: 5px;
		top: 0px;
	}
`

export const MobileClearFilters = styled.div`
	right: 10px;
	top: 10px;
	position: absolute;
	cursor: pointer;
	flex-shrink: 0;
`

export const FilterOptions = styled.div`
	position: absolute;
	width: 100%;
	left: 0px;
	top: 43px;
	height: auto;
	background: #ffffff;
	border: 1px solid #c7c8c9;
	font-family: Averta for TBWA;
	font-size: 16.2px;
	color: #000000;
	z-index: 10;
	padding: 10px;
	box-sizing: border-box;
	max-height: 450px;
	overflow: scroll;
	${p => (p.show ? '' : 'display: none;')}
	> label {
		width: 169px;
		margin-bottom: 10px;
	}
`

export const FilterLabel = styled.div`
	height: 100%;
	width: 80%;
	display: flex;
	align-items: center;
	opacity: 0.64;
	cursor: pointer;
`

export const Text = styled.div`
	text-align: left;
	margin-bottom: 25px;
`

export const Detail = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;
`

export const ExploreBtnWrapper = styled.div`
	display: flex;
	justify-content: center;
	cursor: pointer;
	z-index: 4;
	width: 100%;
`

export const Explore = styled.button`
	z-index: 4;
	position: absolute;
	color: #ffcc01;
	bottom: 10px;
	border: none;
	width: 150px;
	height: 50px;
	text-align: center;
	font-size: 13px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	:focus {
		outline: none;
	}
	&:hover {
		color: white;
		i {
			border: solid #fff;
			border-width: 0 1px 1px 0;
		}
	}
	${({ variant }) =>
		variant === 'outlined' &&
		`
	    text-decoration: none;
	border: 3px solid #ffcc01;
	background-color: #000;
	margin-bottom: 20px;
	width: auto;
	padding: 5px 20px;
	position: fixed;
	cursor: pointer;
	transition: color 0.2s, background-color 0.2s;
	font-size: 16px;
	color: #fff;
	font-weight: 400;
	font-family: 'Averta for TBWA';
	bottom: 40px;
	&:hover {
		background-color: #ffcc01;
		color: #000;
	}
	@media (min-width: 992px) {
		bottom: 107px;
	}
	`}
`

export const Caret = styled.i`
	border: solid #ffcc01;
	border-width: 0 1px 1px 0;
	display: inline-block;
	padding: 6px;
	margin-top: 12px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	bottom: 13px;
`

export const LearnMoreBtn = styled.button`
	background-color: transparent;
	border: 3px solid #ffcc01;
	margin-bottom: 20px;
	padding: 5px 20px;
	color: #fff;
	cursor: pointer;
	transition: background-color 0.1s, color 0.1s;
	&:hover {
		outline: none;
		background-color: #ffcc01;
		color: #000;
		transition: background-color 0.1s, color 0.1s;
	}
`

export const Video = styled.video`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
`

export const SearchContainer = styled.div``
