import React, { useState } from 'react'
import * as Styled from './styled'
import { path, rootRoute } from '../../config'
import About from './About'

const VIDEOS = ['Attractor.mp4', 'Fluorescent_Light.mp4', 'Particles.mp4']

const scroll = ({ top }) => {
	let y = document.body.scrollTop
	const animate = () => {
		y = y + 25
		window.scrollTo(0, y)
		if (y < top - 100) {
			window.requestAnimationFrame(animate)
		}
	}
	animate()
}

const Hero = ({ history: { push }, videoIndex }) => {
	const [isAboutVisible, toggleAbout] = useState(false)

	const handleOutsideClick = e => {
		if (e.target.matches('button')) return
		toggleAbout(false)
	}
	return (
		<Styled.Hero>
			<Styled.Video autoPlay loop muted src={VIDEOS[videoIndex]} />
			{videoIndex !== 2 && <Styled.Overlay />}
			<Styled.Logo src={`${path}Logo.png`} fixed={isAboutVisible} />
			{!isAboutVisible && (
				<Styled.Detail>
					<div>
						<Styled.Para>
							10x has evolved from a platform to a mindset that lives on at&nbsp;
							<Styled.Link
								href="https://knowledge.tbwa.com/nuxeo/site/LibraryWebUI/#/v2/landing/v2"
								target="_blank">
								Knowledge Hub
							</Styled.Link>
							.
						</Styled.Para>
						<Styled.Para>
							10x is the idea of taking something that has been successful done once
							(1x) and then <br /> scaling that across ten other agencies or clients.
						</Styled.Para>
						<Styled.Para>
							That something could be a piece of knowledge, a proven methodology or
							even a creative product.
							<br /> At TBWA we believe in being a radically open creative Collective.
							It’s what makes us unique.
						</Styled.Para>
						<Styled.Para>
							So keep the dream of 10x alive and head over to Knowledge Hub to tap
							into our Collective genius <br /> and share some of your own.
						</Styled.Para>
						<Styled.Para>
							If you’re looking for something specific that was on 10x and you can’t
							find it on Knowledge Hub <br />
							then please reach out to&nbsp;
							<Styled.Link href="mailto:charlotte.huntley@tbwa.com" target="_blank">
								charlotte.huntley@tbwa.com
							</Styled.Link>
							.
						</Styled.Para>
					</div>
				</Styled.Detail>
			)}
		</Styled.Hero>
	)
}

export default Hero
