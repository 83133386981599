import React from 'react'
import * as Styled from './styled'
import ReactTooltip from 'react-tooltip'
import { TiInfoLarge } from 'react-icons/ti'

const Info = ({ info, mobile = false, id }) => {
	let output = []
	for (let key in info) {
		if (key === 'type') {
			output.push(
				<Styled.InfoListItem key={key}>
					<div data-tip data-for='type-tip'>
						<span style={{ display: 'flex', alignItems: 'center' }}>
							<TiInfoLarge style={{ paddingRight: 3, cursor: 'pointer' }} />
							{`${key.charAt(0).toUpperCase() + key.slice(1)}:`}
						</span>
					</div>
					<ReactTooltip id='type-tip' type='dark' effect='solid' place='left'>
						<ul style={{ margin: 0, padding: 5, listStyle: 'none', maxWidth: 300 }}>
							<li>
								<h4 style={{ marginBottom: 6 }}>Plug & Play</h4>
								<p style={{ marginTop: 0 }}>
									This innovation can be scaled with no involvement from the inventor agency
								</p>
							</li>
							<li>
								<h4 style={{ marginBottom: 6 }}>Start-up Support</h4>
								<p style={{ marginTop: 0 }}>
									This innovation will require some support from the inventor agency i.e. customizing
									any software, hands on training etc. Potentially will require the agency that wants
									to scale, to cover some start-up costs for the inventor agency to help.
								</p>
							</li>
							<li>
								<h4 style={{ marginBottom: 6 }}>Partnership</h4>
								<p style={{ marginTop: 0, paddingTop: 0 }}>
									This innovation will require partnering with the inventor agency.
								</p>
							</li>
						</ul>
					</ReactTooltip>
					<div>{info[key]}</div>
				</Styled.InfoListItem>
			)
		} else if (key === 'costs') {
			output.push(
				<Styled.InfoListItem key={key}>
					<div data-tip data-for='costs-tip'>
						<TiInfoLarge style={{ paddingRight: 3, cursor: 'pointer' }} />
						<span>{`${key.charAt(0).toUpperCase() + key.slice(1)}:`}</span>
					</div>
					<ReactTooltip id='costs-tip' type='dark' effect='solid' place='left'>
						<ul style={{ margin: 0, padding: 5 }}>
							<li>
								<strong>$</strong> - 10k or less
							</li>
							<li>
								<strong>$$</strong> - 50k or less
							</li>
							<li>
								<strong>$$$</strong> - 100k or less
							</li>
							<li>
								<strong>$$$$</strong> - 250k or less
							</li>
							<li>
								<strong>$$$$$</strong> - above $250k
							</li>
						</ul>
					</ReactTooltip>
					{id !== '16' && <div>{info[key]}</div>}
					{id === '16' && <div dangerouslySetInnerHTML={{ __html: info[key] }} />}
				</Styled.InfoListItem>
			)
		} else {
			output.push(
				<Styled.InfoListItem key={key}>
					<div>{`${key.charAt(0).toUpperCase() + key.slice(1)}:`}</div>
					<div>{info[key]}</div>
				</Styled.InfoListItem>
			)
		}
	}
	return <Styled.Info mobile={mobile}>{output}</Styled.Info>
}

export default Info
