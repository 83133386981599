import React from 'react'
import ReactQuill from 'react-quill'
import {
	FormSectionContainer,
	QuillContainer,
	FormSectionTitle,
	FormInputContainer,
	FormSpacer
} from './styled'

export const Summary = ({
	handleChange,
	summary,
	keychal,
	sumres,
	knowabt,
	company,
	company_facts,
	city,
	contact,
	handleRichText
}) => {
	return (
		<FormSectionContainer>
			<QuillContainer>
				<label className="biggerTitle" htmlFor="summary">
					Executive Summary
				</label>
				<ReactQuill
					name="summary"
					theme="snow"
					value={summary}
					onChange={e => {
						handleRichText('summary', e)
					}}
				/>
			</QuillContainer>
			<QuillContainer>
				<FormSectionTitle>Details</FormSectionTitle>
				<label htmlFor="keychal">Key Business Challenge</label>
				<ReactQuill
					name="keychal"
					theme="snow"
					value={keychal}
					onChange={e => {
						handleRichText('keychal', e)
					}}
				/>
			</QuillContainer>
			<QuillContainer>
				<label htmlFor="sumres">Summary of Breakthrough Results</label>
				<ReactQuill
					name="sumres"
					theme="snow"
					value={sumres}
					onChange={e => {
						handleRichText('sumres', e)
					}}
				/>
			</QuillContainer>
			<QuillContainer>
				<label htmlFor="knowabt">Stuff We Should Know About</label>
				<ReactQuill
					name="knowabt"
					theme="snow"
					value={knowabt}
					onChange={e => {
						handleRichText('knowabt', e)
					}}
				/>
			</QuillContainer>

			<FormSectionTitle>About the Author</FormSectionTitle>
			<form>
				<FormInputContainer>
					<label htmlFor="type">Company</label>
					<input type="text" onChange={handleChange} value={company} name="company" />
				</FormInputContainer>
				<FormInputContainer>
					<label htmlFor="type">City</label>
					<input type="text" onChange={handleChange} value={city} name="city" />
				</FormInputContainer>
				<FormInputContainer>
					<label htmlFor="type">
						Company Facts <span>(optional)</span>
					</label>
					<input
						type="text"
						onChange={handleChange}
						value={company_facts}
						name="company_facts"
					/>
				</FormInputContainer>
				<FormInputContainer>
					<label htmlFor="contact">
						Contact Information <span>(Add multiple emails with a comma)</span>
					</label>
					<input type="text" value={contact} name="contact" onChange={handleChange} />
				</FormInputContainer>
			</form>
		</FormSectionContainer>
	)
}
export default Summary
