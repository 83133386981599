import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withOutsideClick } from '../withOutsideClick'
import { lockBodyScroll } from '../../utils'
import { Icon } from '../Icon'
import * as Styled from './styled'

// TODO: Clean up icons
export const XIcon = () => (
	<Styled.Icon viewBox='0 0 18 18'>
		<g id='Design-V3' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
			<g id='Mobile-Menu,-Open,-Yellow' transform='translate(-343.000000, -14.000000)' fill='#fff'>
				<g id='Group-20' transform='translate(337.000000, 8.000000)'>
					<g
						id='Group-5'
						transform='translate(15.097532, 15.097532) rotate(-45.000000) translate(-15.097532, -15.097532) translate(5.097532, 4.097532)'>
						<rect id='Rectangle-9' x='0' y='9.31034483' width='20' height='3.10344828' rx='0.736842093' />
						<path
							d='M0.392014507,9.36206897 L19.6079855,9.36206897 C20.0149321,9.36206897 20.3448276,9.69196441 20.3448276,10.0989111 L20.3448276,11.6252269 C20.3448276,12.0321735 20.0149321,12.362069 19.6079855,12.362069 L0.392014507,12.362069 C-0.0149321443,12.362069 -0.344827586,12.0321735 -0.344827586,11.6252269 L-0.344827586,10.0989111 C-0.344827586,9.69196441 -0.0149321443,9.36206897 0.392014507,9.36206897 Z'
							id='Rectangle-9'
							transform='translate(10.000000, 10.862069) rotate(-270.000000) translate(-10.000000, -10.862069) '
						/>
					</g>
				</g>
			</g>
		</g>
	</Styled.Icon>
)

class Modal extends Component {
	static defaultProps = {
		onClick: () => null,
		onClose: () => null,
		disableBodyScroll: true,
		hasClose: true,
		focusCloseOnMount: true,
	}

	static propTypes = {
		onClick: PropTypes.func,
		onClose: PropTypes.func,
		disableBodyScroll: PropTypes.bool,
		// If true, close button will be included
		hasClose: PropTypes.bool,
		// If true, close button will receive focus on mount
		focusCloseOnMount: PropTypes.bool,
	}

	componentDidMount() {
		const { disableBodyScroll, focusCloseOnMount } = this.props
		if (disableBodyScroll) {
			this.unlockBodyScroll = lockBodyScroll()
		}
		if (focusCloseOnMount) {
			this.setFocusToClose()
		}
	}

	componentWillUnmount() {
		if (this.unlockBodyScroll) {
			this.unlockBodyScroll()
		}
	}

	setFocusToClose = () => {
		if (!this.close) return
		this.close.focus()
	}

	onOutsideClick = e => {
		const { onOutsideClick, onClose } = this.props
		if (onOutsideClick) {
			onOutsideClick(e)
		} else {
			onClose(e)
		}
	}

	setCloseRef = el => {
		this.close = el
	}

	render() {
		const {
			children,
			onClose,
			onClick,
			id,
			innerRef,
			onOutsideClick,
			disableBodyScroll,
			hasClose,
			focusCloseOnMount,
			...rest
		} = this.props
		return (
			<Styled.Modal onClick={onClick} role='dialog' aria-modal tabIndex='-1' {...rest}>
				{hasClose && (
					<Styled.Close type='button' onClick={onClose} ref={this.setCloseRef}>
						<XIcon />
						<Icon name='x' />
					</Styled.Close>
				)}
				<Styled.Content id={id} ref={innerRef}>
					{children}
				</Styled.Content>
			</Styled.Modal>
		)
	}
}

export default withOutsideClick(Modal)
