import styled from 'styled-components'

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding-bottom: 40px;

	.slider-frame {
		height: auto !important;
	}
`
export const Container = styled.div`
	width: 1179px;
	padding-top: 40px;
	@media (max-width: 1279px) {
		width: 879px;
	}
	@media (max-width: 889px) {
		width: 90%;
	}
`

export const Hero = styled.img`
	width: 100%;
	background-color: #fff;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	@media (max-width: 1279px) {
		/* height: 480px; */
	}
`

export const Details = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	padding-bottom: 50px;
	padding-top: 50px;
	border-bottom: solid 1px #d8d8d8;
	@media (max-width: 768px) {
		flex-direction: column;
	}
`

export const IFrame = styled.iframe`
	width: 100%;
	display: flex;
	border: none;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	@media (max-width: 1279px) {
	}
`

export const DetailLeft = styled.div`
	width: 60%;
	display: flex;
	flex-direction: column;
	padding-right: 30px;
	box-sizing: border-box;
	@media (max-width: 768px) {
		width: 100%;
	}
	> h3,
	h4 {
		font-weight: 600;
	}
	> h1 {
		font-weight: 700;
	}
	> div > p > a {
		text-decoration: underline;
		color: #000;
	}
	> div > p > h4 {
		margin-block-end: 0;
		span {
			font-weight: 200;
		}
	}
	> p > p {
		margin-block-start: 0em;
		margin-block-end: 0em;
	}
	p {
		margin-top: 0;
	}
`

export const DetailRight = styled.div`
	width: 40%;
	display: flex;
	flex-direction: column;
	@media (max-width: 768px) {
		width: 100%;
	}
`

export const Scaled = styled.div`
	width: 100%;
	height: auto;
	box-sizing: border-box;
	border: solid 1px #d3d3d3;
	margin-bottom: 30px;
	bacground-color: #fff;
`

export const ScaleProgressWrapper = styled.div`
	width: 100%;
	height: auto;
	padding: 0px 20px 20px 20px;
	box-sizing: border-box;
	background-color: #fff;
`

export const Info = styled.ul`
	width: 100%;
	height: auto;
	list-style: none;
	padding-inline-start: 0;
	margin-block-start: 0;
	margin-block-end: 0;
	-webkit-padding-start: 0;

	${p => (p.mobile ? '@media(min-width: 768px){ display: none; }' : '@media(max-width: 768px){ display: none; }')}

	@media (max-width: 768px) {
		margin-top: 70px;
	}
`

export const InfoListItem = styled.li`
	width: 100%;
	min-height: 30px;
	height: auto;
	display: flex;
	font-size: 14px;
	> div:first-child {
		width: 25%;
		background-color: #000;
		color: #fff;
		text-align: right;
		padding-right: 3%;
		box-sizing: border-box;
		justify-content: flex-end;
		> svg {
			padding-right: 3px;
		}
	}
	> div:last-child {
		width: 75%;
		background-color: #fff;
		padding-left: 3%;
	}
	> div:first-child,
	div:last-child {
		display: flex;
		align-items: center;
		padding-top: 5px;
		padding-bottom: 5px;
	}
`

export const ScaledByDropdown = styled.div`
	width: 100%;
	background-color: #fff;
`

export const ScaledUl = styled.ul`
	width: 100%;
	height: auto;
	list-style: none;
	padding-inline-start: 0;
	margin-block-start: 0;
	margin-block-end: 0;
	${({ open }) => (open ? '' : 'display: none;')}
	padding: 0;
`

export const ScaledLi = styled.li`
	width: 100%;
	height: 45px;
	border-top: solid 1px #d3d3d3;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
`

export const ScaledByHeader = styled.div`
	width: 100%;
	height: 45px;
	border-top: solid 1px #d3d3d3;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background-color: #fff;
	transition: background-color 0.2s;
	&:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}
	> span {
		font-weight: 700;
		padding-left: 2px;
	}
`

export const StarterKit = styled.div`
	background: url(${({ src }) => src});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	height: auto;
	min-height: 40%;
	width: 100%;
	background-color: #000;
	padding: 40px;
	box-sizing: border-box;
	border-top: solid 1px #7c7c7c;
	> h1 {
		color: #fff;
		font-size: 2.2em;
	}
	> h3 {
		color: #fff;
		font-size: 1.3em;
	}
`

export const BtnContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	> a {
		width: 100%;
		cursor: pointer;
		box-sizing: border-box;
	}
`

// export const ImplementBtn = styled.a`
// 	background: #fafbfc;
// 	border: 6px solid #ffcc01;
// 	color: #000000;
// 	margin-bottom: 20px;
// 	padding: 5px;
// 	transition: background-color 0.2s, border 0.2s, color 0.2s;

// 	&:hover {
// 		border: 6px solid #000;
// 		background-color: #000;
// 		color: #ffcc01;
// 	}
// `

export const FileButton = styled.a`
	color: #ffcc01;
	padding: 5px;
	transition: background-color 0.2s, border 0.2s, color 0.2s;
	text-decoration: none;
	text-align: left;
	font-size: 1.17em;
	font-weight: 400;
	margin-bottom: 5px;
	&:hover {
		text-decoration: underline;
	}
	&::before {
		content: url(${({ arrow }) => arrow});
		margin-right: 8px;
	}
`

export const WorkplaceBtn = styled.a`
	background-color: #414141;
	color: #fff;
	padding: 10px;
	transition: background-color 0.2s, color 0.2s;
	text-decoration: none;
	font-size: 1.6em;
	font-weight: 400;
	padding-top: 20px;
	padding-bottom: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: center;
	justify-content: center;
	margin-top: 40px;
	&:hover {
		background-color: #ffcc01;
	}
	&::after {
		content: url(${({ arrow }) => arrow});
		margin-left: 12px;
	}
`

export const ExploreH3 = styled.h3`
	text-align: center;
	margin-top: 100px;
`

export const InnovationsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;

	> div {
		flex: 1 0 30%;
	}
`

export const Img = styled.div`
	background-image: url(${p => p.src});
	background-repeat: no-repeat;
	background-position: top center;
	background-size: contain;
	width: 100%;
	height: 663px;

	@media (max-width: 400px) {
		height: 230px;
	}
`
