import styled from 'styled-components'

export const InnovationCardsContainer = styled.div`
	display: 'flex';
`
export const ParentFormContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 1000px;
	@media (max-width: 999px) {
		width: 90%;
	}
`

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 1000px;
	@media (max-width: 999px) {
		width: 90%;
	}
`

export const InnovationListTopBarContainer = styled.div`
	display: flex;
	flex-direction: column;
`

export const InnovationListSearchBarContainer = styled.div`
	width: 100%;
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
	align-content: center;
	flex-direction: row;
`

export const InnovationListSaveChangesButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
`

export const InnovationListSearchBarInput = styled.input`
	display: flex;
	flex-grow: 1;
	height: 32px;
	padding-left: 10px;
`
