import React from 'react'

export const SearchIcon = () => (
	<svg width='23px' height='21px' viewBox='0 0 23 21' version='1.1'>
		<g id='Design-MVP' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
			<g id='Innovation-List-Copy' transform='translate(-264.000000, -142.000000)'>
				<g id='Group-2' transform='translate(249.000000, 130.000000)'>
					<g id='ic_search' transform='translate(12.000000, 9.000000)'>
						<polygon
							id='Bounds'
							fillOpacity='0.1'
							fill='#FF0000'
							opacity='0'
							points='0 0 30 0 30 28 0 28'
						/>
						<path
							d='M19.375,16.3333333 L18.3875,16.3333333 L18.0375,16.0183333 C19.2625,14.6883333 20,12.9616667 20,11.0833333 C20,6.895 16.3625,3.5 11.875,3.5 C7.3875,3.5 3.75,6.895 3.75,11.0833333 C3.75,15.2716667 7.3875,18.6666667 11.875,18.6666667 C13.8875,18.6666667 15.7375,17.9783333 17.1625,16.835 L17.5,17.1616667 L17.5,18.0833333 L23.75,23.905 L25.6125,22.1666667 L19.375,16.3333333 L19.375,16.3333333 Z M11.875,16.3333333 C8.7625,16.3333333 6.25,13.9883333 6.25,11.0833333 C6.25,8.17833333 8.7625,5.83333333 11.875,5.83333333 C14.9875,5.83333333 17.5,8.17833333 17.5,11.0833333 C17.5,13.9883333 14.9875,16.3333333 11.875,16.3333333 L11.875,16.3333333 Z'
							id='Shape'
							fillOpacity='0.7'
							fill='#000000'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)

export const FilterIcon = () => (
	<svg width='22px' height='14px' viewBox='0 0 22 14' version='1.1'>
		<g id='Design-MVP' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
			<g id='Innovation-List-Copy' transform='translate(-1315.000000, -146.000000)'>
				<g id='Group-3' transform='translate(1300.000000, 130.000000)'>
					<g id='ic_filter_list' transform='translate(12.000000, 9.000000)'>
						<polygon
							id='Bounds'
							fillOpacity='0.1'
							fill='#FF0000'
							opacity='0'
							points='0 0 28 0 28 28 0 28'
						/>
						<path
							d='M11.6666667,21 L16.3333333,21 L16.3333333,18.6666667 L11.6666667,18.6666667 L11.6666667,21 L11.6666667,21 Z M3.5,7 L3.5,9.33333333 L24.5,9.33333333 L24.5,7 L3.5,7 L3.5,7 Z M7,15.1666667 L21,15.1666667 L21,12.8333333 L7,12.8333333 L7,15.1666667 L7,15.1666667 Z'
							id='Shape'
							fillOpacity='0.7'
							fill='#000000'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)

export const Caret = () => (
	<svg width='14px' height='9px' viewBox='0 0 14 9' version='1.1'>
		<g id='Desktop-Search-&amp;-Filter' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
			<g id='Innovation-List-Copy-4' transform='translate(-1319.000000, -148.000000)'>
				<g id='ic_expand_less' transform='translate(1312.000000, 139.000000)'>
					<polygon id='Bounds' fillOpacity='0.1' fill='#FF0000' opacity='0' points='0 0 28 0 28 28 0 28' />
					<polygon
						id='Shape'
						fillOpacity='0.7'
						fill='#000000'
						points='14 9.33333333 7 16.3333333 8.645 17.9783333 14 12.635 19.355 17.9783333 21 16.3333333'
					/>
				</g>
			</g>
		</g>
	</svg>
)

export const Clear = () => (
	<svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
		<g id='Desktop-Search-&amp;-Filter' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
			<g id='Innovation-List-Copy-4' transform='translate(-2273.000000, -141.000000)'>
				<g id='Group-5' transform='translate(2179.000000, 139.000000)'>
					<g id='ic_cancel' transform='translate(92.000000, 0.000000)'>
						<polygon
							id='Bounds'
							fillOpacity='0.1'
							fill='#FF0000'
							opacity='0'
							points='0 0 28 0 28 28 0 28'
						/>
						<path
							d='M14,2.33333333 C7.54833333,2.33333333 2.33333333,7.54833333 2.33333333,14 C2.33333333,20.4516667 7.54833333,25.6666667 14,25.6666667 C20.4516667,25.6666667 25.6666667,20.4516667 25.6666667,14 C25.6666667,7.54833333 20.4516667,2.33333333 14,2.33333333 L14,2.33333333 Z M19.8333333,18.1883333 L18.1883333,19.8333333 L14,15.645 L9.81166667,19.8333333 L8.16666667,18.1883333 L12.355,14 L8.16666667,9.81166667 L9.81166667,8.16666667 L14,12.355 L18.1883333,8.16666667 L19.8333333,9.81166667 L15.645,14 L19.8333333,18.1883333 L19.8333333,18.1883333 Z'
							id='Shape'
							fill='#FFCC01'
						/>
						<polygon
							id='Path-Copy'
							fill='#FFFFFF'
							points='19.8333333 18.1883333 18.1883333 19.8333333 14 15.645 9.81166667 19.8333333 8.16666667 18.1883333 12.355 14 8.16666667 9.81166667 9.81166667 8.16666667 14 12.355 18.1883333 8.16666667 19.8333333 9.81166667 15.645 14'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
