import React from 'react'
import { Carousel } from '../../components/Carousel'
import * as Styled from './styled'
import Vimeo from './Vimeo'

const Image = ({ data: { src } }) => {
	return <Styled.Img src={src} />
}

const Asset = data => {
	const { type } = data
	switch (type) {
		case 'VIMEO':
			return <Vimeo {...data} />
		case 'IMAGE':
			return <Image {...data} />
	}
}

const HeroCarousel = ({ assets, innovationId, innovationTitle }) => (
	<div style={{ margin: '30px 0 30px 0' }}>
		<Carousel>
			{({ slideProps, isSwiping, isActive, hideCarouselNav }) =>
				assets.map((asset, i) => (
					<div key={i} {...slideProps(i, assets.length)}>
						<Asset
							{...asset}
							swiping={isSwiping()}
							active={isActive(i)}
							hideCarouselNav={hideCarouselNav}
							innovationId={innovationId}
							innovationTitle={innovationTitle}
						/>
					</div>
				))
			}
		</Carousel>
	</div>
)

export default HeroCarousel
