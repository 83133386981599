import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Security, ImplicitCallback, SecureRoute } from '@okta/okta-react'
import { Nav } from './Nav'
import { Innovation } from '../pages/Innovation'
import { Admin } from '../pages/Admin'
import { Landing } from '../pages/Landing'
import { About } from './About'
import { Footer } from './Footer'
import { ScrollToTop } from './Utility'
import { rootRoute } from '../config'
import { Leaderboard } from '../pages/Leaderboard'
import * as ROUTES from '../constants/routes'

const PrivateRoute = ({ component: Component, ...rest }) => {
	return (
		<SecureRoute
			{...rest}
			render={props => {
				if (rest.adminLookupComplete) {
					if (rest.isAdmin === true) return <Component {...props} />
					else return <Redirect to="/" />
				} else return <Component {...props} />
			}}
		/>
	)
}

export const Main = ({ nav, title, admins, adminLookupComplete, isAdmin }) => {
	return (
		<Security
			issuer={OKTA_ISSUER}
			redirect_uri={`${window.location.origin}/implicit/callback`}
			client_id={OKTA_CLIENT_ID}>
			<Nav hide={nav} key={admins} admins={admins} title={title} />
			<div style={{ minHeight: '100%' }}>
				<Switch>
					{/* <Route path={'/admin'} component={About}></Route> */}
					<PrivateRoute
						isAdmin={isAdmin}
						adminLookupComplete={adminLookupComplete}
						path={ROUTES.ADMIN}
						component={Admin}
					/>
					<Route exact path={`${rootRoute}`} component={Landing} />

					<SecureRoute path={`${rootRoute}innovation/:id`} component={Innovation} />
					<SecureRoute path={`${rootRoute}about`} component={About} />
					<SecureRoute path={ROUTES.LEADERBOARD} component={Leaderboard} />

					<Route path={'/implicit/callback'} component={ImplicitCallback} />
					{/* <Route render={() => <Redirect to={{ pathname: '/' }} />} /> */}
				</Switch>
			</div>
			<Footer />
			<ScrollToTop />
		</Security>
	)
}
