export { ScrollToTop } from './ScrollToTop'

export const fetchVideoData = innovations => {
	return Promise.all(
		innovations.map(async innovation => {
			innovation.assets = await Promise.all(
				innovation.assets.map(async asset => {
					const { type, data } = asset
					if (type === 'VIMEO') {
						let response
						if (data.videoApiId != '') {
							response = await fetch(
								`https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${data.videoApiId}&width=1920&height=1080`
							)
						} else {
							let apiId = '429455994/7a1538dfd9'
							response = await fetch(
								`https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${apiId}&width=1920&height=1080`
							)
							let testUrl =
								'https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/429455994/7a1538dfd9&width=1920&height=1080'
						}
						if (response.status == 404) {
							asset.vimeoData = {}
						} else {
							asset.vimeoData = await response.json()
						}
					}
					return asset
				})
			)
			return innovation
		})
	)
}
