import React from 'react'
import { Link } from 'react-router-dom'
import { updateInnovation, createInnovation } from '../../../store/actions'
import { BottomBarContainer, RedButton, BlackButton, YellowButton, GreyButton } from './styled'

export const BottomBar = props => {
	const archiveClick = () => {
		let data = props.data
		let {
			old_title,
			title,
			description,
			company,
			company_facts,
			contact,
			city,
			scaled_by,
			summary,
			tags,
			featured,
			featured_index,
			links,
			keychal,
			sumres,
			knowabt,
			type,
			rollout,
			impact,
			risks,
			costs,
			archived
		} = data
		let assets = props.thumbnailArray
		let details = [keychal, sumres, knowabt]
		let info = {
			costs,
			impact,
			risks,
			rollout,
			type
		}
		featured = false
		archived = true
		let updatedInnovation = JSON.parse(
			JSON.stringify({
				old_title,
				title,
				description,
				company,
				company_facts,
				contact,
				city,
				summary,
				details,
				info,
				scaled_by,
				assets,
				tags,
				featured,
				featured_index,
				links,
				archived
			})
		)

		props.dispatch(updateInnovation(updatedInnovation))
	}

	const updateClick = () => {
		let data = props.data
		let {
			old_title,
			title,
			description,
			company,
			company_facts,
			contact,
			city,
			summary,
			scaled_by,
			tags,
			featured,
			featured_index,
			links,
			keychal,
			sumres,
			knowabt,
			type,
			rollout,
			impact,
			risks,
			costs,
			archived
		} = data
		let assets = props.thumbnailArray
		let details = [keychal, sumres, knowabt]
		let info = {
			costs,
			impact,
			risks,
			rollout,
			type
		}
		let updatedData = JSON.parse(
			JSON.stringify({
				old_title,
				title,
				description,
				company,
				company_facts,
				contact,
				city,
				summary,
				details,
				info,
				scaled_by,
				assets,
				tags,
				featured,
				featured_index,
				links,
				archived
			})
		)
		props.dispatch(updateInnovation(updatedData))
	}

	const createClick = () => {
		let data = props.data
		let innovations = props.innovations
		let {
			title,
			description,
			company,
			company_facts,
			contact,
			city,
			summary,
			scaled_by,
			tags,
			featured,
			featured_index,
			featured_image,
			links,
			keychal,
			sumres,
			knowabt,
			type,
			rollout,
			impact,
			risks,
			costs,
			archived
		} = data
		let assets = props.thumbnailArray
		let details = [keychal, sumres, knowabt]
		let info = {
			costs,
			impact,
			risks,
			rollout,
			type
		}
		let createData = JSON.parse(
			JSON.stringify({
				title,
				description,
				company,
				company_facts,
				contact,
				city,
				summary,
				details,
				info,
				scaled_by,
				assets,
				tags,
				featured,
				featured_index,
				featured_image,
				links,
				archived
			})
		)
		props.dispatch(createInnovation(createData, innovations))
	}

	const deleteClick = () => {
		//to do: implement delete mutation
		props.setDeleteModal(true)
	}

	const saveClick = () => {
		if (props.editingInnovation) {
			updateClick()
		} else {
			createClick()
		}
	}

	return (
		<BottomBarContainer
			style={!props.editingInnovation ? { justifyContent: 'flex-end' } : null}>
			{props.editingInnovation ? (
				<RedButton onClick={() => deleteClick()}>Delete Innovation</RedButton>
			) : null}

			<div>
				{props.editingInnovation ? (
					<Link to="/admin">
						<BlackButton onClick={() => archiveClick()}>Archive</BlackButton>
					</Link>
				) : null}
				<Link to="/admin">
					<YellowButton onClick={() => saveClick()}>
						{props.editingInnovation ? 'Update' : 'Create'}
					</YellowButton>
				</Link>
				<Link to="/admin">
					<GreyButton>Cancel</GreyButton>
				</Link>
			</div>
		</BottomBarContainer>
	)
}

export default BottomBar
