import React from 'react'
import styled from 'styled-components'

export const StyledTag = styled.div`
	position: relative;
	height: 30px;
	display: inline-flex;
	align-items: center;
	margin: 4px 5px;
	font-size: 13.2px;
	> div:first-child {
		padding: 0px 10px;
		position: relative;
		white-space: nowrap;
	}
	::before {
		content: '';
		transform: skew(20deg);
		background-color: #ffd014;
		height: 30px;
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
	}
`

export const Tag = ({ label }) => (
	<StyledTag>
		<div>{label}</div>
	</StyledTag>
)
