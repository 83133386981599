import React, { useState, useEffect, useRef } from 'react'
import dragula from 'react-dragula'
import styled from 'styled-components'
import { YellowButton } from '../../components/admin/form/styled'
import { FeaturedAdminInnovationCard } from '../../components/admin/FeaturedAdminInnovationCard'

let innovationsTitleStyle = {
	marginTop: 20,
	fontWeight: 'bold',
	fontSize: 24,
	marginBottom: 28
}

export const FeaturedOrder = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: left;
	align-items: top;
`

export const FeaturedOrderTitle = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-right: 20px;
`

export const FeaturedInnovations = ({
	dispatch,
	title,
	section,
	otherSections,
	featuredInnovations,
	handleEdit,
	setInnovation,
	resolveFeatureOrder
}) => {
	const thumbContainer = useRef(null)
	let drake = null
	let featuredInnovationsRef = useRef(featuredInnovations)
	let [featureArray, setFeatureArray] = useState(featuredInnovations)

	useEffect(() => {
		drake = dragula([thumbContainer.current], {
			direction: 'horizontal',
			copy: false,
			copySortSource: false,
			revertOnSpill: false,
			removeOnSpill: false,
			mirrorContainer: document.body,
			ignoreInputTextSelection: true,
			slideFactorX: 0,
			slideFactorY: 0
		})
		drake.on('dragend', dragComplete)
	}, [])

	useEffect(() => {
		featuredInnovationsRef.current = featuredInnovations
		setFeatureArray(featuredInnovations)
	}, [featuredInnovations])

	const dragComplete = () => {
		resolveUpdatedOrder(thumbContainer)
	}

	const resolveUpdatedOrder = nodeList => {
		let featureOrder = []
		let NodeArray = [...nodeList.current.childNodes]

		NodeArray.map(fInnov => {
			featureOrder.push(
				fInnov.childNodes[1].childNodes[0].childNodes[0].childNodes[0].nodeValue
			)
		})

		let updatedFeatures = featureOrder.map((title, index) => {
			let findInnov = featuredInnovationsRef.current.find(fInnov => {
				return fInnov.title === title
			})
			findInnov.featured_index = String(index)
			return findInnov
		})

		setFeatureArray(updatedFeatures)
		resolveFeatureOrder(updatedFeatures)
	}

	const getFeaturedIndex = (title, featured_index) => {
		let foundIndex = featureArray.findIndex(feature => feature.title === title)
		return foundIndex === -1
			? Number(featured_index)
			: featureArray.findIndex(feature => feature.title === title)
	}

	const renderCards = () => {
		return featureArray.map(item => (
			<FeaturedAdminInnovationCard
				key={`FeaturedAdminInnovationCard-${item.title}-${item.id}`}
				innov={item}
				title={item.title}
				handleEdit={handleEdit}
				featuredIndex={getFeaturedIndex(item.title, item.featured_index)}
				description={item.description}
				setInnovation={setInnovation}
			/>
		))
	}

	return (
		<React.Fragment>
			<FeaturedOrderTitle>
				<div style={innovationsTitleStyle}>{title}</div>
			</FeaturedOrderTitle>
			<FeaturedOrder className="container" ref={thumbContainer}>
				{renderCards()}
			</FeaturedOrder>
		</React.Fragment>
	)
}
