import styled from 'styled-components'

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 1000px;
	@media (max-width: 999px) {
		width: 90%;
	}
`

export const TopBarContainer = styled.div`
	display: flex;
	margin-top: 40px;
	justify-content: space-between;
	@media (max-width: 999px) {
		width: 90%;
	}
`

export const BottomBarContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 20px;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 999px) {
		width: 90%;
	}
`

export const YellowButton = styled.button`
	margin-left: 15px;
	padding: 8px 25px;
	background: #ffcc01;
	border: none;
	border-radius: 20px;
	cursor: pointer;
`

export const BlackButton = styled.button`
	margin-left: 15px;
	padding: 8px 25px;
	background: #000;
	border: none;
	border-radius: 20px;
	color: #fff;
	cursor: pointer;
`

export const GreyButton = styled.button`
	margin-left: 15px;
	padding: 8px 25px;
	background: #f7f7f7;
	border: none;
	border-radius: 20px;
	cursor: pointer;
`

export const RedButton = styled.button`
	margin-right: 15px;
	padding: 8px 25px;
	background: #a30000;
	border: none;
	border-radius: 20px;
	color: #fff;
	cursor: pointer;
`

export const InnovationTitle = styled.div`
	font-weight: bold;
	font-size: 24;
	margin-bottom: 28;
`

export const FormSectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid #ccc;
	padding: 20px;
	border-radius: 4px;
	form {
		margin-bottom: 0;
	}
	margin-bottom: 15px;
	&:last-child {
		margin-bottom: 0;
	}
`

export const FormInputContainer = styled.div`
	display: flex;
	flex-direction: column;

	label {
		font-size: 14px;
		font-weight: bold;
		color: #000;

		span {
			font-weight: normal;
		}
	}

	input {
		margin-top: 10px;
		height: 40px;
		background: #fdfefe;
		border: 1px solid #ccc;
		box-sizing: border-box;
		border-radius: 4px;
		width: 100%;
		padding: 12px;
		font-size: 12px;
	}

	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
	}
`

export const FormInputCounter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	font-size: 11px;
	margin-top: 3px;
	color: #000;
`

export const QuillContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;

	label {
		font-size: 14px;
		color: #000;
		font-weight: bold;

		span {
			font-weight: normal;
		}
	}

	.biggerTitle {
		font-size: 18px;
	}

	.quill {
		margin-top: 10px;
	}
	.ql-editor,
	.ql-container {
		height: auto;
	}
`

export const FormSectionTitle = styled.div`
	font-size: 18px;
	font-weight: bold;
	color: #000;
	margin-bottom: 20px;
`

export const FormSpacer = styled.div`
	margin-bottom: 20px;
`

export const FormInputWithButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;

	label {
		font-size: 14px;
		color: #000;
		font-weight: bold;
		span {
			font-weight: normal;
		}
	}

	.inputField {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

		input {
			margin-top: 10px;
			height: 40px;
			background: #fdfefe;
			border: 1px solid #ccc;
			box-sizing: border-box;
			border-radius: 4px;
			max-width: 500px;
			width: 85%;
			padding: 12px;
			font-size: 12px;
		}

		button {
			margin-left: 10px;
			margin-top: 10px;
			height: 31px;
			width: 77px;
			background: rgb(255, 204, 1);
			border: none;
			border-radius: 20px;
		}
	}
`

export const TagsContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 5px;
	background: #fdfefe;
	border: 1px solid #ccc;
	box-sizing: border-box;
	border-radius: 4px;
	input {
		display: flex;
		flex-grow: 1;
		height: 40px;
		border: none;
	}
`

export const SingleTag = styled.div`
	display: flex;
	align-items: center;
	margin: 5px;
	padding: 8px;
	background: #dcdcdc;
	font-size: 12px;
	border-radius: 4px;
`

export const CancelBtn = styled.div`
	font-size: 12px;
	margin-right: 5px;
	font-weight: bold;
	cursor: pointer;
`

export const ScaledAgencyContainer = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;
	padding: 12px;
	height: 40px;
	background: #fdfefe;
	border: 1px solid #ccc;
	box-sizing: border-box;
	border-radius: 4px;
	margin-bottom: 10px;
	max-width: 500px;
	width: 100%;
	&:last-child {
		margin-bottom: 0;
	}
`

export const VerticalBorderSpacer = styled.div`
	height: 40px;
	width: 1px;
	background: #ccc;
	margin-right: 12px;
`

export const CarouselContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`

export const CarouselTitleBar = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;

	.buttonContainer {
		display: flex;
		flex-direction: row;
	}
`

export const CarouselThumbnail = styled.div`
	position: relative;
	border: 1px solid #ccc;
	margin-right: 15px;
	margin-bottom: 15px;

	img {
		width: 165px;
		height: auto;
		cursor: grab;
	}

	.deleteBtn {
		position: absolute;
		cursor: pointer;
		background: #fff;
		width: 30px;
		height: 30px;
		bottom: 2px;
		right: 2px;
		border-radius: 4px;
		border: 1px solid #ccc;
		background-image: url('/trash_can.png');
		background-size: 12px 14px;
		background-position: center center;
		background-repeat: no-repeat;
	}
`

export const FileContainer = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;
	padding: 12px;
	height: 40px;
	background: #fdfefe;
	border: 1px solid #ccc;
	box-sizing: border-box;
	border-radius: 4px;
	margin-bottom: 10px;
	width: 100%;
	&:last-child {
		margin-bottom: 0;
	}
`

export const FileInputParentContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
`

export const FileInputContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 70%;
	margin-right: 10px;

	label {
		font-size: 14px;
		font-weight: bold;
		color: #000;

		span {
			font-weight: normal;
		}
	}

	input {
		margin-top: 10px;
		height: 40px;
		background: #fdfefe;
		border: 1px solid #ccc;
		box-sizing: border-box;
		border-radius: 4px;
		width: 100%;
		padding: 12px;
		font-size: 12px;
	}

	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
	}
`

export const FileText = styled.div`
	height: 15px;
	margin-right: 12px;
`

export const ModalBlackout = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.7);
`

export const MediaModalContainer = styled.div`
	padding: 40px;
	position: fixed;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	width: 500px;
	background: #fdfefe;
	border-radius: 20px;
`

export const Tabs = styled.div`
	display: flex;
	justify-content: flex-start;
	border-bottom: 1px solid #f3f3f3;
	padding: 4px 0;
`
export const Tab = styled.div`
	background: ${p => (p.active ? `#ffcc01` : `#f3f3f3`)};
	line-height: 40px;
	border-radius: 20px 20px 0 0;
	margin-right: 6px;
	border: 0px solid #ccc;
	border-bottom: 0;
	padding: 10px 24px 0;
	cursor: pointer;
	&:hover {
		background: ${p => (p.active ? `#ffcc01` : `#eee`)};
	}
`
export const Thumbnails = styled.div`
	display: flex;
	flex-wrap: wrap;
	max-height: 200px;
	overflow-y: scroll;
	margin: 20px 0;
`
export const ImagePreview = styled.div`
	width: 25%;
	min-width: 160px;
	background: #eee;
	border-radius: 10px;
	padding: 10px;
	margin: 5px;
	img {
		width: 100%;
	}
	p {
		font-size: 12px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`

export const DeleteModalContainer = styled.div`
	padding: 20px;
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transform: translate(-50%, -50%);
	top: 40%;
	left: 50%;
	width: 500px;
	height: 200px;
	background: #fdfefe;
	border-radius: 4px;
`

export const UploadProgress = styled.div`
	padding: 10px 12px;
	background: #f3f3f3;
	font-size: 14px;
	line-height: 18px;
	position: relative;
	max-width: 200px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	.progress {
		opacity: 50%;
		font-size: 10px;
	}
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 100%;
		height: 3px;
		background: #ffcc01;
		left: 0;
		width: ${p => p.progress * 100}%;
	}
`
