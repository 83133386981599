import {
	SAVE_VIDEO_DATA,
	SAVE_LIVE_VIDEO_DATA,
	SAVE_INNOVATION_DATA,
	SAVE_LIVE_INNOVATION_DATA,
	EDIT_INNOVATION_DATA,
	LOG_IN,
	SHOW_NAV,
	HIDE_NAV,
	FORCE_AUTH,
	EDIT_ADMINS,
	SET_ADMIN,
	SAVE_ADMIN_DATA,
	CREATE_ADMIN,
	UPDATE_FEATURED_INNOVATIONS
} from './../constants'
import OktaAuth from '@okta/okta-auth-js'
import { fetchVideoData } from '../../components/Utility'
import { apiUrl as url } from './../../config'
import axios from 'axios'

const authClient = new OktaAuth({
	issuer: OKTA_ISSUER
})

export const saveVideoData = data => ({
	type: SAVE_VIDEO_DATA,
	data
})

export const saveLiveVideoData = data => ({
	type: SAVE_LIVE_VIDEO_DATA,
	data
})

export const editInnovationData = data => ({
	type: EDIT_INNOVATION_DATA,
	data
})

export const editAdmins = data => ({
	type: EDIT_ADMINS,
	data
})

export const setAdmin = data => ({
	type: SET_ADMIN,
	data
})

export const hideNav = data => ({
	type: HIDE_NAV
})

export const showNav = title => ({
	type: SHOW_NAV,
	title
})

export const checkIfAuthed = () => dispatch =>
	authClient.session
		.get()
		.then(session => {
			dispatch(login(true))
		})
		.catch(err => {
			dispatch(login(false))
		})

export const getDownload = url => {
	let { idToken } = JSON.parse(localStorage.getItem('okta-token-storage'))
	window.open(`${url}&token=${idToken.idToken}`)
}

export const getInnovationData = query => async dispatch => {
	let { idToken } = JSON.parse(localStorage.getItem('okta-token-storage'))

	try {
		let {
			data: {
				data: { innovations }
			}
		} = await axios({
			url,
			method: 'post',
			data: { query },
			headers: { Authorization: `Bearer ${idToken.idToken}` }
		})

		dispatch(
			saveInnovationData(
				innovations.map(e => ({
					...e,
					details: JSON.parse(e.details),
					info: JSON.parse(e.info),
					scaled_by: JSON.parse(e.scaled_by),
					assets: JSON.parse(e.assets),
					tags: JSON.parse(e.tags),
					featured: JSON.parse(e.featured),
					links: JSON.parse(e.links),
					archived: JSON.parse(e.archived)
				}))
			)
		)
		let list = await fetchVideoData(
			innovations.map(e => ({
				...e,
				details: JSON.parse(e.details),
				info: JSON.parse(e.info),
				scaled_by: JSON.parse(e.scaled_by),
				assets: JSON.parse(e.assets),
				tags: JSON.parse(e.tags),
				featured: JSON.parse(e.featured),
				links: JSON.parse(e.links),
				archived: JSON.parse(e.archived)
			}))
		)
		dispatch(saveVideoData(list))
	} catch (err) {
		if (err.response) {
			if (err.response.status === 403) {
				dispatch({ type: FORCE_AUTH })
			}
		}
		return console.error('api error: ', err)
	}
}

export const getLiveInnovationData = query => async dispatch => {
	let { idToken } = JSON.parse(localStorage.getItem('okta-token-storage'))

	try {
		let {
			data: {
				data: { innovations }
			}
		} = await axios({
			url,
			method: 'post',
			data: { query },
			headers: { Authorization: `Bearer ${idToken.idToken}` }
		})

		let filteredInnovations = innovations.filter(i => i.archived === 'false')

		dispatch(
			saveLiveInnovationData(
				filteredInnovations.map(e => ({
					...e,
					details: JSON.parse(e.details),
					info: JSON.parse(e.info),
					scaled_by: JSON.parse(e.scaled_by),
					assets: JSON.parse(e.assets),
					tags: JSON.parse(e.tags),
					featured: JSON.parse(e.featured),
					links: JSON.parse(e.links),
					archived: JSON.parse(e.archived)
				}))
			)
		)
		let list = await fetchVideoData(
			filteredInnovations.map(e => ({
				...e,
				details: JSON.parse(e.details),
				info: JSON.parse(e.info),
				scaled_by: JSON.parse(e.scaled_by),
				assets: JSON.parse(e.assets),
				tags: JSON.parse(e.tags),
				featured: JSON.parse(e.featured),
				links: JSON.parse(e.links),
				archived: JSON.parse(e.archived)
			}))
		)
		dispatch(saveLiveVideoData(list))
	} catch (err) {
		if (err.response) {
			if (err.response.status === 403) {
				dispatch({ type: FORCE_AUTH })
			}
		}
		return console.error('api error: ', err)
	}
}
export const saveInnovationData = data => ({
	type: SAVE_INNOVATION_DATA,
	data
})

export const saveLiveInnovationData = data => ({
	type: SAVE_LIVE_INNOVATION_DATA,
	data
})

export const getAdminData = query => async dispatch => {
	let { idToken } = JSON.parse(localStorage.getItem('okta-token-storage'))
	try {
		let {
			data: {
				data: { admins }
			},
			data
		} = await axios({
			url,
			method: 'post',
			data: { query },
			headers: { Authorization: `Bearer ${idToken.idToken}` }
		})
		dispatch(
			saveAdminData(
				admins.map(a => ({
					...a
				}))
			)
		)
	} catch (err) {
		if (err.response) {
			if (err.response.status === 403) {
				dispatch({ type: FORCE_AUTH })
			}
		}
		return console.error('api error: ', err)
	}
}

export const saveAdminData = data => ({
	type: SAVE_ADMIN_DATA,
	data
})

export const updateInnovation = ({
	old_title,
	title,
	description,
	company,
	company_facts,
	contact,
	city,
	summary,
	details,
	info,
	scaled_by,
	assets,
	tags,
	featured,
	featured_index,
	featured_image,
	links,
	archived
}) => async dispatch => {
	const body = {
		query: `
			mutation(
				$old_title: String!
				$title: String!
				$description: String!
				$company: String!
				$company_facts: String!
				$contact: String!
				$city: String!
				$summary: String!
				$details: String!
				$info: String!
				$scaled_by: String!
				$assets: String!
				$tags: String!
				$featured: String!
				$featured_index: String!
				$featured_image: String
				$links: String!
				$archived: String!
			) {
				editInnovation(
					old_title: $old_title,
					title: $title,
					description: $description,
					company: $company,
					company_facts: $company_facts,
					contact: $contact,
					city: $city,
					summary: $summary,
					details: $details,
					info: $info,
					scaled_by: $scaled_by,
					assets: $assets,
					tags: $tags,
					featured: $featured,
					featured_index: $featured_index,
					featured_image: $featured_image,
					links: $links,
					archived: $archived
				) {
					id,
					title,
					description,
					company,
					company_facts,
					contact,
					city,
					summary,
					details,
					info,
					scaled_by,
					assets,
					tags,
					featured,
					featured_index,
					featured_image,
					links,
					archived
				}
			}
		`,
		variables: {
			old_title,
			title,
			description,
			company,
			company_facts,
			contact,
			city,
			summary,
			details: JSON.stringify(details),
			info: JSON.stringify(info),
			scaled_by: JSON.stringify(scaled_by),
			assets: JSON.stringify(assets),
			tags: JSON.stringify(tags),
			featured: JSON.stringify(featured),
			featured_index,
			featured_image,
			links: JSON.stringify(links),
			archived: JSON.stringify(archived)
		}
	}
	let { idToken } = JSON.parse(localStorage.getItem('okta-token-storage'))

	try {
		axios
			.post(url, body, { headers: { Authorization: `Bearer ${idToken.idToken}` } })
			.catch(err => {
				console.log('Error with updateInnovation', err)
			})
			.then(res => {
				// console.log('res.data.data.editInnovation: ', res.data.data.editInnovation)
				let updatedInnovations = [...res.data.data.editInnovation]
				dispatch(
					saveInnovationData(
						updatedInnovations.map(e => ({
							...e,
							details: JSON.parse(e.details),
							info: JSON.parse(e.info),
							scaled_by: JSON.parse(e.scaled_by),
							assets: JSON.parse(e.assets),
							tags: JSON.parse(e.tags),
							featured: JSON.parse(e.featured),
							links: JSON.parse(e.links),
							archived: JSON.parse(e.archived)
						}))
					)
				)
			})
	} catch (err) {
		if (err.response) {
			if (err.response.status === 403) {
				dispatch({ type: FORCE_AUTH })
			}
		}
		return console.error('updateInnovation error: ', err)
	}
}

function findNextId(innovationList) {
	let idList = innovationList.map(innov => Number(innov.id))
	console.log('findNextId: lastId', Math.max(...idList), 'nextID:', Math.max(...idList) + 1)
	return Math.max(...idList) + 1
}

export const createInnovation = (
	{
		title,
		description,
		company,
		company_facts,
		contact,
		city,
		summary,
		details,
		info,
		scaled_by,
		assets,
		tags,
		featured,
		featured_index,
		featured_image,
		links,
		archived
	},
	other_innovations
) => async dispatch => {
	const body = {
		query: `
			mutation(
				$id: ID!
				$title: String!
				$description: String!
				$company: String!
				$company_facts: String!
				$contact: String!
				$city: String!
				$summary: String!
				$details: String!
				$info: String!
				$scaled_by: String!
				$assets: String!
				$tags: String!
				$featured: String!
				$featured_index: String!
				$featured_image: String
				$links: String!
				$archived: String!
			) {
				createInnovation(
					id: $id,
					title: $title,
					description: $description,
					company: $company,
					company_facts: $company_facts,
					contact: $contact,
					city: $city,
					summary: $summary,
					details: $details,
					info: $info,
					scaled_by: $scaled_by,
					assets: $assets,
					tags: $tags,
					featured: $featured,
					featured_index: $featured_index,
					featured_image: $featured_image,
					links: $links,
					archived: $archived,
				) {
					id,
					title,
					description,
					company,
					company_facts,
					contact,
					city,
					summary,
					details,
					info,
					scaled_by,
					assets,
					tags,
					featured,
					featured_index,
					featured_image,
					links,
					archived,
				}
			}
		`,
		variables: {
			id: findNextId(other_innovations),
			title,
			description,
			company,
			company_facts,
			contact,
			city,
			summary,
			details: JSON.stringify(details),
			info: JSON.stringify(info),
			scaled_by: JSON.stringify(scaled_by),
			assets: JSON.stringify(assets),
			tags: JSON.stringify(tags),
			featured: JSON.stringify(featured),
			featured_index,
			featured_image,
			links: JSON.stringify(links),
			archived: JSON.stringify(archived)
		}
	}
	let { idToken } = JSON.parse(localStorage.getItem('okta-token-storage'))

	try {
		axios
			.post(url, body, { headers: { Authorization: `Bearer ${idToken.idToken}` } })
			.catch(err => {
				console.log('Error with createInnovation', err)
			})
			.then(res => {
				let updatedInnovations = [...res.data.data.createInnovation]
				dispatch(
					saveInnovationData(
						updatedInnovations.map(e => ({
							...e,
							details: JSON.parse(e.details),
							info: JSON.parse(e.info),
							scaled_by: JSON.parse(e.scaled_by),
							assets: JSON.parse(e.assets),
							tags: JSON.parse(e.tags),
							featured: JSON.parse(e.featured),
							links: JSON.parse(e.links),
							archived: JSON.parse(e.archived)
						}))
					)
				)
			})
	} catch (err) {
		if (err.response) {
			if (err.response.status === 403) {
				dispatch({ type: FORCE_AUTH })
			}
		}
		return console.error('createInnovation error: ', err)
	}
}

export const deleteInnovation = ({ id, title }) => async dispatch => {
	const body = {
		query: `
			mutation(
				$id: ID!
			) {
				deleteInnovation(
					id: $id,
				) {
					id,
					title,
					description,
					company,
					company_facts,
					contact,
					city,
					summary,
					details,
					info,
					scaled_by,
					assets,
					tags,
					featured,
					featured_index,
					links,
					archived
				}
			}
		`,
		variables: {
			id
		}
	}
	let { idToken } = JSON.parse(localStorage.getItem('okta-token-storage'))
	try {
		axios
			.post(url, body, { headers: { Authorization: `Bearer ${idToken.idToken}` } })
			.catch(err => {
				console.log('Error with deleteInnovation', err)
			})
			.then(res => {
				let updatedInnovations = [...res.data.data.deleteInnovation]
				dispatch(
					saveInnovationData(
						updatedInnovations.map(e => ({
							...e,
							details: JSON.parse(e.details),
							info: JSON.parse(e.info),
							scaled_by: JSON.parse(e.scaled_by),
							assets: JSON.parse(e.assets),
							tags: JSON.parse(e.tags),
							featured: JSON.parse(e.featured),
							links: JSON.parse(e.links),
							archived: JSON.parse(e.archived)
						}))
					)
				)
			})
	} catch (err) {
		if (err.response) {
			if (err.response.status === 403) {
				dispatch({ type: FORCE_AUTH })
			}
		}
		return console.error('deleteInnovation error: ', err)
	}
}

export const createAdmin = ({ name, email }) => async dispatch => {
	const body = {
		query: `
			mutation ($name: String!, $email: String!) {
				createAdmin(
					name: $name,
					email: $email
				) {
					id,
					name,
					email
				}
			}
		`,
		variables: {
			name,
			email
		}
	}

	let { idToken } = JSON.parse(localStorage.getItem('okta-token-storage'))
	try {
		axios
			.post(url, body, { headers: { Authorization: `Bearer ${idToken.idToken}` } })
			.catch(err => {
				console.log('Error with createAdmin', err)
			})
			.then(res => {
				let updatedAdmins = [...res.data.data.createAdmin]
				dispatch(
					saveAdminData(
						updatedAdmins.map(e => ({
							...e
						}))
					)
				)
			})
	} catch (err) {
		if (err.response) {
			if (err.response.status === 403) {
				dispatch({ type: FORCE_AUTH })
			}
		}
		return console.error('api error: ', err)
	}
}

export const deleteAdmin = ({ id }) => async dispatch => {
	const body = {
		query: `
			mutation ($id: ID!) {
				deleteAdmin(
					id: $id
				) {
					id,
					name,
					email
				}
			}
		`,
		variables: {
			id
		}
	}

	let { idToken } = JSON.parse(localStorage.getItem('okta-token-storage'))
	try {
		axios
			.post(url, body, { headers: { Authorization: `Bearer ${idToken.idToken}` } })
			.catch(err => {
				console.log('Error with deleteAdmin', err)
			})
			.then(res => {
				let updatedAdmins = [...res.data.data.deleteAdmin].filter(admin => admin.id != id)
				dispatch(
					saveAdminData(
						updatedAdmins.map(e => ({
							...e
						}))
					)
				)
			})
	} catch (err) {
		if (err.response) {
			if (err.response.status === 403) {
				dispatch({ type: FORCE_AUTH })
			}
		}
		return console.error('deleteAdmin api error: ', err)
	}
}

export const login = data => ({
	type: LOG_IN,
	data
})
