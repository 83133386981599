import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withAuth } from '@okta/okta-react'
import ReactGA from 'react-ga'
import * as Styled from './styled'
import { ScaleProgress } from '../../components/Common'
import { InnovationCard } from '../../components/InnovationCard'
import { GET_INNOVATIONS, GET_FEATURED_INNOVATIONS } from '../Landing'
import { getLiveInnovationData, getFeaturedInnovations, showNav } from '../../store/actions'
import ScaledByList from './ScaledByList'
import Info from './Info'
import HeroCarousel from './HeroCarousel'

import { getDownload } from '../../store/actions'

function shuffle(array) {
	return [].concat(array).sort(() => Math.random() - 0.5)
}

class Innovation extends Component {
	constructor(props) {
		super(props)
		this._playVideo = this._playVideo.bind(this)
		this.state = { videoClicked: false, innovation: null }
		this._shareToWorkplace = this._shareToWorkplace.bind(this)
	}

	componentDidMount() {
		ReactGA.pageview(window.location.pathname)
	}

	_playVideo() {
		this.setState({ videoClicked: true })
	}

	_shareToWorkplace(e) {
		e.preventDefault()
		const {
			match: {
				params: { id }
			},
			title
		} = this.props
		let url = `https://work.facebook.com/sharer.php?display=popup&u=https://10x.tbwa.com/shareable?inid=${id}`
		let options = 'toolbar=0,status=0,resizable=1,width=626,height=436'
		window.open(url, 'sharer', options)
		ReactGA.event({
			category: 'user',
			action: 'shared to workplace',
			label: `${id} - ${title}`
		})
	}

	static getDerivedStateFromProps(props, state) {
		const {
			match: {
				params: { id }
			},
			liveInnovations,
			loadedVimeoData,
			dispatch,
			title,
			featuredInnovations
		} = props

		const { innovation } = state

		if (liveInnovations && loadedVimeoData) {
			state.innovation = liveInnovations.find(i => i.id === id)
		}

		if (!liveInnovations && !loadedVimeoData) {
			console.log('lnch')
			dispatch(getLiveInnovationData(GET_INNOVATIONS))
		}

		if (state.innovation && liveInnovations && title !== state.innovation.title) {
			dispatch(showNav(state.innovation.title))
		}

		return state
	}

	_renderDetail(detail) {
		return <p dangerouslySetInnerHTML={{ __html: detail }} />
	}

	render() {
		const {
			match: {
				params: { id }
			},
			liveInnovations,
			loadedVimeoData
		} = this.props

		const { innovation } = this.state

		if (!liveInnovations || !loadedVimeoData || !innovation)
			return (
				<div
					style={{
						minHeight: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}>
					Loading Innovation...
				</div>
			)

		const {
			title,
			summary,
			details,
			scaled,
			scaled_by,
			info,
			company,
			company_facts,
			contact,
			city,
			videoId,
			assets,
			links
		} = innovation || {}

		return (
			<div style={{ minHeight: '100%' }}>
				<Styled.Wrapper>
					<Styled.Container>
						<HeroCarousel assets={assets} innovationId={id} innovationTitle={title} />
						<Info info={info} mobile id={id} />
						<Styled.Details>
							<Styled.DetailLeft>
								<h1>Executive Summary</h1>
								{this._renderDetail(summary)}
								<h1>Details</h1>
								<h3>Key Business Challenge this innovation overcomes</h3>
								{this._renderDetail(details[0])}
								<h3>Summary of breakthrough results</h3>
								{this._renderDetail(details[1])}
								{!!details[2] && (
									<>
										<h3>Stuff we should know before scaling</h3>
										{this._renderDetail(details[2])}
									</>
								)}
								<h1>About the Author</h1>
								<div>
									<h4 style={{ marginBottom: 0 }}>{company}</h4>
									<p style={{ marginTop: 10 }}> {city}</p>
									<p style={{ marginTop: 10 }}> {company_facts}</p>
									<p>
										Contact: <a href={`mailto:${contact}`}>{contact}</a>
									</p>
								</div>
							</Styled.DetailLeft>
							<Styled.DetailRight>
								<Styled.Scaled>
									<Styled.ScaleProgressWrapper>
										<ScaleProgress scaled={scaled_by.length} />
									</Styled.ScaleProgressWrapper>
									<ScaledByList scaled_by={scaled_by} />
								</Styled.Scaled>
								<Info info={info} id={id} />
								<Styled.StarterKit src={'../test.svg'}>
									<h1>Downloads</h1>
									<h3>Available material for this innovation:</h3>
									<Styled.BtnContainer>
										{links.map((link, i) => (
											<Styled.FileButton
												arrow={'../triangle.svg'}
												key={`${link.text}-${i}`}
												onClick={e => {
													ReactGA.event({
														category: 'user',
														action: `${link.text}`,
														label: `${id} - ${title}`
													})
													if (link.link.match(/^\/file/)) {
														e.preventDefault()
														getDownload(link.link)
													}
												}}
												href={link.link}
												target="_blank">
												{link.text}
											</Styled.FileButton>
										))}

										<Styled.WorkplaceBtn
											onClick={this._shareToWorkplace}
											arrow={'../share_arrow.svg'}>
											Share to Workplace
										</Styled.WorkplaceBtn>
									</Styled.BtnContainer>
								</Styled.StarterKit>
							</Styled.DetailRight>
						</Styled.Details>
						<Styled.ExploreH3>Explore Innovations</Styled.ExploreH3>
						<Styled.InnovationsContainer>
							{shuffle(liveInnovations.filter(i => parseInt(i.id) !== parseInt(id)))
								.slice(0, 2)
								.map((e, i) => (
									<InnovationCard
										thumbnailSrc={
											e.assets[0].vimeoData
												? e.assets[0].vimeoData.thumbnail_url
												: e.assets[0].data.src
										}
										data={e}
										key={i}
										rootPath={'../'}
										showTags={true}
									/>
								))}
						</Styled.InnovationsContainer>
					</Styled.Container>
				</Styled.Wrapper>
			</div>
		)
	}
}

export default connect(
	({
		videoData,
		liveInnovations,
		loadedVimeoData,
		authenticated,
		nav,
		title,
		featuredInnovations
	}) => ({
		videoData,
		liveInnovations,
		loadedVimeoData,
		authenticated,
		nav,
		title,
		featuredInnovations
	})
)(withAuth(Innovation))
