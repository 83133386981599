import React from 'react'
import ReactDOM from 'react-dom'
import App from './components'
import { createLogger } from 'redux-logger'
import { Provider as ReduxProvider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { reducer } from './store/reducers'
import thunk from 'redux-thunk'

let logger = createLogger()
let middleware = ENV === 'development' ? applyMiddleware(thunk, logger) : applyMiddleware(thunk)
let store = createStore(reducer, middleware)

ReactDOM.render(
	<ReduxProvider store={store}>
		<App />
	</ReduxProvider>,
	document.getElementById('root')
)
