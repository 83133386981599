import React from 'react'
import {
	FormSectionContainer,
	FormInputWithButtonContainer,
	ScaledAgencyContainer,
	FormSectionTitle,
	CancelBtn,
	VerticalBorderSpacer
} from './styled'

const ScaledAgency = ({ ts, removeScaledAgency }) => {
	return (
		<ScaledAgencyContainer>
			<CancelBtn style={{ marginRight: 10 }} onClick={() => removeScaledAgency(ts)}>
				X
			</CancelBtn>
			<VerticalBorderSpacer />
			<span>{ts}</span>
		</ScaledAgencyContainer>
	)
}

export const Scaled = ({
	handleChange,
	addScaledAgency,
	removeScaledAgency,
	scaledByInput,
	scaled_by
}) => {
	let scaledAgencyMap = scaled_by.map((ts, i) => {
		return <ScaledAgency ts={ts} key={i} removeScaledAgency={removeScaledAgency} />
	})
	return (
		<FormSectionContainer>
			<FormSectionTitle>Scaled By</FormSectionTitle>
			<form>
				<FormInputWithButtonContainer>
					<label htmlFor="scaled_by">Agency</label>
					<div className="inputField">
						<input
							type="text"
							onChange={handleChange}
							value={scaledByInput}
							name="scaledByInput"
						/>
						<button onClick={addScaledAgency}>Add</button>
					</div>
				</FormInputWithButtonContainer>
			</form>
			{scaledAgencyMap}
		</FormSectionContainer>
	)
}

export default Scaled
