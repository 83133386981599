import React from 'react'
import { FormSectionContainer, FormInputContainer, FormSectionTitle } from './styled'

export const InfoPanel = ({ handleChange, type, impact, rollout, risks, costs }) => {
	return (
		<FormSectionContainer>
			<FormSectionTitle>Right Side Info Panel</FormSectionTitle>
			<form>
				<FormInputContainer>
					<label htmlFor="type">Innovation Type</label>
					<input type="text" onChange={handleChange} value={type} name="type" />
				</FormInputContainer>
				<FormInputContainer>
					<label htmlFor="impact">Impact</label>
					<input type="text" onChange={handleChange} value={impact} name="impact" />
				</FormInputContainer>
				<FormInputContainer>
					<label htmlFor="rollout">Rollout</label>
					<input type="text" onChange={handleChange} value={rollout} name="rollout" />
				</FormInputContainer>
				<FormInputContainer>
					<label htmlFor="risks">Risks</label>
					<input type="text" onChange={handleChange} value={risks} name="risks" />
				</FormInputContainer>
				<FormInputContainer>
					<label htmlFor="costs">Costs</label>
					<input type="text" onChange={handleChange} value={costs} name="costs" />
				</FormInputContainer>
			</form>
		</FormSectionContainer>
	)
}

export default InfoPanel
