import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import { GET_INNOVATIONS, GET_ADMINS } from '../pages/Landing'
import { GlobalStyle } from './styled'
import { getLiveInnovationData, getInnovationData, getAdminData } from '../store/actions'
import { Main } from './main'

const App = ({
	liveInnovations,
	admins = [],
	authenticated,
	adminCheck,
	isAdmin,
	dispatch,
	nav,
	title,
	checkedAuth
}) => {
	const [admins1, setAdmins] = useState(admins)
	if (!liveInnovations && authenticated && checkedAuth) {
		dispatch(getLiveInnovationData(GET_INNOVATIONS))
		dispatch(getInnovationData(GET_INNOVATIONS))
		dispatch(getAdminData(GET_ADMINS))
	}

	const initGA = () => ReactGA.initialize('UA-135670362-3')

	useEffect(() => {
		initGA()
		setAdmins(admins)
	}, [admins])

	return (
		<Router>
			<div style={{ minHeight: '100%', overflow: 'hidden' }}>
				<Main
					nav={nav}
					admins={admins}
					isAdmin={isAdmin}
					title={title}
					adminLookupComplete={adminCheck}
				/>
				<GlobalStyle />
			</div>
		</Router>
	)
}

export default connect(
	({
		liveInnovations,
		innovations,
		featuredInnovations,
		admins,
		isAdmin,
		authenticated,
		nav,
		title,
		adminCheck,
		checkedAuth
	}) => ({
		liveInnovations, //live innovations, minus archived
		innovations, //full innovation list for admin, including archived
		featuredInnovations,
		admins,
		authenticated,
		nav,
		title,
		isAdmin,
		adminCheck,
		checkedAuth
	})
)(App)
