export const SAVE_LIVE_VIDEO_DATA = 'SAVE_LIVE_VIDEO_DATA'
export const SAVE_VIDEO_DATA = 'SAVE_VIDEO_DATA'
export const SAVE_LIVE_INNOVATION_DATA = 'SAVE_LIVE_INNOVATION_DATA'
export const SAVE_INNOVATION_DATA = 'SAVE_INNOVATION_DATA'
export const EDIT_INNOVATION_DATA = 'EDIT_INNOVATION_DATA'
export const LOG_IN = 'LOG_IN'
export const SHOW_NAV = 'SHOW_NAV'
export const HIDE_NAV = 'HIDE_NAV'
export const FORCE_AUTH = 'FORCE_AUTH'
export const EDIT_ADMINS = 'EDIT_ADMINS'
export const SET_ADMIN = 'SET_ADMIN'
export const SAVE_ADMIN_DATA = 'SAVE_ADMIN_DATA'
export const CREATE_ADMIN = 'CREATE_ADMIN'
export const UPDATE_FEATURED_INNOVATIONS = 'UPDATE_FEATURED_INNOVATIONS'
