import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getLiveInnovationData } from '../../store/actions'
import { GET_INNOVATIONS } from '../../pages/Landing'

import * as Styled from './styled'

const Title = ({ children }) => (
	<Styled.Title>
		<Styled.Text>
			<div>{children}</div>
		</Styled.Text>
	</Styled.Title>
)

const TopScore = ({ company, score }) => (
	<Styled.Leader score={score}>
		<Styled.Company>{company}</Styled.Company>
		<Styled.Score>{`${score}pts`}</Styled.Score>
	</Styled.Leader>
)

class Leaderboard extends Component {
	constructor(props) {
		super(props)
		this.state = { leaderboard: null }
	}

	static getDerivedStateFromProps(props, state) {
		const { leaderboard, dispatch } = props

		if (leaderboard) {
			state.leaderboard = leaderboard
		}

		if (!leaderboard) {
			dispatch(getLiveInnovationData(GET_INNOVATIONS))
		}

		return state
	}

	render() {
		const { leaderboard } = this.state
		return (
			<Styled.Content>
				<Styled.Hero>
					<Styled.Intro>
						This leaderboard celebrates our most innovative 10x agencies across the
						collective. It’s based on how many proven innovations agencies have scaled
						or contributed to 10x. Agencies get 1 point for each innovation contributed
						or scaled.
					</Styled.Intro>
				</Styled.Hero>
				<Title>Top 10x's</Title>
				<Styled.Leaders>
					{leaderboard
						? leaderboard.map(leader => (
								<TopScore
									key={leader.company}
									company={leader.company}
									score={leader.score}
								/>
						  ))
						: null}
				</Styled.Leaders>
			</Styled.Content>
		)
	}
}

export default connect(({ leaderboard }) => ({
	leaderboard
}))(Leaderboard)
