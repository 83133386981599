import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SearchIcon, FilterIcon, Caret, Clear } from '../../components/Icons'
import FeaturedCarousel from './FeaturedCarousel'
import { InnovationCard } from '../../components/InnovationCard'
import { Checkbox, Tag } from '../../components/Common'
import * as Styled from './styled'
import { path } from '../../config'

const spinnerLoader = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: 100,
	width: '100%'
}

const spinnerImg = {
	width: 50,
	height: 50
}

// TODO: REFACTOR
class Content extends Component {
	state = {
		videoData: undefined,
		loading: !this.props.liveInnovations && !this.props.liveVideoData,
		searchInnovations: undefined,
		error: undefined, // ??
		showFilters: false,
		checked: {}, // TODO: rename filters
		filteredFilters: undefined, // ??
		query: '',
		mobile: window.innerWidth < 600,
		mediumScreen: window.innerWidth >= 600 && window.innerWidth < 1024,
		largeScreen: window.innerWidth >= 1024 && window.innerWidth < 1640
	}

	componentDidMount() {
		const { toggleNav } = this.props
		document.addEventListener('scroll', this.handleScroll)
		if (window.location.href.includes('#content')) {
			toggleNav(true)
		}
		this.initResizeEvent()
	}

	componentWillUnmount() {
		document.removeEventListener('scroll', this.handleScroll)
	}

	componentDidUpdate(prevProps, prevState) {
		const { query, checked, loading, showFilters } = this.state
		const { loadedVimeoData } = this.props
		if (loadedVimeoData && loading) {
			this.setState({ loading: false })
		}
		if (prevState.checked !== checked || prevState.query !== query) {
			this.handleSearch(query, this.getActiveFilters())
		}

		// if (prevState.showFilters && !showFilters) {
		// } else if (!prevState.showFilters && showFilters) {
		// }
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.showNav !== this.props.showNav) {
			return false
		}
		return true
	}

	initResizeEvent = () => {
		window.addEventListener('resize', this.handleResize)
	}

	handleResize = ({ target: { innerWidth } }) => {
		const { mobile, mediumScreen, largeScreen } = this.state
		if (innerWidth < 600 && !mobile) {
			this.setState({ mobile: true })
		}
		if (innerWidth >= 600 && innerWidth < 1024 && !mediumScreen) {
			this.setState({ mediumScreen: true })
		}
		if (innerWidth >= 1024 && innerWidth < 1640) {
			this.setState({ largeScreen: true })
		}

		if (innerWidth >= 600 && mobile) {
			this.setState({ mobile: false })
		}
		if ((innerWidth < 600 || innerWidth >= 1024) && mediumScreen) {
			this.setState({ mediumScreen: false })
		}
		if ((innerWidth < 1024 || innerWidth >= 1640) && largeScreen) {
			this.setState({ largeScreen: false })
		}
	}

	// TODO: refactor
	handleSearch = (query, filters) => {
		const {
			props: { liveInnovations },
			hasValue
		} = this
		const searchInnovations = liveInnovations.filter(e => {
			let searchInclude = !(query.length > 0),
				filterInclude = !(filters.length > 0)
			if (!searchInclude) {
				searchInclude =
					hasValue(e.title, query) ||
					hasValue(e.description, query) ||
					hasValue(e.city, query) ||
					hasValue(e.summary, query)
			}
			if (!filterInclude) {
				for (let i = 0; i < e.tags.length; i++) {
					if (filters.indexOf(e.tags[i]) > -1) {
						filterInclude = true
					}
				}
			}
			return searchInclude && filterInclude
		})
		this.setState({ searchInnovations })
	}

	clearFilters = () => {
		this.setState({ checked: {} })
	}

	handleSearchInput = ({ target: { value: query } }) => {
		this.setState({ query })
	}

	handleScroll = ({
		target: {
			scrollingElement: { scrollTop }
		}
	}) => {
		const { toggleNav, showNav } = this.props
		if (!showNav && this.node && this.node.offsetTop - scrollTop <= 155) toggleNav(true)
		else if (showNav && this.node && this.node.offsetTop - scrollTop >= 155) toggleNav(false)
	}

	hasValue(string, value) {
		return string.toLowerCase().includes(value.toLowerCase())
	}

	openFilterOptions = () => {
		this.setState({ showFilters: true })
		setTimeout(() => {
			window.addEventListener('click', this.handleClickOutsideFilter)
			window.addEventListener('touchend', this.handleClickOutsideFilter)
		}, 10)
	}

	closeFilterOptions = () => {
		this.setState({ showFilters: false })
		window.removeEventListener('click', this.handleClickOutsideFilter)
		window.removeEventListener('touchend', this.handleClickOutsideFilter)
	}

	handleClickOutsideFilter = e => {
		if (!this.filterOptionsNode.contains(e.target)) {
			this.closeFilterOptions()
		}
	}

	handleFilterInputChange = ({ target: { value } }) => {
		let filters = this.props.tags.filter(e => this.hasValue(e, value))
		this.setState({ filteredFilters: value.length > 0 ? filters : undefined })
	}

	onFilterSelect = value => {
		const { checked } = this.state
		this.setState({
			checked: {
				...checked,
				[value]: !checked[value]
			}
		})
	}

	renderSelectedFilters = () => {
		const { mobile, mediumScreen, largeScreen } = this.state
		let max = mobile ? 1 : mediumScreen ? 2 : largeScreen ? 4 : 6
		const activeFilters = this.getActiveFilters()
		return max < activeFilters.length ? (
			<div>
				<Styled.Ellipsis>...</Styled.Ellipsis>
				{activeFilters.slice(activeFilters.length - max).map((e, i) => (
					<Tag key={i} label={e} />
				))}
			</div>
		) : (
			activeFilters.map((e, i) => <Tag key={i} label={e} />)
		)
	}

	getActiveFilters = () => {
		const { checked } = this.state
		return Object.keys(checked).filter(e => !!checked[e])
	}

	setRef = el => (this.node = el)

	setFilterRef = el => (this.filterNode = el)

	setFilterOptionsRef = el => (this.filterOptionsNode = el)

	render() {
		const { liveInnovations } = this.props
		const {
			loading,
			searchInnovations,
			showFilters,
			checked,
			filteredFilters,
			mobile
		} = this.state

		const activeFilterCount = this.getActiveFilters().length

		liveInnovations !== undefined
			? liveInnovations.sort((a, b) =>
					Number(a.id) > Number(b.id) ? -1 : Number(a.id) < Number(b.id) ? 1 : 0
			  )
			: null

		let featuredInnovations = []

		if (!loading) {
			featuredInnovations = liveInnovations
				.filter(e => e.featured)
				.sort((a, b) =>
					Number(a.featured_index) > Number(b.featured_index)
						? 1
						: Number(a.featured_index) < Number(b.featured_index)
						? -1
						: 0
				)
		}
		return (
			<Styled.InnovationsContainer
				id="innovations"
				ref={this.setRef}
				filtering={activeFilterCount > 0}>
				{loading ? (
					<div style={spinnerLoader}>
						<img style={spinnerImg} src={`${path}spinner.svg`} />
					</div>
				) : (
					<>
						<div id="featured" className="carousel">
							<div>Featured Innovations</div>
							<FeaturedCarousel featured={featuredInnovations} />
						</div>
						<div className="search">
							<div>
								<SearchIcon />
								<input onChange={this.handleSearchInput} placeholder="Search" />
							</div>
							<div ref={this.setFilterRef}>
								{showFilters ? (
									<div className="svg">
										<Caret />
									</div>
								) : (
									<div className="svg" onClick={this.openFilterOptions}>
										<FilterIcon />
									</div>
								)}
								<Styled.SelectedFilters>
									{this.renderSelectedFilters()}
								</Styled.SelectedFilters>
								{activeFilterCount === 0 && (
									<Styled.FilterLabel
										onClick={!showFilters ? this.openFilterOptions : undefined}>
										Filter by Tags
									</Styled.FilterLabel>
								)}
								{// TODO: use CSS instead?
								activeFilterCount > 0 &&
									(mobile ? (
										<Styled.MobileClearFilters onClick={this.clearFilters}>
											<Clear />
										</Styled.MobileClearFilters>
									) : (
										<Styled.ClearFilters onClick={this.clearFilters}>
											Clear filters <Clear />
										</Styled.ClearFilters>
									))}
								<Styled.FilterOptions
									ref={this.setFilterOptionsRef}
									show={showFilters}>
									{(filteredFilters || this.props.tags).map((e, i) => (
										<Checkbox
											key={i}
											checked={!!checked[e]}
											label={e}
											onChangeHandler={this.onFilterSelect}
											disabled={false}
										/>
									))}
								</Styled.FilterOptions>
							</div>
						</div>
						<div className="innovations">
							{(searchInnovations || liveInnovations)
								.sort((a, b) =>
									Number(a.id) > Number(b.id)
										? -1
										: Number(a.id) < Number(b.id)
										? 1
										: 0
								)
								.map((data, i) => (
									<InnovationCard
										thumbnailSrc={
											data.assets[0] != null
												? data.assets[0].vimeoData
													? data.assets[0].vimeoData.thumbnail_url
													: data.assets[0].data.src
												: ''
										}
										data={data}
										key={i}
										mobile={mobile}
										showTags={true}
									/>
								))}
						</div>
					</>
				)}
			</Styled.InnovationsContainer>
		)
	}
}

export default connect(({ liveVideoData, liveInnovations, tags, loadedVimeoData }) => ({
	liveVideoData,
	liveInnovations,
	loadedVimeoData,
	tags
}))(Content)
