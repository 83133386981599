import React, { useState, useEffect, useRef } from 'react'
import dragula from 'react-dragula'
import 'react-quill/dist/quill.snow.css'
import { connect } from 'react-redux'
import withAuth from '@okta/okta-react/dist/withAuth'
import { MediaModal } from '../../components/admin/form/MediaModal'
import { DeleteModal } from '../../components/admin/form/DeleteModal'

import { ParentFormContainer, FormContainer } from './styled'
import {
	FormSectionContainer,
	FormSectionTitle,
	CarouselContainer,
	CarouselTitleBar,
	CarouselThumbnail,
	YellowButton
} from '../../components/admin/form/styled'
import {
	Name,
	Summary,
	Scaled,
	InfoPanel,
	Tags,
	TopBar,
	BottomBar,
	Files
} from '../../components/admin/form'

const InnovationForm = props => {
	const [displayModal, setDisplayModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)

	const thumbContainer = useRef(null)
	const drake = useRef(null)

	const [innovation, setInnovation] = useState(null)
	const [loadedInnovation, setLoadedInnovation] = useState(false)
	useEffect(() => {
		if (props.innovations && props.editingInnovation) {
			setInnovation(
				props.innovations.find(innovation => innovation.id === props.match.params.id)
			)
		}
	}, [props.innovations])

	useEffect(() => {
		if (innovation) {
			let title = innovation.title
			let description = innovation.description
			let summary = innovation.summary
			let company = innovation.company
			let contact = innovation.contact
			let details = innovation.details
			let tags = innovation.tags
			let scaled_by = innovation.scaled_by
			let info = innovation.info
			let links = innovation.links
			let assets = innovation.assets
			let city = innovation.city
			let company_facts = innovation.company_facts
			let featured = innovation.featured
			let featured_index = innovation.featured_index
			let archived = innovation.archived
			let keychal = ''
			let sumres = ''
			let knowabt = ''
			if (details[0]) keychal = details[0]
			if (details[1]) sumres = details[1]
			if (details[2]) knowabt = details[2]
			let featured_image = innovation.featured_image || null
			const { type, rollout, impact, risks, costs } = info

			setState({
				old_title: title,
				title: title,
				description: description,
				summary: summary,
				keychal: keychal,
				sumres: sumres,
				knowabt: knowabt,
				company: company,
				contact: contact,
				tags: tags,
				tagInput: '',
				scaled_by: scaled_by,
				scaledByInput: '',
				type: type,
				impact: impact,
				rollout: rollout,
				risks: risks,
				costs: costs,
				links: links,
				linkTitle: '',
				linkUrl: '',
				assets: assets,
				videoPath: '',
				videoTitle: '',
				videoDesc: '',
				city: city,
				company_facts,
				featured,
				featured_index,
				featured_image,
				archived: archived
			})
			setThumbnailArray(assets)
			setLoadedInnovation(true)
		} else {
			console.log('creating new innovation')
		}
	}, [innovation])

	let [thumbnailArray, setThumbnailArray] = useState([])

	const [state, setState] = useState({
		old_title: '',
		title: '',
		description: '',
		summary: '',
		keychal: '',
		sumres: '',
		knowabt: '',
		company: '',
		contact: '',
		tags: [],
		tagInput: '',
		scaled_by: [],
		scaledByInput: '',
		type: '',
		impact: '',
		rollout: '',
		risks: '',
		costs: '',
		links: [],
		linkTitle: '',
		linkUrl: '',
		assets: [],
		videoPath: '',
		videoTitle: '',
		videoDesc: '',
		city: '',
		company_facts: '',
		featured: false,
		featured_index: 'none',
		featured_image: null,
		archived: false
	})

	useEffect(() => {
		if (thumbContainer.current && drake.current === null) {
			drake.current = dragula([thumbContainer.current], {
				direction: 'horizontal',
				copy: false,
				copySortSource: false,
				revertOnSpill: false,
				removeOnSpill: false,
				mirrorContainer: document.body,
				ignoreInputTextSelection: true,
				slideFactorX: 0,
				slideFactorY: 0
			})
			drake.current.on('dragend', dragComplete)
		}
	})

	const dragComplete = () => {
		resolveUpdatedOrder(thumbContainer.current)
	}

	const resolveUpdatedOrder = nodeList => {
		let imageSrcOrder = []
		let NodeArray = [...nodeList.current.childNodes]
		NodeArray.map(thumb => {
			imageSrcOrder.push(thumb.firstChild.attributes[0].nodeValue)
		})

		thumbnailArray = imageSrcOrder.map(imageSrc => {
			return assets.find(assetObj => {
				if (assetObj.type === 'IMAGE') {
					return assetObj.data.src === imageSrc
				} else if (assetObj.type === 'VIMEO') {
					return assetObj.data.poster === imageSrc
				}
			})
		})
		setThumbnailArray(thumbnailArray)
	}

	const renderThumbnail = (thumbData, i) => {
		let imgPath =
			thumbData.type === 'VIMEO'
				? thumbData.data.poster ||
				  (thumbData.vimeoData && thumbData.vimeoData.thumbnail_url)
				: thumbData.data.src
		return (
			<CarouselThumbnail key={`thumbnail-${i}-${imgPath}`}>
				<img key={`img-thumb-${i}`} src={imgPath} />
				<div
					key={`delete-${i}`}
					className="deleteBtn"
					onClick={() => handleDeleteThumbnail(thumbData)}></div>
			</CarouselThumbnail>
		)
	}

	const handleDeleteThumbnail = thumbData => {
		let imgPath =
			thumbData.type === 'VIMEO'
				? thumbData.data.poster ||
				  (thumbData.vimeoData && thumbData.vimeoData.thumbnail_url)
				: thumbData.data.src
		let updatedThumbnailArray
		let updatedAssets
		if (thumbData.type === 'VIMEO') {
			updatedThumbnailArray = thumbnailArray.filter(
				thumb =>
					thumb.type === 'IMAGE' ||
					(thumb.data.poster != imgPath && thumb.vimeoData.thumbnail_url != imgPath)
			)
			updatedAssets = thumbnailArray.filter(
				thumb =>
					thumb.type === 'IMAGE' ||
					(thumb.data.poster != imgPath && thumb.vimeoData.thumbnail_url != imgPath)
			)
		}
		if (thumbData.type === 'IMAGE') {
			updatedThumbnailArray = thumbnailArray.filter(thumb => thumb.data.src != imgPath)
			updatedAssets = thumbnailArray.filter(thumb => thumb.data.src != imgPath)
		}

		setThumbnailArray(updatedThumbnailArray)
		setState({ ...state, assets: updatedAssets })
	}

	const handleChange = e => {
		const name = e.target.name
		const val = e.target.value
		setState({
			...state,
			[name]: val
		})
	}

	const handleRichText = (tagName, html) => {
		const name = tagName
		const val = html
		setState({
			...state,
			[name]: val
		})
	}
	const addTag = e => {
		if (e.key === 'Enter') {
			const tags = state.tags
			tags.push(state.tagInput)
			setState({
				...state,
				tags,
				tagInput: ''
			})
		}
	}

	const removeTag = tagToDelete => {
		const tags = state.tags.filter(tag => {
			return tag != tagToDelete
		})
		setState({
			...state,
			tags
		})
	}

	const addScaledAgency = e => {
		e.preventDefault()
		const times = state.scaled_by.slice()
		times.push(state.scaledByInput)
		setState({
			...state,
			scaled_by: times,
			scaledByInput: ''
		})
	}

	const removeScaledAgency = ts => {
		const scaled_by = state.scaled_by.filter(t => {
			return ts != t
		})
		setState({
			...state,
			scaled_by
		})
	}

	const addLink = e => {
		e.preventDefault()
		const newLink = {
			text: state.linkTitle,
			link: state.linkUrl
		}
		const links = state.links.slice()
		links.push(newLink)
		setState({
			...state,
			links,
			linkTitle: '',
			linkUrl: ''
		})
	}

	const removeLink = title => {
		const links = state.links.filter(link => {
			return link.text != title
		})
		setState({
			...state,
			links
		})
	}

	if (props.editingInnovation && (!innovation || !loadedInnovation)) {
		return null
	}

	//console.log('editInnovation:', props.editInnovation, 'innovations: ', props.innovations)
	return (
		<ParentFormContainer>
			<TopBar
				data={state}
				thumbnailArray={thumbnailArray}
				dispatch={props.dispatch}
				editingInnovation={props.editingInnovation}
				innovations={props.innovations}
			/>
			<FormContainer>
				<Name
					handleChange={handleChange}
					title={state.title}
					description={state.description}
					featuredImage={state.featured_image}
				/>
				<FormSectionContainer>
					<CarouselTitleBar>
						<FormSectionTitle style={{ marginBottom: 0 }}>
							Innovation Carousel
						</FormSectionTitle>
						<YellowButton onClick={() => setDisplayModal(true)}>
							Add Image or Video
						</YellowButton>
					</CarouselTitleBar>
					<CarouselContainer ref={thumbContainer}>
						{state.assets.map((thumb, i) => renderThumbnail(thumb, i))}
					</CarouselContainer>
				</FormSectionContainer>
				<Summary
					handleChange={handleChange}
					summary={state.summary}
					keychal={state.keychal}
					sumres={state.sumres}
					knowabt={state.knowabt}
					company={state.company}
					city={state.city}
					contact={state.contact}
					handleRichText={handleRichText}
				/>
				<Tags
					addTag={addTag}
					removeTag={removeTag}
					handleChange={handleChange}
					tags={state.tags}
					tagInput={state.tagInput}
				/>
				<Scaled
					addScaledAgency={addScaledAgency}
					removeScaledAgency={removeScaledAgency}
					handleChange={handleChange}
					scaled_by={state.scaled_by}
					scaledByInput={state.scaledByInput}
				/>
				<InfoPanel
					handleChange={handleChange}
					type={state.type}
					impact={state.impact}
					rollout={state.rollout}
					risks={state.risks}
					costs={state.costs}
				/>
				<Files
					handleChange={handleChange}
					addLink={addLink}
					removeLink={removeLink}
					linkTitle={state.linkTitle}
					linkUrl={state.linkUrl}
					links={state.links}
				/>
				{deleteModal && (
					<DeleteModal
						setDeleteModal={setDeleteModal}
						dispatch={props.dispatch}
						innovation={innovation}
					/>
				)}
				{displayModal && (
					<MediaModal
						handleChange={handleChange}
						assets={state.assets}
						videoPath={state.videoPath}
						videoTitle={state.videoTitle}
						videoDesc={state.videoDesc}
						setDisplayModal={setDisplayModal}
						dispatch={props.dispatch}
						innovation={innovation}
						onSave={assets => {
							setState({ ...state, assets: [...state.assets, ...assets] })
							setThumbnailArray([...thumbnailArray, ...assets])
						}}
					/>
				)}
			</FormContainer>
			<BottomBar
				data={state}
				setDeleteModal={setDeleteModal}
				thumbnailArray={thumbnailArray}
				dispatch={props.dispatch}
				editingInnovation={props.editingInnovation}
				innovations={props.innovations}
			/>
		</ParentFormContainer>
	)
}

export default connect(({ editInnovation, innovations }) => ({
	editInnovation,
	innovations
}))(withAuth(InnovationForm))
