import React, { useEffect, useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { path } from '../../../config'
import styled from 'styled-components'
import {
	CardDescription,
	CardButtonIcon,
	FeaturedIndexHeader,
	FeaturedIndexHeaderLine
} from './styled'

let AdminInnovationCardStyle = {
	position: 'relative',
	height: 170,
	width: 312,
	background: '#f7f7f7',
	borderRadius: '20px',
	padding: '30px 30px 20px 30px',
	marginRight: '20px',
	marginBottom: '20px'
}

let FeatureIndexOrderStyle = {
	fontSize: 12,
	color: '#999',
	marginBottom: 10
}

let buttonRowStyle = {
	display: 'flex',
	width: '80%',
	justifyContent: 'space-between',
	position: 'absolute',
	bottom: 20
}

let titleStyle = {
	fontSize: 16,
	fontWeight: 'bold'
}

let editStyle = {
	height: 32,
	width: 81,
	background: '#FFCC01',
	borderRadius: '30px',
	border: 'none',
	cursor: 'pointer'
}

let classifyStyle = {
	display: 'flex',
	fontSize: 12,
	alignItems: 'center',
	cursor: 'pointer'
}

export const FeaturedAdminInnovationCard = withRouter(props => {
	const [prevIndex, setPrevIndex] = useState(props.innov.featured_index)
	const archiveClick = () => {
		let updatedInnovation = JSON.parse(JSON.stringify(props.innov))
		updatedInnovation.archived = !updatedInnovation.archived
		updatedInnovation.old_title = updatedInnovation.title
		updatedInnovation.featured = false
		updatedInnovation.featured_index = 'none'
		props.setInnovation(updatedInnovation)
	}

	const featureClick = () => {
		let updatedInnovation = JSON.parse(JSON.stringify(props.innov))
		let newFeaturedState = !updatedInnovation.featured
		if (newFeaturedState === true && updatedInnovation.archived === true) {
			updatedInnovation.archived = false
		}
		updatedInnovation.old_title = updatedInnovation.title
		updatedInnovation.featured = newFeaturedState
		updatedInnovation.featured_index =
			newFeaturedState === false ? 'none' : String(props.featuredLength)
		props.setInnovation(updatedInnovation)
	}

	// useEffect(() => {
	// 	let feature_index = Number(props.innov.featured_index)
	// 	console.log(
	// 		props.innov.title,
	// 		' | featuredIndex :',
	// 		props.featuredIndex,
	// 		'feature_index :',
	// 		feature_index,
	// 		'prevIndex',
	// 		prevIndex
	// 	)
	// 	if (feature_index !== prevIndex) {
	// 		let updatedInnovation = JSON.parse(JSON.stringify(props.innov))
	// 		updatedInnovation.old_title = updatedInnovation.title
	// 		updatedInnovation.featured_index = String(props.featuredIndex)
	// 		props.setInnovation(updatedInnovation)
	// 	}
	// }, [props.featuredIndex])

	return (
		<div>
			<FeaturedIndexHeader>
				{props.featuredIndex + 1}
				<FeaturedIndexHeaderLine />
			</FeaturedIndexHeader>
			<div style={AdminInnovationCardStyle}>
				<div>
					{/* Main Card Content*/}
					<div style={titleStyle}>{props.title}</div>
					<CardDescription>{props.description}</CardDescription>
					<div style={buttonRowStyle}>
						<div style={classifyStyle} onClick={() => archiveClick()}>
							{props.innov.archived ? (
								<CardButtonIcon src={`${path}admin/archive.png`} />
							) : (
								<CardButtonIcon src={`${path}admin/archive-outline.png`} />
							)}
							<div>&nbsp;Archive</div>
						</div>
						<div style={classifyStyle} onClick={() => featureClick()}>
							{props.innov.featured ? (
								<CardButtonIcon src={`${path}admin/Vector.png`} />
							) : (
								<CardButtonIcon src={`${path}admin/star-outline.png`} />
							)}
							<div>&nbsp;Featured</div>
						</div>
						<button style={editStyle} onClick={() => props.handleEdit(props.innov)}>
							Edit
						</button>
					</div>
				</div>
			</div>
		</div>
	)
})
