import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { GET_INNOVATIONS } from '../../../pages/Landing'
import { getInnovationData, updateInnovation } from '../../../store/actions/index'
import { withRouter, Link } from 'react-router-dom'
import withAuth from '@okta/okta-react/dist/withAuth'
import { connect } from 'react-redux'
import { Vimeo } from 'vimeo'
import * as tus from 'tus-js-client'
import axios from 'axios'
import { useDropzone } from 'react-dropzone'

import {
	ModalBlackout,
	MediaModalContainer,
	FormSectionTitle,
	YellowButton,
	GreyButton,
	Tabs,
	Tab,
	ImagePreview,
	Thumbnails
} from './styled'

let sectionStyle = {
	padding: 20,
	opacity: 0.5,
	border: '1px solid #8F9BB3',
	boxSizing: 'border-box',
	borderRadius: '4px',
	marginBottom: 15
}

let addImageStyle = {}

let sectionTitleStyle = {
	color: '#8F9BB3',
	fontSize: 18,
	marginBottom: 15
}

let labelTitleStyle = {
	color: '#8F9BB3',
	fontSize: 12
}

let inputTitleStyle = {
	fontSize: 12,
	color: '#8F9BB3'
}

let formInputStyle = {
	opacity: 0.5,
	height: 40,
	background: '#FDFEFE',
	border: '1px solid #9CA8BD',
	boxSizing: 'border-box',
	borderRadius: '4px',
	width: '100%',
	marginBottom: 20,
	padding: 12,
	fontSize: 12
}
let uploadImageStyle = {
	height: 88,
	border: '1px solid #9CA8BD',
	width: '100%',
	borderRadius: '4px',
	marginBottom: 15,
	opacity: 0.5
}
let saveButtonStyle = {
	marginLeft: 15,
	height: 31,
	width: 77,
	background: '#FFCC01',
	boxShadow: '3px 3px 10px rgba(11, 27, 102, 0.304824)',
	borderRadius: '20px'
}
let cancelButtonStyle = {
	height: 31,
	width: 77,
	background: '#FDFEFE',
	border: '1px solid #DAE1ED',
	borderRadius: '20px'
}

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#aaaaaa',
	outline: 'none',
	transition: 'border .14s ease-out',
	minHeight: 120,
	justifyContent: 'center',
	textAlign: 'center'
}

const activeStyle = {
	borderColor: '#2196f3'
}

const acceptStyle = {
	borderColor: '#00e676'
}

const rejectStyle = {
	borderColor: '#ff1744'
}

const ImageFile = ({ file }) => {
	const [data, setData] = useState(null)
	useEffect(() => {
		const reader = new FileReader()

		reader.onabort = () => console.log('file reading was aborted')
		reader.onerror = () => console.log('file reading has failed')
		reader.onload = () => {
			const binaryStr = reader.result
			setData(binaryStr)
		}
		reader.readAsDataURL(file)
	}, [file])
	return (
		<ImagePreview>
			{data && <img src={data} />}
			<p>{file.name}</p>
		</ImagePreview>
	)
}

export const AddImage = ({
	onChange = () => {},
	multiple = true,
	idleMessage = "Drag 'n' drop some images here, or click to select images",
	acceptingMessage = 'Drop the images to preview...',
	rejectMessage = 'You may only upload images.',
	preview = true
}) => {
	const [images, setImages] = useState([])
	const mounted = useRef(false)

	const onDrop = useCallback(
		acceptedFiles => {
			setImages([].concat(images, acceptedFiles))
		},
		[images, setImages]
	)

	useEffect(() => {
		if (mounted.current) {
			onChange(multiple ? images : images.length === 1 ? images[0] : images)
		} else {
			mounted.current = true
		}
	}, [images, onChange])

	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		onDrop,
		accept: 'image/*',
		multiple
	})
	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {})
		}),
		[isDragActive, isDragReject, isDragAccept]
	)

	return (
		<div style={addImageStyle}>
			<div {...getRootProps({ style })}>
				<input {...getInputProps()} />
				{isDragActive && isDragAccept ? (
					<p>{acceptingMessage}</p>
				) : isDragReject ? (
					<p>{rejectMessage}</p>
				) : (
					<p>{idleMessage}</p>
				)}
			</div>

			{preview && (
				<>
					{images.length > 0 && <h3>Adding images:</h3>}
					<Thumbnails>
						{images.map(file => (
							<ImageFile file={file} />
						))}
					</Thumbnails>
				</>
			)}
		</div>
	)
}

const AddVideo = ({ handleChange, videoPath, videoTitle, videoDesc, children, onChange }) => {
	const [video, setVideo] = useState(null)
	const mounted = useRef(false)

	const onDrop = useCallback(
		acceptedFiles => {
			if (acceptedFiles.length > 0) setVideo(acceptedFiles[0])
		},
		[video, setVideo]
	)

	useEffect(() => {
		if (mounted.current) {
			onChange(video)
		} else {
			mounted.current = true
		}
	}, [video, onChange])

	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		onDrop,
		multiple: false,
		accept: 'video/*'
	})
	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {})
		}),
		[isDragActive, isDragReject, isDragAccept]
	)

	return (
		<div>
			<FormSectionTitle>Add Vimeo Video</FormSectionTitle>
			<form>
				<div {...getRootProps({ style })}>
					<input {...getInputProps()} />
					{isDragActive && isDragAccept ? (
						<p>Drop the video to upload...</p>
					) : isDragReject ? (
						<p>You may only upload one video.</p>
					) : video ? (
						<p>Drag 'n' drop another video</p>
					) : (
						<p>Drag 'n' drop a video file here</p>
					)}
				</div>
				{video && (
					<>
						<br />
						<label style={inputTitleStyle} htmlFor="videoPath">
							Video
						</label>
						<br />
						<p>{video.name}</p>
					</>
				)}
				<br />
				<label style={inputTitleStyle} htmlFor="videoTitle" name="videoTitle">
					Video Title
				</label>
				<br />
				<input
					style={formInputStyle}
					type="text"
					onChange={e => {
						handleChange(e)
					}}
					name="videoTitle"
					value={videoTitle}
				/>
				<br />
				<label style={inputTitleStyle} htmlFor="videoDesc">
					Video Description
				</label>
				<br />
				<input
					style={formInputStyle}
					type="text"
					onChange={e => {
						handleChange(e)
					}}
					name="videoDesc"
					value={videoDesc}
				/>
			</form>
		</div>
	)
}

let buttonsRow = {
	display: 'flex'
}
let bottomRowStyle = {
	display: 'flex',
	justifyContent: 'flex-end'
}
const headerPost = {
	Accept: 'application/vnd.vimeo.*+json;version=3.4',
	Authorization: `bearer ${process.env.VIMEO_CLIENT_ACCESS_TOKEN}`,
	'Content-Type': 'application/json'
}

export const MediaModal = props => {
	const { onSave } = props

	const [video, setVideo] = useState(null)
	const [uploadingVideo, setUploadingVideo] = useState(false)

	const [videoUploadProgress, setVideoUploadProgress] = useState(0)

	const uploadVideo = async e => {
		if (video && !uploadingVideo) setUploadingVideo(true)

		const file = video
		const fileName = file.name
		const fileSize = file.size.toString()

		const response = await axios({
			method: 'post',
			url: 'https://api.vimeo.com/me/videos',
			headers: headerPost,
			data: {
				upload: {
					approach: 'tus',
					size: fileSize
				},
				name: fileName,
				description: 'Hello'
			}
		})
		let videoId = response.data.uri
		let videoApiId = response.data.link.slice(18)
		let client = new Vimeo(
			process.env.VIMEO_CLIENT_ID,
			process.env.VIMEO_CLIENT_SECRET,
			process.env.VIMEO_CLIENT_ACCESS_TOKEN
		)

		const upload = new tus.Upload(file, {
			endPoint: 'https://api.vimeo.com/me/videos',
			uploadUrl: response.data.upload.upload_link,
			retryDelays: [0, 3000, 5000, 10000, 20000],
			metadata: {
				filename: file.name,
				filetype: file.type
			},
			headers: {},
			onError: function(err) {
				console.log('Failed:', err)
			},
			onProgress: function(bytesUploaded, bytesTotal) {
				let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
				setVideoUploadProgress(`${percentage}%`)
			},
			onSuccess: function() {
				let uri = '/me/projects/574262' + videoId
				client.request(
					{
						method: 'PUT',
						path: uri
					},
					function(error, videoBody, status_code, headers) {
						if (error) {
							console.log(error)
						} else {
							let presetId = '120802043'
							let uri = videoId + '/presets/' + presetId
							client.request(
								{
									method: 'PUT',
									path: uri
								},
								async function(err, body, status_code, headers) {
									if (err) {
									} else {
										setVideoUploadProgress(`Downloading video metadata...`)
										// Add Vimeo asset to innovation

										client.request(
											{
												method: 'GET',
												path: `${videoId}/pictures`
											},
											function(err, body, status_code, headers) {
												let vimeoData = {
													data: {
														videoApiId,
														videoId: videoId.slice(8),
														videoLink: ''
													},
													type: 'VIMEO'
												}
												onSave([vimeoData])
												props.setDisplayModal(false)
											}
										)
									}
								}
							)
						}
					}
				)
			}
		})
		upload.start()
	}

	const [tab, setTab] = useState('IMAGE')
	const [saving, setSaving] = useState(false)

	const [images, setImages] = useState([])

	const handleSave = useCallback(() => {
		if (tab === 'IMAGE') {
			if (!saving) {
				setSaving(true)
				const data = new FormData()
				images.forEach(file => data.append('file', file, file.fileName))

				axios
					.post('/upload', data, {
						headers: {
							accept: 'application/json',
							'Accept-Language': 'en-US,en;q=0.8',
							'Content-Type': `multipart/form-data; boundary=${data._boundary}`
						}
					})
					.then(response => {
						onSave(response.data)
						props.setDisplayModal(false)
					})
					.catch(error => {
						//handle error
						console.log('error', error)
					})
			}
		} else if (tab === 'VIDEO') {
			if (video) uploadVideo()
		}
	}, [tab, images, video])

	return (
		<ModalBlackout>
			<MediaModalContainer>
				<Tabs>
					<Tab active={tab === 'IMAGE'} onClick={() => setTab('IMAGE')}>
						Add Images
					</Tab>
					<Tab active={tab === 'VIDEO'} onClick={() => setTab('VIDEO')}>
						Add Video
					</Tab>
				</Tabs>
				<div style={{ padding: 20 }}>
					{tab === 'IMAGE' && (
						<AddImage onChange={images => setImages(images)} assets={props.assets} />
					)}
					{tab === 'VIDEO' && !uploadingVideo && (
						<AddVideo
							handleChange={props.handleChange}
							videoPath={props.videoPath}
							videoTitle={props.videoTitle}
							videoDesc={props.videoDesc}
							onChange={video => setVideo(video)}></AddVideo>
					)}
					{tab === 'VIDEO' && uploadingVideo && (
						<>
							<strong>Upload in progress:</strong>
							<em>{videoUploadProgress}</em>
						</>
					)}
				</div>
				<div style={bottomRowStyle}>
					<YellowButton disabled={saving} onClick={handleSave}>
						Save
					</YellowButton>
					<GreyButton
						onClick={() => {
							props.setDisplayModal(false)
						}}>
						Cancel
					</GreyButton>
				</div>
			</MediaModalContainer>
		</ModalBlackout>
	)
}

export default MediaModal
