import React from 'react'
import styled from 'styled-components'

export const Title = styled.h2`
	text-align: center;
	margin: 0 20px;
	padding: 1.08em 0;
	font-size: 15.6px;
	&:first-child {
		position: relative;
		height: 30px;
		display: inline-flex;
		align-items: center;
		margin: 4px 5px;
		::before {
			content: '';
			transform: skew(20deg);
			background-color: #ffd014;
			height: 30px;
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
		}
	}
	@media (min-width: 768px) {
		font-size: 20px;
	}
	@media (min-width: 768px) {
		font-size: 30px;
	}
	@media (min-width: 2000px) {
		font-size: 60px;
		margin: 43px 0;
	}
`

export const Text = styled.div`
	position: relative;
	display: inline-flex;
	align-items: center;
	margin: 4px 5px;
	padding: 0.3em 0.05em;
	font-weight: 500;
	font-size: inherit;
	> div:first-child {
		padding: 0px 10px;
		position: relative;
		white-space: nowrap;
		margin: 0 auto;
	}
	::before {
		content: '';
		transform: skew(20deg);
		background-color: #ffd014;
		height: 100%;
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
	}
`

export const Intro = styled.p`
	font-weight: 600;
	font-size: 16px;
	line-height: 1.25;
	margin: 0 auto;
	padding: 34px 0;
	@media (min-width: 768px) {
		font-size: 20px;
		padding: 40px 0;
		max-width: 70%;
	}
	@media (min-width: 1200px) {
		font-size: 24px;
		padding: 60px 0;
		max-width: 55%;
	}
	@media (min-width: 2000px) {
		font-size: 34px;
		padding: 96px 0;
	}
`

export const Content = styled.main`
	margin: 48px 0;
`

export const Hero = styled.div`
	border-bottom: 6px solid #eee;
	padding: 0 20px;
`

export const Company = styled.span`
	color: #231f20;
	font-weight: bold;
	margin-right: 0.5em;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: calc(100% - 13px);
	white-space: nowrap;
`

export const Score = styled.span`
	color: #929292;
	font-weight: 100;
`
export const Leaders = styled.ul`
	list-style-type: none;
	padding: 0 20px;
	margin: 0 auto;
	@media (min-width: 768px) {
		max-width: 80%;
	}
`

export const getFontSize = score =>
	score >= 5
		? { xs: '2.4em', sm: '2.6em', md: '2.827em', lg: '2.827em' }
		: score >= 4
		? { xs: '1.8em', sm: '1.999em', md: '1.999em', lg: '1.999em' }
		: score >= 3
		? { xs: '1.414em', sm: '1.414em', md: '1.414em', lg: '1.414em' }
		: { xs: '1em', sm: '1em', md: '1em', lg: '1em' }

export const Leader = styled.li`
	padding: 13.5px 0;
	border-bottom: 1px solid #9b9b9b;
	display: flex;
	font-size: 16px;
	:first-child {
		border-top: 1px solid #9b9b9b;
	}
	@media (min-width: 768px) {
		padding: 28px 0;
		font-size: 24px;
	}
	@media (min-width: 1200) {
		padding: 28px 0;
		font-size: 36px;
	}
	@media (min-width: 2000px) {
		padding: 28px 0;
		font-size: 40px;
	}
	span {
		line-height: 1.24;
		font-size: ${props => getFontSize(+props.score).xs};
		@media (min-width: 768px) {
			font-size: ${props => getFontSize(+props.score).sm};
		}
		@media (min-width: 1200px) {
			font-size: ${props => getFontSize(+props.score).md};
		}
		@media (min-width: 2000px) {
			font-size: ${props => getFontSize(+props.score).lg};
		}
	}
`
