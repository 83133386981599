import React from 'react'
import { deleteInnovation } from '../../../store/actions'
import { withRouter, Link } from 'react-router-dom'

import {
	ModalBlackout,
	DeleteModalContainer,
	FormSectionTitle,
	YellowButton,
	GreyButton
} from './styled'

let bottomRowStyle = {
	display: 'flex',
	justifyContent: 'flex-end'
}

export const DeleteModal = withRouter(props => {
	const handleConfirmClick = () => {
		props.dispatch(deleteInnovation(props.innovation))
		props.setDeleteModal(false)
		props.history.push('/admin')
	}

	return (
		<ModalBlackout>
			<DeleteModalContainer>
				<div style={{ marginBottom: 30 }}>
					{`Are you sure you want to delete "${props.innovation.title}"?`}
				</div>

				<div style={bottomRowStyle}>
					<YellowButton onClick={() => handleConfirmClick()}>Confirm</YellowButton>
					<GreyButton
						onClick={() => {
							props.setDeleteModal(false)
						}}>
						Cancel
					</GreyButton>
				</div>
			</DeleteModalContainer>
		</ModalBlackout>
	)
})

export default DeleteModal
