import React, { Component } from 'react'
import { Switch, Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { login, getInnovationData, editInnovationData } from './../../store/actions'
import { SecureRoute } from '@okta/okta-react'
import withAuth from '@okta/okta-react/dist/withAuth'
import AddAdmin from './AddAdmin'
import InnovationForm from './InnovationForm'
import { GET_INNOVATIONS } from '../Landing'
import { path } from '../../config'
import styled from 'styled-components'
import { InnovationsList } from './InnovationsList'

let sidebarStyle = {
	display: 'flex',
	flexDirection: 'column',
	height: 141,
	minWidth: 72,
	marginRight: 40,
	marginTop: 40,
	marginLeft: 40,
	alignItems: 'center',
	justifyContent: 'space-around',
	background: '#f7f7f7',
	borderRadius: '12px'
}

let adminHomeStyle = {
	height: 40,
	width: 40
}

let addAdminBg = {
	height: 40,
	width: 40
}

let adminUsersStyle = {
	height: 36,
	width: 40,
	margin: 'auto'
}

export const AdminSection = styled.div`
	position: relative;
	padding-bottom: 80px;
	display: flex;
	flex-direction: row;
	background: #fff;
	margin-top: 44px;
	height: auto;
	@media (min-width: 2000px) {
		margin-top: 85px;
	}
`

const Sidebar = ({ sidebar, changeSidebar }) => {
	return (
		<div style={sidebarStyle}>
			<Link to="/admin">
				{sidebar == 'innovationList' ? (
					<div
						style={{
							height: 40,
							width: 40,
							display: 'flex',
							background: '#FFCC01',
							borderRadius: 4
						}}
						onClick={() => {
							changeSidebar('innovationList')
						}}>
						<img
							onClick={() => {
								changeSidebar('innovationList')
							}}
							style={adminHomeStyle}
							src={`${path}admin/home.svg`}
						/>
					</div>
				) : (
					<div
						style={{ width: 40, height: 40, display: 'flex' }}
						onClick={() => {
							changeSidebar('innovationList')
						}}>
						<img
							// onClick={() => {
							// 	changeSidebar('innovationList')
							// }}
							style={adminHomeStyle}
							src={`${path}admin/home.svg`}
						/>
					</div>
				)}
			</Link>
			<Link to="/admin/addadmin">
				{sidebar == 'innovationList' ? (
					<div
						style={{ height: 40, width: 40, display: 'flex' }}
						onClick={() => {
							changeSidebar('addAdmin')
						}}>
						<img style={adminUsersStyle} src={`${path}admin/users.svg`} />
					</div>
				) : (
					<div
						style={{
							height: 40,
							width: 40,
							display: 'flex',
							background: '#FFCC01',
							borderRadius: 4
						}}
						onClick={() => {
							changeSidebar('addAdmin')
						}}>
						<img
							// onClick={() => {
							// 	changeSidebar('addAdmin')
							// }}
							style={adminUsersStyle}
							src={`${path}admin/users.svg`}
						/>
					</div>
				)}
				{/* Image for admin */}
			</Link>
		</div>
	)
}

class Admin extends Component {
	constructor(props) {
		super(props)
		this.state = {
			innovations: this.props.innovations,
			searchInnovations: this.props.innovations,
			searchTerm: '',
			sidebar: 'innovationList'
		}
		this.handleEdit = this.handleEdit.bind(this)
		this.changeSidebar = this.changeSidebar.bind(this)
		this.auth = this.props.auth
		this.authenticated = this.props.authenticated
		this.dispatch = this.props.dispatch
	}

	checkAuth = async () => {
		const isAuthenticated = await this.auth.isAuthenticated()
		if (!isAuthenticated) {
			this.auth.login('/')
		} else if (!this.authenticated) {
			this.dispatch(login(true))
		}
	}

	changeSidebar(sidebar) {
		this.setState({
			sidebar
		})
	}

	componentWillReceiveProps(nextProps) {
		// console.log('nextprops', nextProps)
		if (this.props.innovations !== nextProps.innovations) {
			this.setState({
				innovations: nextProps.innovations
			})
		}
	}

	// shouldComponentUpdate(nextProps, nextState) {
	// 	console.log("SCU", nextProps, nextState)
	// 	if (nextProps.innovations === this.state.innovations) {
	// 		console.log("SCU is false")
	// 		return false;
	// 	} else {
	// 		console.log("SCU is true")
	// 		return true;
	// 	}
	// }

	async componentDidMount() {
		if (!this.state.innovations) {
			let { dispatch } = this.props
			await this.checkAuth()
			await dispatch(getInnovationData(GET_INNOVATIONS))
		}
	}

	handleEdit(innovation) {
		this.props.history.push({
			pathname: `admin/editinnovation/${innovation.id}`
		})
		// console.log('handle edit', innovation)
		this.dispatch(editInnovationData(innovation))
	}

	render() {
		return (
			<AdminSection>
				<Sidebar changeSidebar={this.changeSidebar} sidebar={this.state.sidebar} />
				<Switch>
					{this.state.innovations && this.state.innovations.length > 0 && (
						<SecureRoute
							exact
							path="/admin"
							render={props => (
								<InnovationsList
									{...props}
									handleEdit={this.handleEdit}
									innovations={this.state.innovations}
									dispatch={this.props.dispatch}
								/>
							)}
						/>
					)}
					<SecureRoute
						path={'/admin/addinnovation'}
						render={props => (
							<InnovationForm
								{...props}
								editingInnovation={false}
								dispatch={this.props.dispatch}
							/>
						)}
					/>
					<SecureRoute
						path={'/admin/editinnovation/:id'}
						render={props => (
							<InnovationForm
								{...props}
								editingInnovation={true}
								dispatch={this.props.dispatch}
							/>
						)}
					/>
					<SecureRoute path={'/admin/addadmin'} component={AddAdmin} />
					<SecureRoute render={() => <Redirect to={{ pathname: '/admin' }} />} />
				</Switch>
			</AdminSection>
		)
	}
}

export default connect(({ innovations }) => {
	return { innovations }
})(withAuth(Admin))
