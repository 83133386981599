import gql from 'graphql-tag'

export const GET_ADMINS = `
    {
        admins {
            id
            name
            email
        }
    }
`

export const GET_INNOVATIONS = `
    {
        innovations {
            id
            title
            description
            company
            scaled_by
            company_facts
            contact
            city
            summary
            details
            info
            assets
            tags
            featured
            featured_index
            featured_image
            links
            archived
        }    
    }
`

export default `
    {
        innovations {
            id
            title
            description
            company
            scaled_by
            company_facts
            contact
            city
            summary
            details
            info
            assets
            tags
            featured
            featured_index
            featured_image
            links
            archived
        }    
        admins {
            id
            name
            email
        }
    }
`
