import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { AdminInnovationCard } from '../../components/admin/AdminInnovationCard'
import { FeaturedAdminInnovationCard } from '../../components/admin/FeaturedAdminInnovationCard'
import { FeaturedInnovations } from './FeaturedInnovations'
import { updateInnovation } from './../../store/actions'
import { BlackButton, YellowButton } from '../../components/admin/form/styled'
import {
	InnovationListSearchBarContainer,
	InnovationListTopBarContainer,
	InnovationListSaveChangesButtonContainer,
	InnovationListSearchBarInput
} from './styled'

let innovationsListStyle = {
	width: '90%'
}

let innovationCardsContainerStyle = {
	display: 'flex',
	flexWrap: 'wrap',
	alignContent: 'flex-start',
	justifyContent: 'top'
}

const InnovationSection = ({ title, section, otherSections }) => {
	return (
		<React.Fragment>
			<div style={innovationsTitleStyle}>{title}</div>
			<div style={innovationCardsContainerStyle}>{section}</div>
		</React.Fragment>
	)
}

let innovationsTitleStyle = {
	marginTop: 40,
	fontWeight: 'bold',
	fontSize: 24,
	marginBottom: 28
}

export const InnovationsList = props => {
	const [innovations, setInnovations] = useState([])
	const innovationsRef = useRef([])
	const [searchTerm, setSearchTerm] = useState('')
	const [sinn, setSinn] = useState([])
	const [bChanges, setBChanges] = useState(false)
	const [changedInnovations, setChangedInnovations] = useState([])
	let featuredInnovations = []
	let featuredInnovationCards = []
	let regularInnovations = []
	let regularInnovationCards = []
	let archivedInnovations = []
	let archivedInnovationCards = []
	let searchInnovationCards = []

	useEffect(() => {
		if (props.innovations) {
			setInnovations(props.innovations)
			innovationsRef.current = props.innovations
		}
	}, [props.innovations])

	const handleChange = e => {
		const searchTerm = e.target.value.toLowerCase().replace(/ /g, '')
		setSearchTerm(searchTerm)
		handleSearch()
	}

	const handleSearch = () => {
		let searchInnovations = innovations.filter(innov => {
			let title = innov.title.toLowerCase().replace(/ /g, '')
			let tags = innov.tags.map(tag => tag.toLowerCase().replace(/ /g, '')).join('')
			let city = innov.city.toLowerCase().replace(/ /g, '')
			if (
				title.includes(searchTerm) ||
				tags.includes(searchTerm) ||
				city.includes(searchTerm)
			) {
				return true
			}
		})
		setSinn(searchInnovations)
	}

	const resolveFeatureOrder = updatedFeatures => {
		innovationsRef.current = props.innovations
		setInnovationsInBulk(updatedFeatures)
	}

	const setInnovationsInBulk = data => {
		let newChangedInnovations = changedInnovations

		data.map(item => {
			let findChangedIndex = changedInnovations.findIndex(innov => innov.id === item.id)
			if (findChangedIndex === -1) {
				newChangedInnovations.push(item)
			} else {
				newChangedInnovations[findChangedIndex] = item
			}
		})

		setChangedInnovations(newChangedInnovations)
		setBChanges(true)
	}

	const setInnovation = data => {
		let updatedInnovationData = [...innovations]
		let findInnovationIndex = updatedInnovationData.findIndex(innov => innov.id === data.id)
		updatedInnovationData[findInnovationIndex] = { ...data }

		let newChangedInnovations = changedInnovations
		let findChangedIndex = changedInnovations.findIndex(innov => innov.id === data.id)
		if (findChangedIndex === -1) {
			newChangedInnovations.push(data)
		} else {
			newChangedInnovations[findChangedIndex] = data
		}

		setChangedInnovations(newChangedInnovations)
		setInnovations(updatedInnovationData)
		setBChanges(true)
	}

	const saveChangesClick = () => {
		changedInnovations.map((innovation, i) => {
			let {
				title,
				description,
				company,
				company_facts,
				contact,
				city,
				summary,
				scaled_by,
				assets,
				tags,
				featured,
				featured_index,
				links,
				details,
				info,
				archived
			} = innovation

			let updatedData = JSON.parse(
				JSON.stringify({
					old_title: title,
					title,
					description,
					company,
					company_facts,
					contact,
					city,
					summary,
					details,
					info,
					scaled_by,
					assets,
					tags,
					featured,
					featured_index,
					links,
					archived
				})
			)

			props.dispatch(updateInnovation(updatedData))
		})
		setBChanges(false)
	}

	const updateCards = () => {
		innovations.map(innovation => {
			if (innovation.featured) {
				featuredInnovations.push(innovation)
			}
			if (!innovation.featured && !innovation.archived) {
				regularInnovations.push(innovation)
			}
			if (innovation.archived) {
				archivedInnovations.push(innovation)
			}
		})

		//sort and build the card arrays
		featuredInnovations.sort((a, b) =>
			Number(a.featured_index) > Number(b.featured_index)
				? 1
				: Number(a.featured_index) < Number(b.featured_index)
				? -1
				: 0
		)

		// console.log('featuredInnovations', featuredInnovations)
		const featuredLength = featuredInnovations.length
		featuredInnovations.map(featuredInnov => {
			featuredInnovationCards.push(
				<FeaturedAdminInnovationCard
					key={`${featuredInnov.title}-${featuredInnov.id}`}
					handleEdit={props.handleEdit}
					innov={featuredInnov}
					title={featuredInnov.title}
					featuredIndex={featuredInnov.featured_index}
					description={featuredInnov.description}
					setInnovation={setInnovation}
				/>
			)
		})

		//sort regular innovations by decending order of ID
		regularInnovations.sort((a, b) =>
			Number(a.id) > Number(b.id) ? -1 : Number(a.id) < Number(b.id) ? 1 : 0
		)

		// console.log('regularInnovations', regularInnovations)

		regularInnovations.map((regularInnovation, i) => {
			regularInnovationCards.push(
				<AdminInnovationCard
					key={`${regularInnovation.title}-${regularInnovation.id}`}
					handleEdit={props.handleEdit}
					innov={regularInnovation}
					title={regularInnovation.title}
					description={regularInnovation.description}
					featuredLength={featuredLength}
					setInnovation={setInnovation}
				/>
			)
		})

		//sort archived innovations by decending order of ID
		archivedInnovations.sort((a, b) =>
			Number(a.id) > Number(b.id) ? -1 : Number(a.id) < Number(b.id) ? 1 : 0
		)

		// console.log('archivedInnovations', archivedInnovations)
		archivedInnovations.map((archivedInnovation, i) => {
			archivedInnovationCards.push(
				<AdminInnovationCard
					key={`${archivedInnovation.title}-${archivedInnovation.id}`}
					handleEdit={props.handleEdit}
					innov={archivedInnovation}
					title={archivedInnovation.title}
					description={archivedInnovation.description}
					featuredLength={featuredLength}
					setInnovation={setInnovation}
				/>
			)
		})
	}

	updateCards()

	const renderRegularInnovationView = () => {
		return (
			<>
				<FeaturedInnovations
					title={'Featured Innovations'}
					featuredInnovations={featuredInnovations}
					otherSections={regularInnovationCards}
					dispatch={props.dispatch}
					handleEdit={props.handleEdit}
					setInnovation={setInnovation}
					resolveFeatureOrder={resolveFeatureOrder}
				/>
				<InnovationSection
					title={'Innovations'}
					section={regularInnovationCards}
					otherSections={featuredInnovationCards}
				/>
				{archivedInnovations.length > 0 ? (
					<InnovationSection
						title={'Archived Innovations'}
						section={archivedInnovationCards}
						otherSections={featuredInnovationCards.concat(regularInnovationCards)}
					/>
				) : null}
			</>
		)
	}

	const renderSearchView = () => {
		searchInnovationCards = []
		sinn.map((searchInnovation, i) => {
			searchInnovationCards.push(
				<AdminInnovationCard
					key={i}
					handleEdit={props.handleEdit}
					innov={searchInnovation}
					title={searchInnovation.title}
					description={searchInnovation.description}
					setInnovation={setInnovation}
				/>
			)
		})
		return searchInnovationCards.length > 0 ? (
			<InnovationSection
				title={'Search Results'}
				section={searchInnovationCards}
				otherSections={null}
			/>
		) : (
			<InnovationSection
				title={'No Search Results'}
				section={searchInnovationCards}
				otherSections={null}
			/>
		)
	}

	return (
		<div style={innovationsListStyle}>
			{/* top bar */}
			<InnovationListTopBarContainer>
				<InnovationListSearchBarContainer>
					<InnovationListSearchBarInput
						type="text"
						name="search"
						onChange={handleChange}
						placeholder="Search by title or tag"
					/>
					<Link to="/admin/addinnovation">
						<BlackButton style={{ marginRight: 20 }}>Add New Innovation</BlackButton>
					</Link>
				</InnovationListSearchBarContainer>
				{bChanges ? (
					<InnovationListSaveChangesButtonContainer>
						<YellowButton
							style={{ marginRight: 20 }}
							onClick={() => saveChangesClick()}>
							Save Changes
						</YellowButton>
					</InnovationListSaveChangesButtonContainer>
				) : null}
			</InnovationListTopBarContainer>

			{/* list content */}
			{searchTerm.length > 0 ? renderSearchView() : renderRegularInnovationView()}

			{/* bottom changes save button */}
			{bChanges ? (
				<InnovationListSaveChangesButtonContainer>
					<YellowButton style={{ marginRight: 20 }} onClick={() => saveChangesClick()}>
						Save Changes
					</YellowButton>
				</InnovationListSaveChangesButtonContainer>
			) : null}
		</div>
	)
}
