import React, { Component } from 'react'
import ReactGA from 'react-ga'
import { VideoPlayer } from '../../components/VideoPlayer'

const iframeContainerStyle = {
	position: 'relative',
	overflow: 'hidden',
	paddingTop: '56.25%'
}

const iframeStyle = {
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	border: 0
}

export default class Vimeo extends Component {
	state = { playing: false }
	tracked = false

	play = () => {
		const { innovationId, innovationTitle } = this.props
		if (this.state.playing) {
			this.player.play()
		} else {
			if (this.player.video.currentTime >= 10 && !this.tracked) {
				ReactGA.event({
					category: 'user',
					action: 'watched video',
					label: `${innovationId} - ${innovationTitle}`
				})
				this.tracked = true
			}
			this.player.pause()
		}
	}

	setPlayerRef = el => (this.player = el)

	render() {
		const {
			data: { videoLink, videoId },
			vimeoData,
			swiping,
			active,
			hideCarouselNav
		} = this.props
		console.log('Vidlink', videoLink)
		return (
			<>
				<div
					onClick={e =>
						!swiping && this.setState({ playing: !this.state.playing }, this.play)
					}
					style={{
						backgroundColor: 'rgba(0,0,0,0)',
						width: '100%'
					}}>
					<div style={iframeContainerStyle} j>
						<iframe
							src={
								'https://player.vimeo.com/video/' +
								videoId +
								'?byline=false&color=f4d146&playsinline=1&quality=auto'
							}
							width="640"
							height="360"
							style={iframeStyle}
							frameBorder="0"
							allow="fullscreen"></iframe>
					</div>
					{/* <VideoPlayer
						disabled={!active}
						hideCarouselNav={hideCarouselNav}
						clickToPlay={false}
						ref={this.setPlayerRef}
						type={'video'}
						width='100%'
						src={videoLink}
						poster={vimeoData.thumbnail_url}
					/> */}
				</div>
			</>
		)
	}
}
