import React from 'react'
import styled from 'styled-components'

// TODO: simplify
export const Checkbox = ({ checked, onChangeHandler, label, disabled }) => {
	return (
		<Label onClick={ev => onChangeHandler(label, ev)}>
			<span>
				{checked ? <CheckBoxIcon /> : <CheckBoxIconBlank />}
				<Input type='checkbox' checked={!!checked} onChange={() => {}} disabled={disabled} />
			</span>
			{label && <span>{label}</span>}
		</Label>
	)
}

export const Label = styled.label`
	position: relative;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	vertical-align: middle;
	> span:first-child {
		display: inline-flex;
		flex: 0 0 auto;
		padding: 0;
		align-items: center;
		justify-content: center;
		margin-right: 5px;
		color: #ffcc01;
		> svg {
			fill: currentColor;
			width: 1em;
			height: 1em;
			display: inline-block;
			font-size: 24px;
			transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			user-select: none;
			flex-shrink: 0;
		}
	}
	> span:last-child {
		font-size: 12px;
		> span {
			text-decoration: underline;
		}
	}
`

export const Input = styled.input`
	top: 0;
	left: 0;
	width: 100%;
	cursor: inherit;
	height: 100%;
	margin: 0;
	opacity: 0;
	padding: 0;
	position: absolute;
`

const CheckBoxIcon = () => (
	<svg width='28px' height='28px' viewBox='0 0 28 28' version='1.1'>
		<g id='Desktop-Search-&amp;-Filter' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
			<g id='Innovation-List-Copy-4' transform='translate(-1580.000000, -186.000000)'>
				<g id='Group-2' transform='translate(1580.000000, 186.000000)'>
					<rect
						id='Rectangle-2-Copy-3'
						stroke='#FFCC01'
						strokeWidth='1.91999977'
						fill='#FFD014'
						x='0.959999886'
						y='0.959999886'
						width='26.0800002'
						height='26.0800002'
					/>
					<g id='ic_check'>
						<polygon
							id='Bounds'
							fillOpacity='0.1'
							fill='#FF0000'
							opacity='0'
							points='0 0 28 0 28 28 0 28'
						/>
						<polygon
							id='Shape'
							fill='#FFFFFF'
							points='10.5 18.865 5.635 14 3.97833333 15.645 10.5 22.1666667 24.5 8.16666667 22.855 6.52166667'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)

const CheckBoxIconBlank = () => (
	<svg width='28px' height='28px' viewBox='0 0 28 28' version='1.1'>
		<g id='Desktop-Search-&amp;-Filter' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
			<g
				id='Innovation-List-Copy-4'
				transform='translate(-1312.000000, -186.000000)'
				fill='#FFFFFF'
				stroke='#FFCC01'
				strokeWidth='1.91999977'>
				<g id='Group-9' transform='translate(1300.000000, 174.000000)'>
					<g id='Group-6' transform='translate(12.000000, 12.000000)'>
						<g id='Group-2'>
							<rect
								id='Rectangle-2'
								x='0.959999886'
								y='0.959999886'
								width='26.0800002'
								height='26.0800002'
							/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
