import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Spring } from 'react-spring'
import {
	Container,
	Video,
	Controls,
	Scrubber,
	SoundToggle,
	InputRange,
	PlayButton,
	Timestamp,
	PlayButtonToggle,
} from './ui'

const STOP = e => e.stopPropagation()

export default class VideoPlayer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			playing: false,
			controlVisibility: 1,
			time: 0,
			volume: 1,
			muted: false,
			currentTime: 0,
			pause: this.pause.bind(this),
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (props.disabled && state.playing) {
			state.pause()
		}
		return null
	}

	play = () => {
		if (!this.state.playing) this.setState({ playing: true }, () => this.video.play())
	}
	pause = () => {
		if (this.state.playing) this.setState({ playing: false }, () => this.video.pause())
	}

	toggle = () => {
		if (!this.state.playing) play()
		else pause()
	}

	onMouseEnter = () => {
		if (this._controlsTimer) {
			clearTimeout(this._controlsTimer)
			this._controlsTimer = null
		}
		this.setState({ controlVisibility: 1 })
	}

	onMouseLeave = () => {
		if (this.state.playing)
			this._controlsTimer = setTimeout(() => this.setState({ controlVisibility: 0 }), this.props.controlsTimeout)
	}

	onTimeUpdate = () => {
		const currentTime = this.video.currentTime
		this.setState({
			currentTime,
			time: (this.video.currentTime / this.video.duration) * 100,
		})
	}

	scrub = e => {
		e.preventDefault()
		e.stopPropagation()
		this.setState(
			{ time: e.target.value },
			() => (this.video.currentTime = (this.video.duration * this.state.time) / 100)
		)
	}

	adjustVolume = e => {
		e.preventDefault()
		e.stopPropagation()
		const volume = e.target.value

		this.setState({ volume, muted: parseFloat(volume) === 0 }, () => (this.video.volume = volume))
	}

	toggleSound = e => {
		e.preventDefault()
		e.stopPropagation()

		this.setState({ muted: !this.video.muted }, () => (this.video.muted = this.state.muted))
	}

	setVideoRef = el => (this.video = el)

	render() {
		const { muted, autoplay, src, poster, controls, width, height, hideCarouselNav } = this.props

		const { playing, controlVisibility, time, volume, muted: toggleMuted, currentTime } = this.state

		return (
			<Container onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
				<Video
					ref={this.setVideoRef}
					onTimeUpdate={this.onTimeUpdate}
					onPlay={() => hideCarouselNav(true)}
					onPause={() => hideCarouselNav(false)}
					onEnded={() => hideCarouselNav(false)}
					volume={volume}
					playsInline
					{...{ muted, src, poster, width, height }}
				/>
				<Spring
					to={{
						opacity: controlVisibility,
						bottom: controlVisibility === 0 ? -80 : 0,
					}}>
					{({ opacity, bottom }) => (
						<Controls style={{ opacity }}>
							{!playing && <PlayButton />}
							<Scrubber style={{ bottom }}>
								<PlayButtonToggle playing={playing} onClick={this.toggle} />
								<InputRange
									style={{ '--value': `${time}%` }}
									step='0.01'
									type={'range'}
									min={0}
									max={100}
									value={time}
									onChange={this.scrub}
									onMouseDown={STOP}
									onMouseUp={STOP}
									onTouchStart={STOP}
									onTouchMove={STOP}
									onTouchEnd={STOP}
									onClick={STOP}
								/>
								<Timestamp time={currentTime} />
								<SoundToggle muted={toggleMuted} onClick={this.toggleSound} />
								<InputRange
									style={{
										width: '10%',
										minWidth: 70,
										'--value': `${(toggleMuted ? 0 : volume) * 100}%`,
									}}
									step='0.01'
									type={'range'}
									min={0}
									max={1}
									value={toggleMuted ? 0 : volume}
									onChange={this.adjustVolume}
									onTouchStart={STOP}
									onTouchMove={STOP}
									onTouchEnd={STOP}
									onMouseDown={STOP}
									onMouseUp={STOP}
									onClick={STOP}
								/>
							</Scrubber>
						</Controls>
					)}
				</Spring>
			</Container>
		)
	}
}

VideoPlayer.defaultProps = {
	muted: false,
	autoplay: false,
	controls: false,
	controlsTimeout: 1500,
	disabled: false,
}

VideoPlayer.propTypes = {
	src: PropTypes.string.isRequired,
	poster: PropTypes.string,
	autoplay: PropTypes.bool,
	muted: PropTypes.bool,
	controls: PropTypes.bool,
	width: PropTypes.any,
	height: PropTypes.any,
	controlsTimeout: PropTypes.number,
}
