import styled from 'styled-components'

export const Wrapper = styled.div`
	width: 100%;
	height: auto;
	min-height: 100%;
	display: flex;
	justify-content: center;
	position: relative;
	background-color: #000;
	:before {
		content: '';
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.55;
		background-image: url(/about_hero-bg.png);
		background-position: 20% 10%;
		background-repeat: no-repeat;
		background-size: auto;
		@media (min-width: 992px) {
			background-position: center;
			background-size: cover;
		}
	}
`

export const Container = styled.div`
	width: 1179px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 80px;
	@media (max-width: 1279px) {
		/* width: 879px; */
	}
	@media (max-width: 889px) {
		width: 90%;
	}
`

export const X = styled.div`
	position: absolute;
	right: 20px;
	top: 20px;
	width: 30px;
	font-size: 30px;
	font-weight: 300;
	color: #fff;
	cursor: pointer;
`

export const Logo = styled.div`
	background: url(${({ src }) => src});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	height: 100px;
	width: 100px;
	top: 50px;
`

export const Header = styled.h1`
	color: #fff;
	text-align: center;
`

export const Hero = styled.div`
	width: 100%;
	height: 500px;
	margin: 40px 0px 25px 0px;
	background-color: #fff;
	opacity: 0.05;
`

export const Details = styled.div`
	width: 80%;
	padding: 0px 13%;
	color: fff;
	box-sizing: border-box;
	@media (max-width: 500px) {
		width: 100%;
	}
	> div:first-child > p {
		font-size: 17px;
		margin-bottom: 30px;
	}
	> div > p > a {
		color: #fff;
		&:hover {
			color: #ffcc01;
		}
	}
`

export const BtnContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 50px;
`

export const ExploreBtn = styled.button`
	border: 3px solid #ffcc01;
	background-color: #000;
	margin-bottom: 20px;
	padding: 5px 20px;
	cursor: pointer;
	transition: color 0.2s, background-color 0.2s;
	font-size: 16px;
	color: #fff;
	font-weight: 400;
	font-family: 'Averta for TBWA';
	&:hover {
		background-color: #ffcc01;
		color: #000;
	}
`
