import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

// TODO: convert to hook

class InitalScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		const {
			location: { hash },
		} = this.props

		if (location.pathname !== prevProps.location.pathname && hash !== '#innovations') {
			window.scrollTo(0, 0)
		}
	}
	render() {
		return this.props.children || <div />
	}
}

export const ScrollToTop = withRouter(InitalScrollToTop)
