import React, { useCallback, useEffect, useState } from 'react'
import {
	FormSectionContainer,
	FormInputContainer,
	FormInputCounter,
	UploadProgress
} from './styled'

import axios from 'axios'
import { AddImage } from './MediaModal'

const useUpload = (_files = [], options = { autoUpload: true }) => {
	const [uploading, setUploading] = useState(false)
	const [uploads, setUploads] = useState([])
	const [files, setFiles] = useState(_files)

	const [progress, setProgress] = useState(0)

	const { autoUpload } = options

	const upload = useCallback(() => {
		const data = new FormData()
		files.forEach(file => data.append('file', file, file.fileName))
		setUploading(true)
		axios
			.post('/upload', data, {
				headers: {
					accept: 'application/json',
					'Accept-Language': 'en-US,en;q=0.8',
					'Content-Type': `multipart/form-data; boundary=${data._boundary}`
				},
				onUploadProgress: progressEvent =>
					setProgress(progressEvent.loaded / progressEvent.total)
			})
			.then(response => {
				setUploads(response.data)
				setFiles(files)
				setUploading(false)
			})
			.catch(error => {
				console.error(error)
				setFiles([])
				setUploading(false)
			})
	}, [files])

	useEffect(() => {
		if (autoUpload && files.length > 0) {
			upload()
		}
	}, [files])

	return { upload, uploads, setFiles, uploading, progress }
}

export const Name = ({ title, description, handleChange, featuredImage }) => {
	let titleCount = title.length
	let descriptionCount = description.length
	let [splash, setSplash] = useState(featuredImage)

	const { setFiles, uploading, uploads, progress } = useUpload()

	const onChange = useCallback(image => setFiles([image]), [setFiles])

	useEffect(() => {
		// console.log('uploads', uploads[0])
		if (uploads.length > 0) {
			if (uploads[0].data && uploads[0].data.src) {
				setSplash(uploads[0].data.src)
				handleChange({
					target: {
						name: 'featured_image',
						value: uploads[0].data.src
					}
				})
			}
		}
	}, [setSplash, uploads])

	return (
		<FormSectionContainer>
			<form>
				<FormInputContainer>
					<label htmlFor="title">Innovation Name</label>
					<input
						type="text"
						name="title"
						onChange={handleChange}
						value={title}
						maxLength="50"
					/>
					<FormInputCounter>{`${titleCount}/50`}</FormInputCounter>
				</FormInputContainer>
				<FormInputContainer>
					<label htmlFor="description">One Liner</label>
					<input
						type="text"
						name="description"
						onChange={handleChange}
						value={description}
						maxLength="120"
					/>
					<FormInputCounter>{`${descriptionCount}/120`}</FormInputCounter>
				</FormInputContainer>
				<FormInputContainer>
					<label htmlFor="description">Card Featured Image</label>
					<div style={{ display: 'flex', marginTop: 20 }}>
						<AddImage
							multiple={false}
							idleMessage={'Drop an image to update the card image'}
							preview={false}
							onChange={onChange}
						/>
						<div style={{ flex: '1 auto', padding: '0 30px' }}>
							{splash && (
								<>
									<img style={{ maxWidth: 300 }} src={splash} />
								</>
							)}
							{uploading && (
								<UploadProgress progress={progress}>
									Uploading...
									<span className="progress">
										{progress === 1
											? 'finishing'
											: `${Math.floor(progress * 100)}%`}
									</span>
								</UploadProgress>
							)}
						</div>
					</div>
				</FormInputContainer>
			</form>
		</FormSectionContainer>
	)
}

export default Name
