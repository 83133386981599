import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Navbar = styled.div`
	width: 100%;
	position: fixed;
	height: 44px;
	background-color: #fff;
	border-bottom: solid 1px #d3d3d3;
	transition: top 0.5s;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 100;
	padding: 0 12px;
	top: ${({ hide }) => (hide ? 0 : '-3000px')};
	@media (min-width: 2000px) {
		height: 85px;
	}
`

export const Logo = styled.div`
	background: url(${({ src }) => src});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	height: 100%;
	width: 43px;
	cursor: pointer;
	@media (min-width: 20000px) {
		width: 85px;
	}
`

export const Nav = styled.nav`
	z-index: 2;
`

export const NavList = styled.ul`
	list-style: none;
	display: ${props => (props.hide ? 'none' : 'flex')};
	flex-direction: column;
	padding: 0;
	margin: 0;
	position: absolute;
	left: 0;
	top: 44px;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.7);
	@media (min-width: 992px) {
		display: flex;
		flex-direction: row;
		position: relative;
		height: auto;
		background-color: transparent;
		top: 0;
	}
`

export const NavItem = styled.li`
	text-align: center;
	background-color: #ffce00;
	background: #ffce00;
	box-shadow: inset 0 -1px 0 0 #000000;
	font-size: 20px;
	color: #222;
	letter-spacing: -0.43px;
	height: 44px;
	@media (min-width: 992px) {
		height: auto;
		margin: 0px 5px;
		background-color: transparent;
		box-shadow: none;
		font-size: 12px;
	}
	@media (min-width: 2000px) {
		font-size: 20px;
	}
	&.mobile-only {
		@media (min-width: 992px) {
			display: none;
		}
	}
`

export const NavLink = styled.a`
	color: inherit;
	text-decoration: none;
	display: inline-block;
	font-size: inherit;
	font-weight: 700;
	line-height: 44px;
	cursor: pointer;
	width: 100%;
	height: 100%;
	transition: background-color 0.2s;
	&:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}
	> svg {
		width: 24;
		height: 24;
		display: none;
	}
	@media (min-width: 992px) {
		padding: 4px 10px 4px 8px;
		display: inline-flex;
		align-items: center;
		border: 2px solid #ffcc00;
		line-height: 1;
		width: auto;
		> svg {
			margin-right: 6px;
			display: inline;
		}
	}
	@media (min-width: 2000px) {
		padding: 8px 10px 8px 10px;
	}
`

const FeedbackSvg = styled.svg`
	top: 3px;
	left: 4px;
`

export const FeedbackLogo = () => (
	<FeedbackSvg width='24' height='24' viewBox='0 0 24 24'>
		<path d='M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM18 14H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z' />
		<path d='M0 0h24v24H0z' fill='none' />
	</FeedbackSvg>
)

export const LeaderboardIcon = () => (
	<FeedbackSvg width='30px' height='30px' viewBox='0 0 30 30'>
		<g id='Design-V3' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
			<g id='Agency-Grid' transform='translate(-2066.000000, -28.000000)'>
				<g id='Group-2' transform='translate(2059.000000, 21.000000)'>
					<g id='Group-8'>
						<g id='ic_public' transform='translate(5.000000, 5.000000)'>
							<polygon id='Bounds' points='0 0 34 0 34 34 0 34' />
							<path
								d='M17,2.83333333 C9.18,2.83333333 2.83333333,9.18 2.83333333,17 C2.83333333,24.82 9.18,31.1666667 17,31.1666667 C24.82,31.1666667 31.1666667,24.82 31.1666667,17 C31.1666667,9.18 24.82,2.83333333 17,2.83333333 L17,2.83333333 Z M15.5833333,28.2341667 C9.9875,27.54 5.66666667,22.78 5.66666667,17 C5.66666667,16.1216667 5.78,15.2858333 5.96416667,14.4641667 L12.75,21.25 L12.75,22.6666667 C12.75,24.225 14.025,25.5 15.5833333,25.5 L15.5833333,28.2341667 L15.5833333,28.2341667 Z M25.3583333,24.6358333 C24.99,23.4883333 23.9416667,22.6666667 22.6666667,22.6666667 L21.25,22.6666667 L21.25,18.4166667 C21.25,17.6375 20.6125,17 19.8333333,17 L11.3333333,17 L11.3333333,14.1666667 L14.1666667,14.1666667 C14.9458333,14.1666667 15.5833333,13.5291667 15.5833333,12.75 L15.5833333,9.91666667 L18.4166667,9.91666667 C19.975,9.91666667 21.25,8.64166667 21.25,7.08333333 L21.25,6.5025 C25.4008333,8.18833333 28.3333333,12.2541667 28.3333333,17 C28.3333333,19.9466667 27.2,22.6241667 25.3583333,24.6358333 L25.3583333,24.6358333 Z'
								id='Shape'
								fill='#222222'
							/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</FeedbackSvg>
)

export const InnovationIcon = () => (
	<FeedbackSvg width='26px' height='26px' viewBox='0 0 26 26'>
		<g id='Design-V3' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
			<g id='Agency-Grid' transform='translate(-2275.000000, -30.000000)'>
				<g id='Group-2' transform='translate(2059.000000, 21.000000)'>
					<g id='Group-8' transform='translate(207.000000, 0.000000)'>
						<g id='ic_inbox' transform='translate(5.000000, 5.000000)'>
							<polygon id='Bounds' points='0 0 34 0 34 34 0 34' />
							<path
								d='M26.9166667,4.25 L7.06916667,4.25 C5.51083333,4.25 4.26416667,5.525 4.26416667,7.08333333 L4.25,26.9166667 C4.25,28.475 5.51083333,29.75 7.06916667,29.75 L26.9166667,29.75 C28.475,29.75 29.75,28.475 29.75,26.9166667 L29.75,7.08333333 C29.75,5.525 28.475,4.25 26.9166667,4.25 L26.9166667,4.25 Z M26.9166667,21.25 L21.25,21.25 C21.25,23.6016667 19.3516667,25.5 17,25.5 C14.6483333,25.5 12.75,23.6016667 12.75,21.25 L7.06916667,21.25 L7.06916667,7.08333333 L26.9166667,7.08333333 L26.9166667,21.25 L26.9166667,21.25 Z M22.6666667,14.1666667 L19.8333333,14.1666667 L19.8333333,9.91666667 L14.1666667,9.91666667 L14.1666667,14.1666667 L11.3333333,14.1666667 L17,19.8333333 L22.6666667,14.1666667 L22.6666667,14.1666667 Z'
								id='Shape'
								fill='#222222'
							/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</FeedbackSvg>
)

export const XIcon = () => (
	<svg width='18px' height='18px' viewBox='0 0 18 18'>
		<g id='Design-V3' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
			<g id='Mobile-Menu,-Open,-Yellow' transform='translate(-343.000000, -14.000000)' fill='#000000'>
				<g id='Group-20' transform='translate(337.000000, 8.000000)'>
					<g
						id='Group-5'
						transform='translate(15.097532, 15.097532) rotate(-45.000000) translate(-15.097532, -15.097532) translate(5.097532, 4.097532)'>
						<rect id='Rectangle-9' x='0' y='9.31034483' width='20' height='3.10344828' rx='0.736842093' />
						<path
							d='M0.392014507,9.36206897 L19.6079855,9.36206897 C20.0149321,9.36206897 20.3448276,9.69196441 20.3448276,10.0989111 L20.3448276,11.6252269 C20.3448276,12.0321735 20.0149321,12.362069 19.6079855,12.362069 L0.392014507,12.362069 C-0.0149321443,12.362069 -0.344827586,12.0321735 -0.344827586,11.6252269 L-0.344827586,10.0989111 C-0.344827586,9.69196441 -0.0149321443,9.36206897 0.392014507,9.36206897 Z'
							id='Rectangle-9'
							transform='translate(10.000000, 10.862069) rotate(-270.000000) translate(-10.000000, -10.862069) '
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)

export const MenuIcon = () => (
	<svg width='20px' height='15px' viewBox='0 0 20 15'>
		<g id='Design-V3' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
			<g id='Mobile-Menu,-Closed' transform='translate(-342.000000, -15.000000)' fill='#000000'>
				<g id='Group-21' transform='translate(342.000000, 15.000000)'>
					<g id='Group-20'>
						<rect id='Rectangle-9' x='0' y='0' width='20' height='3' rx='0.736842093' />
						<rect id='Rectangle-9' x='0' y='6' width='20' height='3' rx='0.736842093' />
						<rect id='Rectangle-9' x='0' y='12' width='20' height='3' rx='0.736842093' />
					</g>
				</g>
			</g>
		</g>
	</svg>
)

export const Button = styled.button`
	width: 30px;
	height: 30px;
	padding: 0;
	border: none;
	background-color: transparent;
	cursor: pointer;
	svg {
		pointer-events: none;
	}
	:focus {
		outline: none;
	}
	@media (min-width: 992px) {
		display: none;
	}
`

export const Title = styled.h1`
	font-size: 18px;
	margin: 0 auto;
	padding: 0;
	text-align: center;
	width: 100%;
	max-width: calc(100% - 120px);
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	position: absolute;
	left: 60px;
	pointer-events: none;
	@media (min-width: 2000px) {
		font-size: 46px;
	}
`
