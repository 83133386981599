import styled from 'styled-components'

export const ProgressContainer = styled.div`
	width: 100%;
	height: 6px;
	position: relative;
	padding-top: 10px;
`

export const ProgressRail = styled.div`
	width: 100%;
	height: 6px;
	background-color: #000;
	border-radius: 6px;
	overflow: hidden;
`

export const Progress = styled.div`
	width: ${({ scaled }) => `${scaled * 10}%`};
	background-color: #ffcc00;
	height: 6px;
	border-radius: 6px;
	position: relative;
	left: -1px;
`
