import React, { Component } from 'react'
import * as Styled from './styled'

export default class ScaledByList extends Component {
	constructor(props) {
		super(props)
		this.state = { open: false }
		this._toggle = this._toggle.bind(this)
	}

	_toggle(value) {
		this.setState({ open: value })
	}

	render() {
		const { open } = this.state
		return (
			<Styled.ScaledByDropdown>
				<Styled.ScaledByHeader onClick={() => this._toggle(!open)}>
					Agencies with related or scaled versions <span>+</span>
				</Styled.ScaledByHeader>
				<Styled.ScaledUl open={open}>
					{this.props.scaled_by.map((e, i) => (
						<Styled.ScaledLi key={i} dangerouslySetInnerHTML={{ __html: e }} />
					))}
				</Styled.ScaledUl>
			</Styled.ScaledByDropdown>
		)
	}
}
