import styled from 'styled-components'

export const Container = styled.div`
	background-color: #000;
	padding: 0 20px;
`

export const Footer = styled.footer`
	width: 100%;
	background: black;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	padding: 40px 0;
	margin: 0 auto;
	max-width: 1260px;
	> div {
		width: 30%;
	}
	@media (max-width: 767px) {
		> div {
			width: 100%;
			max-width: 320px;
			margin: 0 auto;
		}
	}
`
export const Logo = styled.div`
	> div {
		max-width: 320px;
		width: 100%;
		padding-top: 23.46%;
		background: url(/TBWA_logo.png) center center no-repeat;
		background-size: contain;
		margin: 0 auto;
	}
`

export const Icons = styled.div`
	display: flex;
	justify-content: space-between;
`

export const Icon = styled.a`
	width: 40px;
	height: 40px;
	background: url(${p => p.bg}) center center no-repeat;
	background-size: contain;
`

export const Copyright = styled.div`
	color: white;
	text-align: center;
	padding: 0;
	font-size: 12px;
`

export const Policies = styled.div`
	color: white;
	font-size: 12px;
	display: flex;
	justify-content: center;
	> a {
		color: white;
		padding: 5px;
		margin: 5px;
		text-decoration: underline;
	}
`

export const Title = styled.h3`
	margin-top: 0;
`
export const FooterLink = styled.a`
	background-color: transparent;
	color: #fff;
	text-decoration: none;
	display: inline-flex;
	font-size: inherit;
	font-weight: 500;
	line-height: 44px;
	cursor: pointer;
	padding: 8px 10px 8px 8px;
	width: auto;
	margin-top: 52px;
	transition: background-color 0.2s;
	align-items: center;
	border: 2px solid #ffcc00;
	line-height: 1;
	&:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}
`

export const FeedbackIcon = styled.svg`
	top: 3px;
	left: 4px;
	width: 24;
	height: 24;
	margin-right: 6px;
	display: inline-block;
	fill: #fff;
`
