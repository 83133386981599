import styled from 'styled-components'

const CarouselContainer = styled.div`
	width: 100%;
	height: auto;
	${p => !p.showOverflow && 'overflow: hidden;'}
	position: relative;
`

const CarouselRail = styled.div`
	height: auto;
	position: relative;
`

const Indicators = styled.div`
	position: absolute;
	top: calc(103%);
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: center;
`

const Indicator = styled.div`
	width: 30px;
	height: 20px;
	margin: 10px 3px;
	cursor: pointer;
	position: relative;

	&:after {
		position: absolute;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		background: ${p => (p.active ? 'rgb(255, 204, 1)' : '#ccc')};
		height: 15px;
		width: 15px;
		left: 50%;
		border-radius: 100%;
		content: '';
		display: block;
	}
`

/* prettier-ignore */
const NavigationButton = styled.button`
	border: 0;
	border-radius: 100%;
	background: rgb(255, 204, 1);
	width: 36px;
	height: 36px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	${p => (p.direction === 'previous' ? `left: 3%;` : `right:3%;`)}
	outline: none;
	cursor: pointer;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
	&:hover{
		    /* background: rgb(255,195,1); */
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.5);
	}
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		left: 50%;
		background: url(${p => p.direction === 'previous' ? '/previous-chevron.svg' : '/next-chevron.svg'}) center center;
		width: 100%;
		height: 100%;
		margin: 4px 0 0 ${p => p.direction === 'previous' ? -3 : 2 }px;
	}
`
export { CarouselContainer, CarouselRail, Indicators, Indicator, NavigationButton }
