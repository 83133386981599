import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withAuth } from '@okta/okta-react'
import ReactGA from 'react-ga'
import { login, hideNav, showNav } from './../../store/actions'
import Content from './Content'
import Hero from './Hero'
import { getCookie } from '../../utils'

const AuthedLanding = withAuth(
	class Landing extends Component {
		state = { showNav: false, videoIndex: 0 }

		componentDidMount() {
			const { dispatch, history } = this.props
			dispatch(hideNav())
			this.checkAuth()
			this.getVideoCookie()
			// this.checkIfVisited()
			ReactGA.pageview('/')
			this.unsubscribe = history.listen(({ hash }) => {
				hash && this.scrollToElement(hash)
			})
		}

		componentDidUpdate() {
			const { forceAuth, auth } = this.props
			if (forceAuth) {
				auth.login('/')
			}
		}

		componentWillUnmount() {
			this.unsubscribe && this.unsubscribe()
		}

		toggleNav = value => {
			const { dispatch } = this.props
			this.setState({ showNav: value })
			if (value) {
				dispatch(showNav())
			} else {
				dispatch(hideNav())
			}
		}

		checkAuth = async () => {
			const { auth, authenticated, dispatch } = this.props
			const isAuthenticated = await auth.isAuthenticated()
			if (!isAuthenticated) {
				auth.login('/')
			} else if (!authenticated) {
				dispatch(login(true))
			}
		}

		getVideoCookie = () => {
			const videoIndex = parseInt(getCookie('videoNum')) || 0
			// Set cookie to the following index to be used the next time the page loads
			document.cookie = `videoNum=${videoIndex < 2 ? videoIndex + 1 : 0}`
			this.setState({ videoIndex })
		}

		checkIfVisited() {
			const {
				history: {
					location: { hash }
				}
			} = this.props
			const visited = getCookie('visited')
			if (visited === 'true') {
				window.location.hash = 'innovations'
				window.setTimeout(() => {
					this.scrollToElement(hash)
				}, 0)
			} else {
				document.cookie = `visited=true`
			}
		}

		scrollToElement(hash) {
			if (hash === '') return
			const element = document.getElementById(hash.slice(1))
			if (element) {
				window.setTimeout(() => {
					window.scrollTo(0, element.offsetTop)
				}, 0)
			}
		}

		render() {
			const { showNav, videoIndex } = this.state
			const { authenticated, history } = this.props
			if (!authenticated) return null
			return (
				<div style={{ minHeight: '100%', position: 'relative' }}>
					<Hero history={history} videoIndex={videoIndex} />
				</div>
			)
		}
	}
)

export default connect(({ authenticated, nav, forceAuth }) => ({
	authenticated,
	forceAuth
}))(AuthedLanding)
