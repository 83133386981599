import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import * as Styled from './styled'
import { rootRoute } from '../../config'
import { Tag } from './../Common'

// TODO: use CSS media queries instead?

export const InnovationCard = withRouter(
	({
		data,
		data: { title, id, description, scaled_by, company, tags, assets },
		history: { push },
		thumbnailSrc,
		mobile,
		showTags = true,
		disabled,
		goToSlide,
		rootPath = ''
	}) => {
		return (
			<Styled.InnovationCard
				onClick={() => (disabled ? goToSlide() : push(`${rootRoute}innovation/${id}`))}>
				<Styled.Header
					src={
						!data.featured_image
							? data.assets[0] != null
								? data.assets[0].vimeoData
									? thumbnailSrc
									: `${rootPath}${data.assets[0].data.src}`
								: ''
							: data.featured_image
					}>
					<Styled.HeaderOverlay>
						<Styled.Title>{title}</Styled.Title>
					</Styled.HeaderOverlay>
				</Styled.Header>
				<Styled.Info>
					{!mobile && (
						<Styled.ProgressContainer>
							<Styled.ProgressRail>
								<Styled.Progress scaled={scaled_by.length} />
							</Styled.ProgressRail>
						</Styled.ProgressContainer>
					)}
					<Styled.Description>{description}</Styled.Description>
					{showTags && (
						<Styled.TagContainer>
							{tags.map((e, i) => (
								<Tag key={i} label={e} />
							))}
						</Styled.TagContainer>
					)}
				</Styled.Info>
				<Styled.Circle>{`${scaled_by.length}x`}</Styled.Circle>
				<Styled.Footer>
					By <span>{company}</span>
				</Styled.Footer>
			</Styled.InnovationCard>
		)
	}
)
