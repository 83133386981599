import React, { Component } from 'react'
import { compose } from 'recompose'
import * as Styled from './styled'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { path, rootRoute } from '../../config'
import * as ROUTES from '../../constants/routes'
import { setAdmin } from '../../store/actions'

class Nav extends Component {
	state = {
		isMenuVisible: false,
		title: '',
		admin: false
	}

	componentDidMount() {
		const {
			history,
			location: { pathname }
		} = this.props
		const title = this.getTitle(pathname)
		if (title) {
			this.setTitle(title)
		} else {
			this.setTitle(this.getTitle(pathname))
		}
		const token = JSON.parse(localStorage.getItem('okta-token-storage'))
		if (token) {
			let tokenLength = Object.keys(token).length
			if (tokenLength > 0) {
				const userEmail = token.idToken.claims.email
				const adminsClone = this.props.admins.map(a => a.email)
				const admin = adminsClone.includes(userEmail)
				this.props.dispatch(setAdmin(admin))
				this.setState({
					admin
				})
			}
		}
		this.unsubscribe = history.listen(({ pathname: nextPath }) => {
			this.setTitle(this.getTitle(nextPath))
		})
	}

	componentWillUnmount() {
		this.unsubscribe && this.unsubscribe()
	}

	hideMenu = () => {
		this.setState({
			isMenuVisible: false
		})
	}

	toggleMenu = () => {
		this.setState(prevState => ({
			isMenuVisible: !prevState.isMenuVisible
		}))
	}

	getTitle = pathname => {
		switch (pathname) {
			case ROUTES.LANDING: {
				return 'Explore Innovations'
			}
			case ROUTES.LEADERBOARD: {
				return 'Leaderboard'
			}
			default: {
				let title = ''
				const { innovations } = this.props
				const innovationId = pathname.split('/innovation/')[1]
				if (innovations && innovationId) {
					const innovation = innovations.find(
						innovation => innovation.id === innovationId
					)
					if (innovation) {
						title = innovation.title
					}
				}
				return title
			}
		}
	}

	setTitle = title => {
		this.setState({
			title
		})
	}

	handleClick = e => {
		const { isMenuVisible } = this.state
		if (!isMenuVisible || !e.target.matches('a')) return
		this.hideMenu()
	}

	render() {
		const { hide } = this.props
		const { isMenuVisible, title } = this.state
		return (
			<Styled.Navbar hide={hide}>
				<HashLink to={'/#innovations'}>
					<Styled.Logo src={`${path}Logo-black.png`} />
				</HashLink>
				<Styled.Title>{title}</Styled.Title>
				<Styled.Nav>
					<Styled.Button onClick={this.toggleMenu}>
						{isMenuVisible ? <Styled.XIcon /> : <Styled.MenuIcon />}
					</Styled.Button>
					<Styled.NavList hide={!isMenuVisible} onClick={this.handleClick}>
						<Styled.NavItem className="mobile-only">
							<Styled.NavLink as={HashLink} to="/#innovations">
								Home
							</Styled.NavLink>
						</Styled.NavItem>
						{this.state.admin && (
							<Styled.NavItem>
								<Styled.NavLink as={Link} to={ROUTES.ADMIN}>
									<Styled.InnovationIcon />
									Admin
								</Styled.NavLink>
							</Styled.NavItem>
						)}
						<Styled.NavItem>
							<Styled.NavLink as={Link} to={ROUTES.LEADERBOARD}>
								<Styled.InnovationIcon />
								Leaderboard
							</Styled.NavLink>
						</Styled.NavItem>
						<Styled.NavItem>
							<Styled.NavLink
								href="https://forms.office.com/Pages/ResponsePage.aspx?id=GlDrQXH24Eylv7ZBaMNwX_MCwulSMoxMgJBWgA3x2dxUMjJBNktRVTI3STRMTlpFUEI2UTZKWUwzSSQlQCN0PWcu"
								target="_blank"
								rel="noreferrer noopener">
								<Styled.InnovationIcon />
								Submit an Innovation
							</Styled.NavLink>
						</Styled.NavItem>
					</Styled.NavList>
				</Styled.Nav>
			</Styled.Navbar>
		)
	}
}

const mapStateToProps = state => {
	return {
		innovations: state.innovations,
		isAdmin: state.isAdmin
	}
}

export default compose(withRouter, connect(mapStateToProps))(Nav)
