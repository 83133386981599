import React from 'react'
import styled from 'styled-components'

export * from './Checkbox'
export * from './Tag'

import * as Styled from './styled'

export const ScaledSvg = styled.div`
	width: 100%;
	height: auto;
	padding-top: 20px;
	padding-bottom: 20px;
	font-size: 72px;
	text-align: center;
	font-family: 'Averta for TBWA';
	font-weight: bold;
	letter-spacing: -1.5;
`

export const ScaleProgress = ({ scaled }) => {
	scaled = String(scaled)
	return (
		<ScaledSvg>
			{scaled}x
			<Styled.ProgressContainer>
				<Styled.ProgressRail>
					<Styled.Progress scaled={scaled} />
				</Styled.ProgressRail>
			</Styled.ProgressContainer>
		</ScaledSvg>
	)
}

/*
<ScaledSvg width='502px' height='147px' viewBox='0 0 502 147' version='1.1'>
			<g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g id={`X/Featured/Desktop/Level-${scaled}`} transform='translate(1.000000, 0.000000)'>
					<g id='Group-6'>
						<g id='Dynamic-group' transform='translate(183.000000, 0.000000)'>
							<g>
								<circle id='Oval-5' fill='#FFFFFF' cx='66.4400072' cy='66.4400072' r='66.4400072' />
								<text
									id={`${scaled}x`}
									fontFamily='AvertaforTBWA-Bold, Averta for TBWA'
									fontSize='72'
									fontWeight='bold'
									letterSpacing='-1.5'
									fill='#000000'>
									<tspan x='35' y='99'>
										{scaled}x
									</tspan>
								</text>
							</g>
						</g>
						<g id='Group-10' transform='translate(0.000000, 142.000000)' stroke='#000000'>
							<rect id='Rectangle-10' fill='#333333' x='-0.5' y='-0.5' width='501' height='5' rx='2' />
							<path
								d='M2,-0.5 L48,-0.5 C49.3807119,-0.5 50.5,0.619288125 50.5,2 C50.5,3.38071187 49.3807119,4.5 48,4.5 L2,4.5 C0.619288125,4.5 -0.5,3.38071187 -0.5,2 C-0.5,0.619288125 0.619288125,-0.5 2,-0.5 Z'
								id='Rectangle-10'
								fill='#FFCC00'
							/>
						</g>
					</g>
				</g>
			</g>
		</ScaledSvg>
*/
