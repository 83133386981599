import {
	SAVE_VIDEO_DATA,
	SAVE_LIVE_VIDEO_DATA,
	SAVE_INNOVATION_DATA,
	SAVE_LIVE_INNOVATION_DATA,
	EDIT_INNOVATION_DATA,
	LOG_IN,
	SHOW_NAV,
	HIDE_NAV,
	FORCE_AUTH,
	EDIT_ADMINS,
	SET_ADMIN,
	SAVE_ADMIN_DATA,
	UPDATE_FEATURED_INNOVATIONS
} from './../constants'

let initialState = {
	videoData: undefined,
	innovations: undefined,
	liveInnovations: undefined,
	featuredInnovations: [],
	editInnovation: undefined,
	loadedVimeoData: false,
	authenticated: false,
	checkedAuth: false,
	nav: true,
	title: 'Explore Innovations',
	forceAuth: false,
	admins: [],
	isAdmin: false,
	adminLookup: false,
	adminCheck: false
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SAVE_VIDEO_DATA:
			return {
				...state,
				innovations: action.data,
				loadedVimeoData: true
			}
		case SAVE_LIVE_VIDEO_DATA:
			return {
				...state,
				liveInnovations: action.data,
				loadedVimeoData: true
			}

		case SAVE_INNOVATION_DATA:
			return {
				...state,
				innovations: action.data,
				featuredInnovations: action.data.filter(innovation => innovation.featured === true)
			}
		case SAVE_LIVE_INNOVATION_DATA:
			//create unique tags list
			let tagSet = new Set()
			action.data.map(innovation => {
				let innovationTags = innovation.tags
				innovationTags.map(tag => {
					tagSet.add(tag)
				})
			})
			let uniqueTagArray = Array.from(tagSet)

			//dynamic leaderboard calculation

			//helper for stripping Agency names of href tags from some innovations
			const clearAgencyNameOfURL = agencyName => {
				let searchString = '(<a href='
				let foundIndex = agencyName.indexOf(searchString)
				return agencyName.slice(0, foundIndex !== -1 ? foundIndex - 1 : agencyName.length)
			}

			//create unique agency list
			let agencySet = new Set()
			action.data.map(innovation => {
				let scaled_by = innovation.scaled_by
				scaled_by.map(agency => {
					let cleanedName = clearAgencyNameOfURL(agency)
					agencySet.add(cleanedName)
				})
			})
			let agencyArray = Array.from(agencySet)

			//create the default leaderboard object array
			let leaderBoardArray = agencyArray.map(agency => {
				return {
					company: agency,
					score: 0
				}
			})

			//calculate the scores
			action.data.map(innovation => {
				let scaled_by = innovation.scaled_by
				scaled_by.map(agency => {
					let findAgencyIndex = leaderBoardArray.findIndex(leaderboardItem => {
						return leaderboardItem.company === clearAgencyNameOfURL(agency)
					})
					if (findAgencyIndex !== -1) {
						leaderBoardArray[findAgencyIndex].score += 1
					}
				})
			})

			//sort leaderboard by score
			leaderBoardArray.sort((a, b) =>
				Number(a.score) > Number(b.score) ? -1 : Number(a.score) < Number(b.score) ? 1 : 0
			)

			return {
				...state,
				liveInnovations: action.data,
				featuredInnovations: action.data.filter(innovation => innovation.featured === true),
				tags: uniqueTagArray,
				leaderboard: leaderBoardArray
			}

		case EDIT_INNOVATION_DATA:
			return {
				...state,
				editInnovation: action.data
			}

		case UPDATE_FEATURED_INNOVATIONS:
			return {
				...state,
				featuredInnovations: action.data
			}

		case LOG_IN:
			return {
				...state,
				authenticated: action.data,
				checkedAuth: true,
				forceAuth: false
			}

		case EDIT_ADMINS:
			return {
				...state,
				admins: action.data
			}

		case SET_ADMIN:
			return {
				...state,
				isAdmin: action.data,
				adminCheck: state.adminLookup
			}
		case SAVE_ADMIN_DATA:
			return {
				...state,
				admins: action.data,
				adminLookup: true
			}

		case FORCE_AUTH:
			return {
				...state,
				forceAuth: true
			}
		// TODO: why store nav status globally?
		case SHOW_NAV:
			return {
				...state,
				nav: true,
				title: action.title || 'Explore Innovations'
			}
		case HIDE_NAV:
			return {
				...state,
				nav: false
			}
		default:
			return state
	}
}
