import React, { useEffect } from 'react'
import * as Styled from './styled'
import { rootRoute } from '../../config'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'
import ReactGA from 'react-ga'

export default connect(({ authenticated }) => ({
	authenticated
}))(({ history: { push }, authenticated }) => {
	useEffect(() => {
		ReactGA.pageview('/about')
	}, [])
	return authenticated ? (
		<Styled.Wrapper>
			<Styled.Container>
				<Styled.X onClick={() => push(rootRoute)}>X</Styled.X>
				<Styled.Logo src={'../Logo.png'} />
				<Styled.Header>
					An open innovation platform <br /> for the TBWA\ Collective.
				</Styled.Header>
				<Styled.Details>
					<div>
						<p>
							10x has evolved from a platform to a mindset that lives on at &nbsp;
							<a
								href="https://knowledge.tbwa.com/nuxeo/site/LibraryWebUI/#/p/innovation/NEXT"
								target="_blank">
								Knowledge Hub
							</a>
							.
						</p>
						<p>
							10x is the idea of taking something that has been done once and had a
							positive impact and then <br />
							scaling that impact across ten other agencies or clients.
						</p>
						<p>
							That something could be a piece of knowledge, a proven methodology or
							even a creative product. At TBWA we believe in being a radically open
							creative Collective. It’s what makes us unique.
						</p>
						<p>
							So keep the dream of 10x alive and head over to Knowledge Hub to tap
							into our Collective genius <br />
							and share some of your own.
						</p>
						<p>
							10x will continually be added to over time and as our collective
							agencies continue to share new innovations and learnings from scaling
							10x innovations.
						</p>
						<p>
							If you’re looking for something specific that was on 10x and you can’t
							find it on Knowledge Hub <br />
							then please reach out to &nbsp;
							<a href="mailto:charlotte.huntley@tbwa.com">
								charlotte.huntley@tbwa.com
							</a>
							.
						</p>
					</div>
				</Styled.Details>
			</Styled.Container>
		</Styled.Wrapper>
	) : (
		<Redirect to={{ pathname: rootRoute }} />
	)
})
