import styled from 'styled-components'

export const CardDescription = styled.div`
	height: 32;
	margin-top: 5;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	font-size: 12;
`
export const CardButtonIcon = styled.img`
	height: 24px;
	width: 24px;
`

export const FeaturedIndexHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	font-size: 12px;
	color: #999;
	margin-bottom: 8px;
	width: 94%;
`

export const FeaturedIndexHeaderLine = styled.div`
	display: flex;
	height: 1px;
	flex-grow: 1;
	background: #999;
	margin-left: 8px;
`

let FeatureIndexOrderStyle = {
	fontSize: 12,
	color: '#999',
	marginBottom: 10
}
