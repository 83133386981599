import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import withAuth from '@okta/okta-react/dist/withAuth'
import { ParentFormContainer, FormContainer } from './styled'
import {
	FormSectionTitle,
	FileInputParentContainer,
	FileInputContainer,
	FileContainer,
	YellowButton,
	CancelBtn,
	VerticalBorderSpacer,
	FileText
} from '../../components/admin/form/styled'

import { editAdmins, createAdmin, deleteAdmin } from './../../store/actions'

const AddAdmin = props => {
	const [newAdmin, setNewAdmin] = useState({
		name: '',
		email: ''
	})

	const removeAdmin = e => {
		props.dispatch(deleteAdmin({ id: Number(e.id) }))
	}

	const handleSubmit = e => {
		e.preventDefault()
		props.dispatch(createAdmin(newAdmin))
		const admins = props.admins.slice()
		admins.push(newAdmin)
		props.dispatch(editAdmins(admins))
		setNewAdmin({
			name: '',
			email: ''
		})
	}

	const handleChange = e => {
		const name = e.target.name
		const value = e.target.value
		setNewAdmin({
			...newAdmin,
			[name]: value
		})
	}

	return (
		<ParentFormContainer>
			<FormContainer>
				<FormSectionTitle style={{ marginTop: 40 }}>Admin Users</FormSectionTitle>
				<form>
					<FileInputParentContainer>
						<FileInputContainer>
							<label htmlFor="name">Name</label>
							<input
								type="text"
								onChange={handleChange}
								value={props.name}
								name="name"
							/>
						</FileInputContainer>
						<FileInputContainer>
							<label htmlFor="email">Email</label>
							<input
								type="text"
								value={props.email}
								name="email"
								onChange={handleChange}
							/>
						</FileInputContainer>
						<YellowButton style={{ marginTop: 10 }} onClick={handleSubmit}>
							Add
						</YellowButton>
					</FileInputParentContainer>
				</form>
				{props.admins.map((admin, i) => {
					return (
						<FileContainer key={`admin-${i}`}>
							<CancelBtn
								style={{ marginRight: 10 }}
								onClick={() => removeAdmin(admin)}>
								X
							</CancelBtn>
							<VerticalBorderSpacer />
							<FileText>{admin.name}</FileText>
							<VerticalBorderSpacer />
							<FileText>{admin.email}</FileText>
						</FileContainer>
					)
				})}
			</FormContainer>
		</ParentFormContainer>
	)
}

export default connect(({ admins }) => ({
	admins
}))(withAuth(AddAdmin))
