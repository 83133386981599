import styled from 'styled-components'

export const InnovationCard = styled.div`
	/* flex: 1 0 30%; */
	min-width: calc(33% - 40px);
	margin: 20px 20px 50px;
	border: 1px solid #c7c8c9;
	height: 350px;
	cursor: pointer;
	position: relative;
	display: flex;
	justify-content: center;

	@media (max-width: 1150px) {
		flex: 1 0 40%;
	}
	@media (max-width: 760px) {
		flex: 1 0 60%;
	}
`

export const Title = styled.div`
	height: 30px;
	color: #fff;
	font-size: 24px;
	text-shadow: 0 2px 4px #000000;
	text-align: center;
`

export const Circle = styled.div`
	top: 42%;
	position: absolute;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #fff;
	font-size: 28px;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const Header = styled.div`
	width: 100%;
	height: 50%;
	top: 0;
	background-color: #979797;
	background: url(${({ src }) => src});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
`

export const TagContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`

export const HeaderOverlay = styled.div`
	width: 100%;
	height: 50%;
	top: 0;
	position: absolute;
	background: rgba(17, 17, 17, 0.65);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color 0.1s;
	&:hover {
		background: rgba(17, 17, 17, 0.75);
		transition: background-color 0.1s;
	}
`

export const Info = styled.div`
	width: 100%;
	height: 50%;
	bottom: 0;
	position: absolute;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	padding-top: 20px;
	padding-bottom: 30px;
	box-sizing: border-box;
`

export const Description = styled.div`
	width: 60%;
	text-align: center;
	font-size: 15px;
	@media (max-width: 600px) {
		width: 90% !important;
	}
`

export const ProgressContainer = styled.div`
	width: 60%;
	height: 6px;
	position: relative;
`

export const ProgressRail = styled.div`
	width: 100%;
	height: 6px;
	background-color: #000;
	border-radius: 6px;
	overflow: hidden;
`

export const Progress = styled.div`
	width: ${({ scaled }) => `${scaled * 10}%`};
	background-color: #ffcc00;
	height: 6px;
	border-radius: 6px;
	position: relative;
	left: -1px;
`

export const Footer = styled.div`
	height: 30px;
	width: 100%;
	background-color: #fff;
	border-top: solid 1px #d3d3d3;
	bottom: 0;
	position: absolute;
	text-align: center;
	font-size: 15px;
	line-height: 30px;
	> span {
		font-weight: 700;
	}
`
