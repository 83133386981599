import styled from 'styled-components'
import { BREAKPOINTS } from '../../theme'

export const Modal = styled.div`
	box-sizing: border-box;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	display: block;
	z-index: 3;
	overflow: visible;
	background-color: rgba(0, 0, 0, 0.55);
`

export const Content = styled.div`
	box-sizing: border-box;
	max-height: calc(100vh - 300px);
	overflow-y: auto;
	padding: 0;
	box-shadow: none;
	margin: 0 12px;
	-webkit-backface-visibility: hidden;
	-webkit-overflow-scrolling: touch;
	position: absolute;
	top: 150px;
	left: 0;
	::-webkit-scrollbar {
		width: 4px;
	}
	::-webkit-scrollbar-track {
		background-color: #333;
		border-radius: 10px;
		outline: 1px solid #000;
	}
	::-webkit-scrollbar-thumb {
		background-color: #ffcc00;
		border-radius: 10px;
		outline: 1px solid #000;
	}
	@media (min-width: ${BREAKPOINTS.xs}) {
		width: 80%;
		margin: 0 auto;
		left: 50%;
		transform: translateX(-50%);
		max-width: 800px;
	}
	@media (min-width: 992px) {
		top: 180px;
		max-height: calc(100vh - 400px);
	}
`
export const Close = styled.button`
	position: absolute;
	right: 20px;
	top: 20px;
	width: 30px;
	font-size: 30px;
	font-weight: 300;
	color: #fff;
	cursor: pointer;
	border: none;
	background-color: transparent;
	&:focus {
		outline: none;
	}
	@media (min-width: ${BREAKPOINTS.md}) {
		top: 32px;
		right: 32px;
	}
`
export const Icon = styled.svg`
	width: 18px;
	height: 18px;
	@media (min-width: ${BREAKPOINTS.md}) {
		width: 24px;
		height: 24px;
	}
`
