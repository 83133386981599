import React, { Component } from 'react'
import Swipeable from 'react-swipeable'
import { Spring } from 'react-spring'
import PropTypes from 'prop-types'
import * as Styled from './styled'

const slideProps = (index, length, adjustTop) => ({
	style: {
		position: index === 0 ? 'relative' : 'absolute',
		left: `${(index * 100) / length}%`,
		top: index === 0 && adjustTop ? 0 : -20,
		width: `${100 / length}%`,
	},
	onMouseDown: e => e.preventDefault(),
	onMouseUp: e => e.preventDefault(),
	// onClick: e => e.preventDefault()
})

export default class Carousel extends Component {
	static propTypes = {
		children: PropTypes.func.isRequired,
	}

	state = {
		currentItem: 0,
		x: '0%',
		swiping: false,
		hideNav: false,
		hovering: false,
	}

	componentDidMount() {
		if (this.props.autoSwipe) {
			this.setAutoSwipeInterval()
		}
	}
	componentWillUnmount() {
		if (!!this.interval) window.clearInterval(this.interval)
	}

	setAutoSwipeInterval = () => {
		const { currentItem } = this.state
		const { children } = this.props

		const slideLength = children({
			slideProps: () => {},
			isSwiping: () => {},
			isActive: () => {},
			hideCarouselNav: () => {},
		}).length

		if (!!this.interval) return window.clearInterval(this.interval)
		this.interval = window.setInterval(() => {
			this.goToSlide(currentItem < slideLength - 1 ? currentItem + 1 : 0)
		}, 5000)
	}

	handleHover = e => {
		if (e.type === 'mouseenter') {
			clearInterval(this.interval)
			this.interval = null
		} else {
			this.setAutoSwipeInterval()
		}
	}

	swiping = (e, x) => {
		const { currentItem } = this.state
		x = `${currentItem * -100 - (x * 100) / this.ref.offsetWidth}%`

		this.setState({
			swiping: true,
			x,
		})
	}

	swiped = (e, x, y, isFlick) => {
		e.preventDefault()
		const { currentItem } = this.state
		const { children } = this.props

		if (!isFlick && Math.abs(x) < 30) {
			this.setState({
				swiping: false,
				x: `${currentItem * -100}%`,
			})

			return
		}
		const slides = children({
			slideProps,
			isSwiping: () => false,
			isActive: i => false,
		})
		const nextItem = Math.max(0, Math.min(currentItem + (x > 0 ? 1 : -1), slides.length - 1))

		this.setState({
			swiping: false,
			currentItem: nextItem,
			x: `${nextItem * -100}%`,
			slides,
		})
	}

	goToSlide = slide => {
		if (slide < 0) return
		this.setState({
			swiping: false,
			currentItem: slide,
			x: `${slide * -100}%`,
		})
	}

	handleNav = index => {
		this.goToSlide(index)
		if (this.props.autoSwipe) this.setAutoSwipeInterval()
	}

	setContainerRef = el => (this.ref = el)

	render() {
		const { children, showOverflow, hideNavIndicators } = this.props,
			{ currentItem, x, swiping, hideNav } = this.state,
			isSwiping = () => swiping,
			isActive = i => currentItem === i,
			hideCarouselNav = hidden => this.setState({ hideNav: hidden }),
			slides = children({
				slideProps,
				isSwiping,
				isActive,
				hideCarouselNav,
			})

		return (
			<div style={{ position: 'relative' }}>
				<Styled.CarouselContainer
					ref={this.setContainerRef}
					showOverflow={showOverflow}
					onMouseEnter={this.handleHover}
					onMouseLeave={this.handleHover}>
					<Swipeable trackMouse onSwiping={this.swiping} onSwiped={this.swiped}>
						<Spring to={{ left: x }}>
							{({ left }) => (
								<Styled.CarouselRail
									style={{
										left,
										width: `${slides.length * 100}%`,
									}}>
									{children({
										slideProps,
										isSwiping: () => left !== x,
										isActive,
										hideCarouselNav,
										goToSlide: this.goToSlide,
									})}
								</Styled.CarouselRail>
							)}
						</Spring>
					</Swipeable>
					{!hideNavIndicators && (
						<>
							<Spring
								to={{
									opacity: currentItem === 0 || hideNav ? 0 : 1,
								}}>
								{({ opacity }) => (
									<Styled.NavigationButton
										style={{ opacity }}
										onClick={() => opacity === 1 && this.goToSlide(currentItem - 1)}
										direction={'previous'}
									/>
								)}
							</Spring>
							<Spring
								to={{
									opacity: currentItem === slides.length - 1 || hideNav ? 0 : 1,
								}}>
								{({ opacity }) => (
									<Styled.NavigationButton
										style={{ opacity }}
										onClick={() => opacity === 1 && this.goToSlide(currentItem + 1)}
										direction={'next'}
									/>
								)}
							</Spring>
						</>
					)}
				</Styled.CarouselContainer>

				<Styled.Indicators>
					{slides.length > 1 &&
						slides.map((slide, i) => (
							<Styled.Indicator key={i} onClick={() => this.handleNav(i)} active={isActive(i)} />
						))}
				</Styled.Indicators>
			</div>
		)
	}
}
