import React, { Component } from 'react'
import styled from 'styled-components'

export const Container = styled.div`
	position: relative;
`

export const Video = styled.video`
	${p => (p.width ? `width: ${p.width};` : ``)}
	${p => (p.height ? `height: ${p.width};` : ``)}
`

const PlayIcon = ({ fill = '#fff', width = '100%', height = '100%' }) => (
	<svg viewBox='0 0 18 18' width={width} height={height}>
		<path
			fill={fill}
			d='M15.562 8.1L3.87.225C3.052-.337 2 .225 2 1.125v15.75c0 .9 1.052 1.462 1.87.9L15.563 9.9c.584-.45.584-1.35 0-1.8z'
		/>
	</svg>
)

const PauseIcon = ({ fill = '#fff', width = '100%', height = '100%' }) => (
	<svg viewBox='0 0 18 18' width={width} height={height}>
		<path
			fill={fill}
			d='M6 1H3c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h3c.6 0 1-.4 1-1V2c0-.6-.4-1-1-1zM12 1c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h3c.6 0 1-.4 1-1V2c0-.6-.4-1-1-1h-3z'
		/>
	</svg>
)

const MutedIcon = ({ fill = '#fff', width = '100%', height = '100%' }) => (
	<svg viewBox='0 0 18 18' width={width} height={height}>
		<path
			fill={fill}
			d='M12.4 12.5l2.1-2.1 2.1 2.1 1.4-1.4L15.9 9 18 6.9l-1.4-1.4-2.1 2.1-2.1-2.1L11 6.9 13.1 9 11 11.1zM3.786 6.008H.714C.286 6.008 0 6.31 0 6.76v4.512c0 .452.286.752.714.752h3.072l4.071 3.858c.5.3 1.143 0 1.143-.602V2.752c0-.601-.643-.977-1.143-.601L3.786 6.008z'
		/>
	</svg>
)

const SoundIcon = ({ fill = '#fff', width = '100%', height = '100%' }) => (
	<svg viewBox='0 0 18 18' width={width} height={height}>
		<path
			fill={fill}
			d='M15.6 3.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4C15.4 5.9 16 7.4 16 9c0 1.6-.6 3.1-1.8 4.3-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3C17.1 13.2 18 11.2 18 9s-.9-4.2-2.4-5.7z'
		/>
		<path
			fill={fill}
			d='M11.282 5.282a.909.909 0 0 0 0 1.316c.735.735.995 1.458.995 2.402 0 .936-.425 1.917-.995 2.487a.909.909 0 0 0 0 1.316c.145.145.636.262 1.018.156a.725.725 0 0 0 .298-.156C13.773 11.733 14.13 10.16 14.13 9c0-.17-.002-.34-.011-.51-.053-.992-.319-2.005-1.522-3.208a.909.909 0 0 0-1.316 0zM3.786 6.008H.714C.286 6.008 0 6.31 0 6.76v4.512c0 .452.286.752.714.752h3.072l4.071 3.858c.5.3 1.143 0 1.143-.602V2.752c0-.601-.643-.977-1.143-.601L3.786 6.008z'
		/>
	</svg>
)

const PlayButtonContainer = styled.button`
	border-radius: 100%;
	width: 80px;
	height: 80px;
	background: rgba(255, 204, 1, 0.85);
	display: flex;
	justify-content: center;
	align-items: center;
	border: 0;
	cursor: pointer;

	&:hover {
		background: rgb(255, 204, 1);
	}

	svg {
		margin: 2px 0 0 6px;
	}
`
export const PlayButton = props => (
	<PlayButtonContainer>
		<PlayIcon width={'60%'} height={'60%'} />
	</PlayButtonContainer>
)

export const InputRange = styled.input`
	-webkit-appearance: none;
	background: 0 0;
	border: 0;
	border-radius: 28px;
	color: #1aafff;
	display: block;
	height: 20px;
	margin: 5px 0;
	padding: 0;
	transition: box-shadow 0.3s ease;
	width: 100%;

	&::-webkit-slider-runnable-track {
		background: 0 0;
		border: 0;
		border-radius: 2px;
		height: 4px;
		transition: box-shadow 0.3s ease;
		-webkit-user-select: none;
		user-select: none;
		background-image: linear-gradient(to right, rgb(255, 204, 1) var(--value, 0), transparent var(--value, 0));
	}
	&::-webkit-slider-thumb {
		background: #fff;
		border: 0;
		border-radius: 100%;
		box-shadow: 0 1px 1px rgba(255, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
		height: 14px;
		position: relative;
		transition: all 0.12s ease;
		width: 14px;
		-webkit-appearance: none;
		margin-top: -5px;
	}
	&::-moz-range-track {
		background: 0 0;
		border: 0;
		border-radius: 2px;
		height: 4px;
		transition: box-shadow 0.3s ease;
		-moz-user-select: none;
		user-select: none;
	}
	&::-moz-range-thumb {
		background: #fff;
		border: 0;
		border-radius: 100%;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
		height: 14px;
		position: relative;
		transition: all 0.2s ease;
		width: 14px;
	}
	&::-moz-range-progress {
		background: currentColor;
		border-radius: 2px;
		height: 4px;
	}
	&::-ms-track {
		background: 0 0;
		border: 0;
		border-radius: 2px;
		height: 4px;
		transition: box-shadow 0.3s ease;
		-ms-user-select: none;
		user-select: none;
		color: transparent;
	}
	&::-ms-fill-upper {
		background: 0 0;
		border: 0;
		border-radius: 2px;
		height: 4px;
		transition: box-shadow 0.3s ease;
		-ms-user-select: none;
		user-select: none;
	}
	&::-ms-fill-lower {
		background: 0 0;
		border: 0;
		border-radius: 2px;
		height: 4px;
		transition: box-shadow 0.3s ease;
		-ms-user-select: none;
		user-select: none;
		background: rgb(255, 204, 1);
	}
	&::-ms-thumb {
		background: #fff;
		border: 0;
		border-radius: 100%;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
		height: 14px;
		position: relative;
		transition: all 0.2s ease;
		width: 14px;
		margin-top: 0;
	}
	&::-ms-tooltip {
		display: none;
	}
	&:focus {
		outline: 0;
	}
	&::-moz-focus-outer {
		border: 0;
	}

	&::-moz-range-track {
		box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
		outline: 0;
	}
	&::-ms-track {
		box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
		outline: 0;
	}
	&::-webkit-slider-runnable-track {
		background-color: rgba(255, 255, 255, 0.25);
	}
	&::-moz-range-track {
		background-color: rgba(255, 255, 255, 0.25);
	}
	&::-ms-track {
		background-color: rgba(255, 255, 255, 0.25);
	}
	&:active::-webkit-slider-thumb {
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
	}
	&:active::-moz-range-thumb {
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
	}
	&:active::-ms-thumb {
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
	}
`

export const Controls = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const Scrubber = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 6px;
	min-height: 70px;

	> * {
		margin-left: 2px;
		margin-right: 2px;
	}

	background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.69) 200%);
	background: -webkit-gradient(
		left top,
		left bottom,
		color-stop(0%, rgba(0, 0, 0, 0)),
		color-stop(200%, rgba(0, 0, 0, 0.69))
	);
	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.69) 200%);
	background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.69) 200%);
	background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.69) 200%);
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.69) 200%);
`

const PlayPauseToggleButton = styled.button`
	width: 40px;
	height: 30px;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent;
	border: 0;

	&:hover {
		background: rgb(255, 204, 1);
	}
`

const SoundToggleButton = styled.button`
	width: 40px;
	height: 30px;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent;
	border: 0;

	&:hover {
		background: rgb(255, 204, 1);
	}
`

export const SoundToggle = ({ muted, onClick }) => (
	<SoundToggleButton onClick={onClick}>{muted ? <MutedIcon /> : <SoundIcon />}</SoundToggleButton>
)

export const PlayButtonToggle = ({ playing }) => (
	<PlayPauseToggleButton>{playing ? <PauseIcon /> : <PlayIcon />}</PlayPauseToggleButton>
)

const TimestampContainer = styled.div`
	margin: 7px;
	color: white;
	font-size: 14px;
	font-weight: 300;
`

export const Timestamp = ({ time }) => <TimestampContainer>{convertToTime(time)}</TimestampContainer>

const convertToTime = seconds => {
	var date = new Date(null)
	date.setSeconds(seconds) // specify value for SECONDS here
	var result = date.toISOString().substr(11, 8)
	if (result.slice(0, 2) === '00') {
		return result.slice(3)
	}
	return result
}
