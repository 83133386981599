import React from 'react'
import * as Styled from './styled'

export const FeedbackIcon = () => (
	<Styled.FeedbackIcon width="24" height="24" viewBox="0 0 24 24">
		<path d="M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM18 14H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z" />
		<path d="M0 0h24v24H0z" fill="none" />
	</Styled.FeedbackIcon>
)

const Footer = () => (
	<Styled.Container>
		<Styled.Footer>
			<Styled.Logo>
				<div />
			</Styled.Logo>
		</Styled.Footer>
		<Styled.Copyright>
			&copy; TBWA 2022 all rights reserved – TBWA and Disruption are registered trademarks of
			TBWA
		</Styled.Copyright>
		<Styled.Policies>
			<a href="https://tbwa.com/terms-of-service" target="_blank">
				Terms of Service
			</a>
			<a href="https://tbwa.com/privacy-policy" target="_blank">
				Privacy Policy
			</a>
			<a href="https://tbwa.com/privacy-shield-policy" target="_blank">
				Privacy Shield Policy
			</a>
		</Styled.Policies>
	</Styled.Container>
)

export default Footer
