import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`

    html{
        /*height: 100%;*/
        width: 100%;
    }

    body {
        font-family: "Averta for TBWA" !important;
        background-color: #fbfbfb;
        min-width: 100%;
        /*height: 100%;*/
        margin: 0;
    }
    *, *:before, *:after {
        box-sizing: border-box;
    }

    textarea:focus,
	input:focus,
	button:focus {
		outline: none;
	}
`
