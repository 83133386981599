import React from 'react'
import {
	FormSectionContainer,
	FormSectionTitle,
	FileInputContainer,
	FileInputParentContainer,
	YellowButton,
	VerticalBorderSpacer,
	FileContainer,
	FileText,
	CancelBtn
} from './styled'

const FileLink = ({ title, url, removeLink }) => {
	return (
		<FileContainer>
			<CancelBtn style={{ marginRight: 10 }} onClick={() => removeLink(title)}>
				X
			</CancelBtn>
			<VerticalBorderSpacer />
			<FileText>{title}</FileText>
			<VerticalBorderSpacer />
			<FileText>{url}</FileText>
		</FileContainer>
	)
}

let miniStyle = {
	display: 'flex',
	flexDirection: 'column',
	width: '100%'
}

let sameLineButtonStyle = {
	height: 40,
	position: 'relative',
	top: 15
}

export const Files = ({ handleChange, addLink, removeLink, links, linkTitle, linkUrl }) => {
	links = links.map((link, i) => {
		return <FileLink removeLink={removeLink} key={i} title={link.text} url={link.link} />
	})
	return (
		<FormSectionContainer>
			<FormSectionTitle>Innovation Files</FormSectionTitle>
			<form>
				<FileInputParentContainer>
					<FileInputContainer>
						<label htmlFor="linktitle">Link Title</label>
						<input
							type="text"
							onChange={handleChange}
							value={linkTitle}
							name="linkTitle"
						/>
					</FileInputContainer>
					<FileInputContainer>
						<label htmlFor="linkurl">Link URL</label>
						<input type="text" value={linkUrl} name="linkUrl" onChange={handleChange} />
					</FileInputContainer>
					<YellowButton style={{ marginTop: 10 }} onClick={addLink}>
						Add
					</YellowButton>
				</FileInputParentContainer>
			</form>
			{links}
		</FormSectionContainer>
	)
}

export default Files
