import React from 'react'
import {
	FormSectionContainer,
	FormSectionTitle,
	TagsContainer,
	SingleTag,
	CancelBtn
} from './styled'

let tagInputStyle = {
	flex: 1,
	border: 0
}

const Tag = ({ tag, removeTag }) => {
	return (
		<SingleTag>
			<CancelBtn onClick={() => removeTag(tag)}>X</CancelBtn>
			<span>{tag}</span>
		</SingleTag>
	)
}

export const Tags = ({ handleChange, tags, tagInput, addTag, removeTag }) => {
	let tagsComp = tags.map((tag, i) => {
		// let tag = tag.toUpperCase()
		return <Tag tag={tag} key={i} removeTag={removeTag} />
	})

	return (
		<FormSectionContainer>
			<FormSectionTitle>Tags</FormSectionTitle>
			<TagsContainer>
				{tagsComp}
				<input
					className="tagInput"
					name="tagInput"
					value={tagInput}
					onChange={handleChange}
					onKeyDown={addTag}
				/>
			</TagsContainer>
		</FormSectionContainer>
	)
}

export default Tags
