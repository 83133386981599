/**
 * Disables body scroll
 * Useful for modals
 * @return {Function} - A function that resets body overflow attribute to original value
 */
export function lockBodyScroll() {
	const previousOverflow = document.body.style.overflow
	document.body.style.overflow = 'hidden'
	return () => {
		document.body.style.overflow = previousOverflow || ''
	}
}

export const getDisplayName = WrappedComponent => WrappedComponent.displayName || WrappedComponent.name || 'Component'

/**
 * Returns stored cookie value or null if not found
 * @param {String} name - The name of the cookie
 */
export const getCookie = name => {
	const value = `; ${document.cookie}`
	const parts = value.split(`; ${name}=`)
	if (parts.length === 2) {
		return parts
			.pop()
			.split(';')
			.shift()
	}
	return null
}
